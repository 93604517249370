// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import siyad1 from "../../img/siyad-baba1.jpg";
import siyad2 from "../../img/siyad-baba2.jpg";
import religiousImage1 from "../../img/trikuta-hills.jpg";
import historicalImage1 from "../../img/chinkah.jpg";
import FoodsImage3 from "../../img/madri.jpg";
import FoodsImage4 from "../../img/masala-trout.jpg";


const  siyedBabaData = {
    name: "Syed Baba Waterfall",
    
    description: "Siyed Baba Waterfall",
    linkMap:"https://www.google.com/maps?q=Sehar Baba Waterfall, Siyad Baba temple, Sailanjan, Jammu and Kashmir 182311",
    description1: "Siyad Baba, located near the village of Talwara, stands as a renownedexcursion and picnic spot, drawing visitors with its natural beauty and culturalsignificance. The highlight of this destination is a spectacular waterfall, cascading from a height of more than 400 feet. The sheer force and majesty of the waterfall create a mesmerizing spectacle, offering a refreshing retreat amid the picturesque landscapes.",
    p1: "Adding to the allure of Siyad Baba is the presence of an ancient Nag Temple. This sacred site holds historical and religious significance, attracting devotees and curious explorers alike. The Nag Temple is believed to be a testament to the region&#39;s rich cultural heritage, providing a serene setting for spiritual reflection.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [siyad1, siyad2], // Add images array
    distanceFromSrinagar: " From Jammu to Siyad Baba: The journey from Jammu to Siyad Baba takes approximately 2.5 to 3 hours by car, depending on the route chosen, road conditions, and traffic. Travelers usually take the NH44 (National Highway 44) from Jammu, passing through towns like Udhampur and Samba before reaching Talwara. From Srinagar to Siyad Baba: The distance from Srinagar to Siyad Baba is much greater, requiring a longer journey of around 6 to 7 hours by car. The route typically involves traveling via NH44 through towns like Anantnag, Qazigund, and Ramban before reaching Talwara.",
    about1: "As visitors embark on the journey to Siyad Baba, they are treated to scenic vistas of the surrounding hills and valleys. The journey itself becomes an integral part of the experience, with lush greenery and the sound of cascading water guiding the way. The tranquil ambiance and pristine air make Siyad Baba an ideal spot for picnics, familyoutings, and nature enthusiasts seeking respite from the hustle and bustle of urban life. ",
    about2: "The blend of natural wonders and cultural treasures at Siyad Baba offers a unique and immersive experience. Whether reveling in the breathtaking views of the waterfall, exploring the ancient Nag Temple, or simply enjoying a picnic amidst nature, Siyad  Baba beckons travellers to unwind and connect with the beauty of the Himalayan landscape. ",
    about3: "Places near by – Salal Dam, Thanpal, Chinkah, Baradari, Bhimgarh Fort, Sulla Park, Mahakali Temple, Mahadev Temple. ",
    about4: "The imposing cascade captivates onlookers with its sheer force and majestic descent, casting a spellbinding aura that beckons visitors seeking solace amidst nature's embrace. As a cherished picnic spot and excursion destination, Siyad Baba embodies tranquility and charm, promising a refreshing retreat infused with the essence of both natural beauty and cultural significance. ",
    arrivalbycab1: " Arriving at Siyad Baba near the village of Talwara by cab is a convenient option for travelers seeking comfort and flexibility. Here's a guide on how to arrange your journey:",
    arrivalbycab2: " Hire a Cab: Start by hiring a cab from your current location. Whether you're in Jammu, Srinagar, or any other nearby town, you can easily find cab services available for hire. Look for reputable cab providers or use online platforms to book a cab in advance for convenience.",
    arrivalbycab3: "Travel Time and Distance: Be aware of the travel time and distance to Siyad Baba. Depending on your starting point, the journey may take several hours. It's important to plan accordingly and factor in breaks for rest and meals. ",
    arrivalbycab4: "Road Conditions: Inquire about the road conditions along the route to Siyad Baba. While major highways like NH44 are generally well-maintained, there may be sections with rough patches or construction work. Stay informed about any potential roadblocks or diversions to ensure a smooth journey. ",
    cimate0: " Winter (December-March): Cold, with average temperatures ranging from 5°C to -5°C (41°F to 23°F). Frequent snowfall, especially at higher altitudes.",
    cimate1: "Summer (April-June): Warm to hot, with average temperatures ranging from 20°C to 30°C (68°F to 86°F). Occasional showers are possible.  ",
    cimate2: "Monsoon (July-September): Moderate to heavy rainfall, with average temperatures around 25°C (77°F). Landslides can occur during this period. ",
    cimate3: "Autumn (October-November): Pleasant, with average temperatures dropping from 20°C to 10°C (68°F to 50°F). Some snowfall possible at higher altitudes. ",
    cimate4: "The waterfall's elevation will further impact the temperature, with cooler temperatures at higher altitudes ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel &amp; Restaurant Association Katra ","NA","NA","NA" ],
    hotelsPhone: ["+91-9596002222","NA","NA","NA" ],
    hotelsViewLinks: ["NA", "NA", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q="],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Madra', 'Masala Trout'],
    foodsDescription: [ "A hearty lamb or chicken stew cooked in a flavorful gravy with spices, often served with rice or roti. This rich and comforting dish is a staple in the region. ","Masala trout is also prepared with fresh trout but cooked in a flavorful masala gravy, adding a layer of spice and complexity to the dish. "],
    religiousPlaces: ["Trikuta Hills"],
    religiousPlacesDescription: ["Trikuta hills are famous for having the world renowned cave shrine of Mata Vaishno Devi Ji surrounded by lush green forests. Devi Pindi an  ancient Hindu temple located in the foot hills of Trikuta is a famous trekking route passing though water stream flowing all along the 3 Km trek to this temple and isincreasingly becoming popular among tourists for short leisure and adventure trips"],
    historicalPlaces: ["Chinkah"],
    historicalPlacesDescription: ["Chinkah is a village in Arnas Block in Reasi District near Salal Dam. It is surrounded by lush green trees , lake (river Chenab) and mountains. It is very relaxable and sightseeing destination . The tourists can enjoy horse riding and camping at this spot"],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default siyedBabaData;