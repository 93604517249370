// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/sheeshmahal1.jpg";
import baderwah2 from "../../img/sheeshmahal2.jpg";
import religiousImage1 from "../../img/ragunathaji.jpg";
import religiousImage2 from "../../img/peerkho.jpg";
import historicalImage1 from "../../img/bahu-fortgardens.jpg";
import FoodsImage3 from "../../img/patisa.webp";
import FoodsImage4 from "../../img/Dum-Aloo.jpg";


const sheeshMahal = {
    name: "Sheesh Mahal",
    
    description: "Sheesh Mahal",
    linkMap:"https://www.google.com/maps?q=",
    description1: "Sheesh Mahal, an architectural gem in Poonch, stands as a testament to the modernity and grandeur of the Dogra period, with its construction dating back to 1932-1936 AD. Commissioned by Raja Jagat  Dev Singh, it was a groundbreaking building of its time, boasting modern architecture that marked a departure from traditional designs. Specifically constructed between 1927 and 1940, this splendid  palace held great pride for the rulers of Poonch, symbolizing a shift towards contemporary styles.",
    p1: "Originally dedicated to Rani Attlia, the wife of Raja Sukhdev Singh, the palace has seen transformations over the years. Today, it houses the Girls Higher Secondary School, preserving the historical essence within its walls.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Located within the heart of Poonch Town, Sheesh Mahal is easily accessible, offering a glimpse into the town's historical and architectural heritage.",
    about1: "Sheesh Mahal is not merely a building; its a historical narrative etched in its architecture. From its  regal inception during the Dogra period to its adaptive reuse as an educational institution, the palace   weaves a story of cultural transition and resilience. ",
    about2: "Sheesh Mahal is renowned for its modern architectural style, which was innovative for its time. It departed from traditional designs and embraced contemporary aesthetics. ",
    about3: "The construction of Sheesh Mahal symbolized a departure from traditional architectural norms and a shift towards contemporary styles. It was considered a groundbreaking building of its time and stood as a testament to the modernity and grandeur of the Dogra period. ",
    about4: " Sheesh Mahal held great pride for the rulers of Poonch and was a symbol of their power and sophistication. It showcased the wealth and cultural advancement of the region during that period. ",
    arrivalbycab1: "Arriving by cab to sheesh mahal is a convenient option for travelers coming from nearby cities or towns.  ",
    arrivalbycab2: "Pre-booked Cab: You can arrange for a pre-booked cab service from your current location to Poonch. This can be done through local travel agencies, tour operators, or online platforms. By pre-booking, you ensure that a cab will be available to pick you up at your desired location and time. ",
    arrivalbycab3: "Local Taxi Services: Upon arrival at nearby transportation hubs such as airports, bus stations, or railway stations, you can find local taxi services available for hire. Inform the taxi driver of your destination in Poonch, and they will take you there. ",
    arrivalbycab4: "Private Transportation: If you prefer a more personalized and flexible travel experience, you can consider hiring a private cab or taxi for the entire journey to Poonch. This option allows you to make stops along the way and explore attractions at your own pace. ",
    cimate0: "Spring (March to April): Spring brings a gradual transition from winter to summer, with temperatures gradually warming up. Days become longer, and the weather becomes more pleasant, making it an ideal time for outdoor activities and sightseeing. The landscape comes alive with blooming flowers and rejuvenated vegetation. ",
    cimate1: " Summer (April to June): Summers in Poonch are typically warm with temperatures ranging from mild to moderately hot. Daytime temperatures can rise to around 30-35°C (86-95°F) during peak summer months. The weather is generally dry and sunny, making it suitable for outdoor activities. ",
    cimate2: "Monsoon (July to September): Poonch experiences monsoon rains during these months, although the intensity and duration of rainfall may vary. Monsoon showers bring relief from the summer heat but can also lead to occasional humidity and wet conditions. Travelers should be prepared for sporadic rainfall during this time. ",
    cimate3: " Autumn (October to November): Autumn in Poonch is characterized by pleasant weather with gradually decreasing temperatures. Days are warm and sunny, while nights become cooler. The landscape may showcase beautiful fall colors as leaves change hue, creating a picturesque setting.",
    cimate4: "Winter (December to February): Winters in Poonch are cold and chilly, with temperatures dropping significantly. Daytime temperatures range from 5-15°C (41-59°F), while nights can be much colder, often dipping below freezing point. The region experiences occasional snowfall, especially in higher elevations, adding to the scenic beauty but also making travel challenging. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Crown Palace ","Hotel Sain Inn","Hotel Amar Pushp","Hotel H.D Resort" ],
    hotelsPhone: ["080824 40251","099063 02219","NA","NA" ],
    hotelsViewLinks: ["https://crown-palace.business.site/", "https://hotel-sain-inn.business.site/?utm_source=gmb&amp;utm_medium=referral", "NA","NA"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Crown Palace, Behind Jama Masjid, Near Bus Stand Surankote, Poonch, Jammu and Kashmir 185121","https://www.google.com/maps?q=Hotel Sain Inn, 17 RCR Ward no 17, near DIET complex, Jammu and Kashmir 185101","https://www.google.com/maps?q=Hotel Amar Pushp, Near, Tandon Market, Old City, Fort, Poonch, Jammu and Kashmir 185101","https://www.google.com/maps?q=Hotel H.D Resort, Qila Rd, near Poonch Fort, Poonch, 185101"],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Patisa', 'Dum Aloo'],
    foodsDescription: [ "Patisa is primarily made from gram flour (besan), sugar, ghee (clarified butter), water, and optionally flavored with cardamom or other spices.The gram flour is roasted in ghee until it turns golden brown, giving the sweet its distinct flavor."," Dum Aloo is a popular Indian dish made with baby potatoes cooked in a rich and flavorful gravy. "],
    religiousPlaces: ["Raghunath Temple","Peer Kho Cave Temple"],
    religiousPlacesDescription: ["Raghunath Temple is a significant Hindu temple located in the heart of Jammu city. he Raghunath Temple complex is one of the largest temple complexes in North India.t was constructed in the 19th century by Maharaja Gulab Singh, the founder of the Dogra dynasty, and was completed by his son, Maharaja Ranbir Singh.The temple complex consists of seven separate shrines, each dedicated to a different deity.The main deity enshrined in the central shrine is Lord Rama, an incarnation of the Hindu god Vishnu. Other deities worshipped in the complex include Sita, Lakshmana, Hanuman, and various forms of Lord Vishnu and Goddess Durga","Peer Kho Cave Temple is situated on the banks of the Tawi River, near the historic Raghunath Temple, in the heart of Jammu city.he temple is nestled within a natural cave formation, adding to its unique and mystical ambiance.The temple is believed to have been constructed during the medieval period and is dedicated to Lord Shiva, one of the principal deities in Hinduism."],
    historicalPlaces: ["Bahu Fort and Gardens"],
    historicalPlacesDescription: ["Bahu Fort and Gardens, located in Jammu city in the Indian union territory of Jammu and Kashmir, are prominent historical and recreational attractions.Bahu Fort is believed to have been originally built by Raja Bahulochan over 3,000 years ago but was later renovated by the Dogra rulers in the 19th century. It stands on a rock face on the left bank of the Tawi River.The fort showcases a mix of Mughal and Dogra architectural styles. It is constructed using locally available red sandstone and features intricate carvings and decorations. Adjacent to Bahu Fort is the Bahu Gardens, also known as Bagh-e-Bahu. Spread over several acres, these terraced gardens are landscaped with lush greenery, flowering plants, and manicured lawns.The gardens offer a peaceful retreat from the hustle and bustle of the city. Visitors can stroll along the pathways, relax amidst the natural beauty, and enjoy picnics with family and friends."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default sheeshMahal;