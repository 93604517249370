export const districtData ={
  "type": "FeatureCollection",
  "features": [
    {
      // "type": "Feature",
      // "properties": {
      //   "district": "Poonch",
      //   "id": "Poonch",
      //   "territory": "Azad Jammu & Kashmir",
      //   "tehsil": 4,
      //   "capital": "Rawalakot",
      //   "subDivisions": 4,
      //   "division": "Jammu",
      //   "villages": 122,
      //   "areaSqkm": 855,
      //   "areaPercentage": 11.4,
      //   "unionCouncils": 25,
      //   "growthRate": 2.24,
      //   "population": 0.411,
      //   "projectedPopulation": 0.586,
      //   "densitysqKm": 685,
      //   "literacyRate": 81,
      //   "details": "Poonch is a district located in the union territory of Jammu and Kashmir, India. It is known for its scenic beauty, historical significance, and cultural heritage. While Poonch may not be as popular as some other tourist destinations, it offers a unique and tranquil experience for those seeking off-the-beaten-path experiences."
      // },
    //   "geometry": {
    //     "type": "Polygon",
    //     "coordinates": [
    //       [
    //         [
    //           73.94657135009766,
    //           33.643205782197015
    //         ],
    //         [
    //           73.9764404296875,
    //           33.63563128627361
    //         ],
    //         [
    //           73.97369384765625,
    //           33.64249123557401
    //         ],
    //         [
    //           73.97300720214844,
    //           33.648064541959826
    //         ],
    //         [
    //           73.97832870483398,
    //           33.654780611242245
    //         ],
    //         [
    //           73.9932632446289,
    //           33.65578083204094
    //         ],
    //         [
    //           73.98193359375,
    //           33.668354044590075
    //         ],
    //         [
    //           73.97987365722656,
    //           33.68149680197185
    //         ],
    //         [
    //           73.97523880004883,
    //           33.69363778154072
    //         ],
    //         [
    //           73.97850036621094,
    //           33.69735115040591
    //         ],
    //         [
    //           73.97232055664062,
    //           33.705205824960686
    //         ],
    //         [
    //           73.96408081054688,
    //           33.70948989018831
    //         ],
    //         [
    //           73.95927429199219,
    //           33.71834294779959
    //         ],
    //         [
    //           73.96013259887695,
    //           33.72376856418221
    //         ],
    //         [
    //           73.96923065185547,
    //           33.73119253613478
    //         ],
    //         [
    //           73.97781372070312,
    //           33.73518979348226
    //         ],
    //         [
    //           73.97987365722656,
    //           33.74032885072381
    //         ],
    //         [
    //           73.98502349853516,
    //           33.743326491945105
    //         ],
    //         [
    //           73.99463653564453,
    //           33.74361197612151
    //         ],
    //         [
    //           74.00167465209961,
    //           33.747894124738075
    //         ],
    //         [
    //           74.00802612304688,
    //           33.75260424126001
    //         ],
    //         [
    //           74.00871276855469,
    //           33.75660050086414
    //         ],
    //         [
    //           74.00596618652344,
    //           33.762594540992644
    //         ],
    //         [
    //           74.02828216552734,
    //           33.775722878425604
    //         ],
    //         [
    //           74.04888153076172,
    //           33.80882018730745
    //         ],
    //         [
    //           74.0646743774414,
    //           33.819659626496865
    //         ],
    //         [
    //           74.06982421875,
    //           33.83449031726809
    //         ],
    //         [
    //           74.06158447265624,
    //           33.84760762988741
    //         ],
    //         [
    //           74.05025482177734,
    //           33.85958254798477
    //         ],
    //         [
    //           74.03068542480469,
    //           33.87041555094183
    //         ],
    //         [
    //           74.0090560913086,
    //           33.86956036384148
    //         ],
    //         [
    //           73.99600982666016,
    //           33.86271855861567
    //         ],
    //         [
    //           73.9764404296875,
    //           33.85045895313795
    //         ],
    //         [
    //           73.96614074707031,
    //           33.84447106439623
    //         ],
    //         [
    //           73.9544677734375,
    //           33.846181932574986
    //         ],
    //         [
    //           73.94176483154297,
    //           33.84988869610126
    //         ],
    //         [
    //           73.92459869384766,
    //           33.85245482278801
    //         ],
    //         [
    //           73.89747619628905,
    //           33.86243347149933
    //         ],
    //         [
    //           73.88511657714844,
    //           33.86927529957081
    //         ],
    //         [
    //           73.87344360351562,
    //           33.87839688404627
    //         ],
    //         [
    //           73.8555908203125,
    //           33.87782681357735
    //         ],
    //         [
    //           73.8446044921875,
    //           33.8738362136656
    //         ],
    //         [
    //           73.817138671875,
    //           33.884097379274905
    //         ],
    //         [
    //           73.79997253417969,
    //           33.89606717952165
    //         ],
    //         [
    //           73.77799987792969,
    //           33.910314751382735
    //         ],
    //         [
    //           73.76426696777344,
    //           33.91829235152197
    //         ],
    //         [
    //           73.72512817382812,
    //           33.9285481685662
    //         ],
    //         [
    //           73.69766235351562,
    //           33.94506883208477
    //         ],
    //         [
    //           73.67362976074219,
    //           33.95247360616282
    //         ],
    //         [
    //           73.65440368652344,
    //           33.957030069982316
    //         ],
    //         [
    //           73.63861083984375,
    //           33.95361274499209
    //         ],
    //         [
    //           73.63105773925781,
    //           33.94620807002934
    //         ],
    //         [
    //           73.61251831054688,
    //           33.93139678750913
    //         ],
    //         [
    //           73.60153198242186,
    //           33.91544329434573
    //         ],
    //         [
    //           73.58573913574217,
    //           33.904046113106396
    //         ],
    //         [
    //           73.59466552734375,
    //           33.88865750124075
    //         ],
    //         [
    //           73.59294891357422,
    //           33.88466740785103
    //         ],
    //         [
    //           73.59397888183594,
    //           33.87725673930016
    //         ],
    //         [
    //           73.58762741088867,
    //           33.872981060836516
    //         ],
    //         [
    //           73.58882904052734,
    //           33.86129311351553
    //         ],
    //         [
    //           73.57749938964842,
    //           33.84076406581977
    //         ],
    //         [
    //           73.57114791870117,
    //           33.81980224154123
    //         ],
    //         [
    //           73.5641098022461,
    //           33.79883527825399
    //         ],
    //         [
    //           73.564453125,
    //           33.79569692337287
    //         ],
    //         [
    //           73.57612609863281,
    //           33.8159515518711
    //         ],
    //         [
    //           73.59947204589844,
    //           33.815666308702774
    //         ],
    //         [
    //           73.61766815185547,
    //           33.807964383611484
    //         ],
    //         [
    //           73.6471939086914,
    //           33.79997646968148
    //         ],
    //         [
    //           73.67706298828125,
    //           33.790846512234154
    //         ],
    //         [
    //           73.72032165527344,
    //           33.77115672832911
    //         ],
    //         [
    //           73.74092102050781,
    //           33.77201290000953
    //         ],
    //         [
    //           73.74263763427734,
    //           33.78114486556651
    //         ],
    //         [
    //           73.79413604736328,
    //           33.781715581089856
    //         ],
    //         [
    //           73.82881164550781,
    //           33.77543750117441
    //         ],
    //         [
    //           73.86177062988281,
    //           33.75602961803962
    //         ],
    //         [
    //           73.88683319091797,
    //           33.73547530473849
    //         ],
    //         [
    //           73.90262603759766,
    //           33.729193837631136
    //         ],
    //         [
    //           73.90674591064453,
    //           33.7243396617476
    //         ],
    //         [
    //           73.90502929687499,
    //           33.71720066907628
    //         ],
    //         [
    //           73.90777587890625,
    //           33.71120345644536
    //         ],
    //         [
    //           73.927001953125,
    //           33.70948989018831
    //         ],
    //         [
    //           73.9376449584961,
    //           33.70120717129239
    //         ],
    //         [
    //           73.9493179321289,
    //           33.67178278364437
    //         ],
    //         [
    //           73.94760131835938,
    //           33.655209278722346
    //         ],
    //         [
    //           73.94657135009766,
    //           33.643205782197015
    //         ]
    //       ]
    //     ]
    //   }
    // },
   
    // {
      "type": "Feature",
      "properties": {
        "author": "Khawaja Hamad",
        "id": "kupwara",
        "title": "kupwara",
        "district": "Kupwara",
        "details":"Situated in the northwestern part of Jammu and Kashmir, Kupwara emerges as a haven for nature enthusiasts, boasting lush meadows and picturesque mountains. Approximately 80 km from Srinagar, the district encounters cold winters with temperatures plummeting to -4°C, while summers bring a comfortable warmth of up to 29°C. Notable attractions encompass the enchanting Lolab Valley, a pristine haven surrounded by snow-capped mountains; the historic Handwara, known for its cultural heritage, and the serene Lolab Lake, offering tranquility amidst nature's beauty. Famous destinations at Kupwara: Lolab Valley: A pristine valley surrounded by snow-capped mountains ,  Handwara: A historic town known for its cultural heritage."
        
        
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              73.75529766082764,
              34.38027927634609
            ],
            [
              73.7574005126953,
              34.381058476924615
            ],
            [
              73.75933170318604,
              34.38222726419756
            ],
            [
              73.76031875610352,
              34.38406895648966
            ],
            [
              73.76182079315186,
              34.38481270535542
            ],
            [
              73.76469612121582,
              34.38486583002157
            ],
            [
              73.76748561859131,
              34.38534395050027
            ],
            [
              73.76885890960693,
              34.385910608272724
            ],
            [
              73.7717342376709,
              34.39040831829931
            ],
            [
              73.77289295196533,
              34.39479955005034
            ],
            [
              73.77628326416016,
              34.39823446590245
            ],
            [
              73.77782821655273,
              34.399509244161706
            ],
            [
              73.77956628799438,
              34.40057154454329
            ],
            [
              73.78272056579588,
              34.402696104848076
            ],
            [
              73.78503799438477,
              34.406059881670046
            ],
            [
              73.78662586212158,
              34.407192912885925
            ],
            [
              73.79027366638182,
              34.408361335254945
            ],
            [
              73.79250526428223,
              34.40995461218476
            ],
            [
              73.79477977752686,
              34.41218514890259
            ],
            [
              73.79928588867188,
              34.41600878774339
            ],
            [
              73.80310535430907,
              34.41965524340437
            ],
            [
              73.80465030670166,
              34.422876736977386
            ],
            [
              73.80486488342285,
              34.426310500049894
            ],
            [
              73.8076114654541,
              34.429390146951754
            ],
            [
              73.82563591003418,
              34.426239702235286
            ],
            [
              73.83542060852051,
              34.429390146951754
            ],
            [
              73.85001182556152,
              34.44800720442509
            ],
            [
              73.86533260345459,
              34.467716942424836
            ],
            [
              73.8987426757812,
              34.49576950073248
            ],
            [
              73.88668823242199,
              34.51499938964855
            ],
            [
              73.89533996582031,
              34.54666900634777
            ],
            [
              73.94806671142584,
              34.573089599609375
            ],
            [
              73.94142150878906,
              34.59873716790235
            ],
            [
              73.93009185791016,
              34.60354132656319
            ],
            [
              73.92974853515625,
              34.60862777988157
            ],
            [
              73.93421173095703,
              34.61286625296406
            ],
            [
              73.93352508544922,
              34.62275518194733
            ],
            [
              73.93318176269531,
              34.62953548083056
            ],
            [
              73.93455505371094,
              34.6346203413782
            ],
            [
              73.93318176269531,
              34.641117209595805
            ],
            [
              73.93661499023436,
              34.646766246519114
            ],
            [
              73.95172119140624,
              34.64789600772051
            ],
            [
              73.95549774169922,
              34.649025753526985
            ],
            [
              73.95378112792969,
              34.65213247511267
            ],
            [
              73.948974609375,
              34.6555214931444
            ],
            [
              73.95103454589844,
              34.6586279712784
            ],
            [
              73.9570426940918,
              34.663711047966196
            ],
            [
              73.95669937133789,
              34.66709959253004
            ],
            [
              73.95309448242188,
              34.66935854524543
            ],
            [
              73.96116256713867,
              34.67486448442685
            ],
            [
              73.97111892700195,
              34.68658103407748
            ],
            [
              73.98176193237305,
              34.6823463279316
            ],
            [
              74.00819778442383,
              34.68587526476131
            ],
            [
              74.12355422973633,
              34.6987193245323
            ],
            [
              74.20097351074219,
              34.73596993148604
            ],
            [
              74.2797470092774,
              34.76826858520508
            ],
            [
              74.30729675292963,
              34.8003044128418
            ],
            [
              74.34126281738281,
              34.789691925048885
            ],
            [
              74.37554168701183,
              34.803443908691406
            ],
            [
              74.4734001159668,
              34.7905455962799
            ],
            [
              74.50687408447266,
              34.7758828131859
            ],
            [
              74.53811645507812,
              34.776164814362346
            ],
            [
              74.58017730712902,
              34.77009963989269
            ],
            [
              74.63287353515625,
              34.73709847578162
            ],
            [
              74.63939666748047,
              34.733148503316755
            ],
            [
              74.64866638183594,
              34.72299056435158
            ],
            [
              74.61179351806646,
              34.71003341674816
            ],
            [
              74.58556365966808,
              34.6500701904298
            ],
            [
              74.5031127929688,
              34.586364746093864
            ],
            [
              74.5031127929688,
              34.55638122558605
            ],
            [
              74.5257568359375,
              34.529752751979814
            ],
            [
              74.52185058593761,
              34.47393798828131
            ],
            [
              74.46464538574219,
              34.439760996804985
            ],
            [
              74.44315338134766,
              34.37650299072271
            ],
            [
              74.40193176269531,
              34.31653976440441
            ],
            [
              74.33448028564459,
              34.30155181884777
            ],
            [
              74.16584014892584,
              34.339027404785156
            ],
            [
              74.13585662841797,
              34.3052978515625
            ],
            [
              74.09463500976562,
              34.3052978515625
            ],
            [
              74.05334472656249,
              34.340317823030716
            ],
            [
              74.00836944580078,
              34.34740457681595
            ],
            [
              73.91155242919922,
              34.34712111816396
            ],
            [
              73.90399932861328,
              34.352790109119276
            ],
            [
              73.8804817199707,
              34.33471886409211
            ],
            [
              73.8709545135498,
              34.333584852211686
            ],
            [
              73.8607406616211,
              34.33521498946912
            ],
            [
              73.8497543334961,
              34.32444132138531
            ],
            [
              73.84005546569824,
              34.33323047035516
            ],
            [
              73.83868217468262,
              34.333584852211686
            ],
            [
              73.8295841217041,
              34.331742050209684
            ],
            [
              73.8222885131836,
              34.333372223277436
            ],
            [
              73.81628036499023,
              34.33507323966084
            ],
            [
              73.81070137023926,
              34.337128588435945
            ],
            [
              73.8174819946289,
              34.349247034694734
            ],
            [
              73.81482124328613,
              34.350593420615915
            ],
            [
              73.81370544433594,
              34.34995566155914
            ],
            [
              73.81027221679688,
              34.348467538225876
            ],
            [
              73.80606651306151,
              34.34542034612758
            ],
            [
              73.79834175109863,
              34.34258564940402
            ],
            [
              73.79937171936035,
              34.33904214374556
            ],
            [
              73.79825592041016,
              34.33833342464723
            ],
            [
              73.78898620605469,
              34.3366324743773
            ],
            [
              73.7841796875,
              34.335781986306884
            ],
            [
              73.77860069274902,
              34.33521498946912
            ],
            [
              73.76941680908203,
              34.335781986306884
            ],
            [
              73.76220703125,
              34.34683765855365
            ],
            [
              73.75070571899414,
              34.34414474446063
            ],
            [
              73.75182151794434,
              34.35073514419188
            ],
            [
              73.75216484069824,
              34.35612046253825
            ],
            [
              73.75173568725586,
              34.358104439976344
            ],
            [
              73.7523365020752,
              34.36136372952532
            ],
            [
              73.75259399414062,
              34.37036154472887
            ],
            [
              73.75336647033691,
              34.37935839358731
            ],
            [
              73.75529766082764,
              34.38027927634609
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "poonch",
        "title": "Poonch",
        "district": "Poonch",
        "details": "Poonch, located in the union territory of Jammu and Kashmir, Poonch is a district in Jammu known for its historical forts, scenic landscapes, and cultural richness. Lush valleys, cascading waterfalls, and snow-capped peaks paint a mesmerizing picture, waiting to be explored. Hike to the breathtaking Nandan Sar lake, reflecting the grandeur of the Himalayas, or chase the refreshing spray of the Noori Chamb waterfall amidst verdant greenery. Soak in the warmth of the natural sulfur springs at Tatta Pani, believed to possess healing properties.Experience the vibrant spirit of Poonch at the annual festival, where traditional dances, music, and local cuisine transport you into the heart of its rich culture."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.2620849609375,
              34.03160744227741
            ],
            [
              74.3001937866211,
              34.03132292028969
            ],
            [
              74.46258544921875,
              33.99802726234877
            ],
            [
              74.5587158203125,
              33.88295731069693
            ],
            [
              74.564208984375,
              33.80882018730745
            ],
            [
              74.62875366210938,
              33.74946419232578
            ],
            [
              74.67681884765624,
              33.64663552343716
            ],
            [
              74.71664428710938,
              33.610044573695646
            ],
            [
              74.69513702392584,
              33.58803939819336
            ],
            [
              74.72769927978516,
              33.50366973876959
            ],
            [
              74.69516754150396,
              33.49795913696289
            ],
            [
              74.607406616211,
              33.56209945678711
            ],
            [
              74.55023193359386,
              33.57905197143555
            ],
            [
              74.452133178711,
              33.5657196044923
            ],
            [
              74.41117858886724,
              33.51594161987305
            ],
            [
              74.2840576171875,
              33.55627344791359
            ],
            [
              74.23324584960938,
              33.53338195763831
            ],
            [
              74.22294616699219,
              33.51162942617925
            ],
            [
              74.21333312988281,
              33.49330726228523
            ],
            [
              74.20646667480469,
              33.47899037345071
            ],
            [
              74.1796875,
              33.42456461884056
            ],
            [
              74.1862106323242,
              33.45493267328553
            ],
            [
              74.17762756347656,
              33.48271299221316
            ],
            [
              74.16460418701183,
              33.495647430420036
            ],
            [
              74.09571075439464,
              33.570396423339844
            ],
            [
              74.04647827148438,
              33.565428345981616
            ],
            [
              74.02844238281244,
              33.57468032836914
            ],
            [
              74.01077270507812,
              33.604897725023875
            ],
            [
              73.98639678955078,
              33.631772324639655
            ],
            [
              73.9764404296875,
              33.63548836485198
            ],
            [
              73.97266387939453,
              33.6477787401531
            ],
            [
              73.97815704345703,
              33.65349459599047
            ],
            [
              73.99188995361328,
              33.65549505585616
            ],
            [
              73.98365020751953,
              33.66606814261825
            ],
            [
              73.97541046142577,
              33.69349495645655
            ],
            [
              73.97850036621094,
              33.69777960571757
            ],
            [
              73.97232055664062,
              33.704634600118474
            ],
            [
              73.96442413330078,
              33.70948989018831
            ],
            [
              73.95893096923828,
              33.71777181033768
            ],
            [
              73.96099090576172,
              33.7243396617476
            ],
            [
              73.96785736083983,
              33.73119253613478
            ],
            [
              73.97815704345703,
              33.73461876811943
            ],
            [
              73.97953033447266,
              33.73890136570335
            ],
            [
              73.98468017578125,
              33.743469234152094
            ],
            [
              73.98907852172859,
              33.743599221830934
            ],
            [
              73.99463653564453,
              33.7437547178534
            ],
            [
              74.00115966796875,
              33.747751389895576
            ],
            [
              74.00733947753906,
              33.75174787568194
            ],
            [
              74.0090560913086,
              33.759169426528985
            ],
            [
              74.00596618652344,
              33.76173827520807
            ],
            [
              74.02725219726562,
              33.774010600658606
            ],
            [
              74.03583526611328,
              33.78628116836614
            ],
            [
              74.0427017211914,
              33.797408767572485
            ],
            [
              74.04956817626953,
              33.80882018730745
            ],
            [
              74.06572723388678,
              33.82009887695318
            ],
            [
              74.14334106445318,
              33.83013916015625
            ],
            [
              74.21967315673834,
              33.86717605590832
            ],
            [
              74.24491882324219,
              33.894072329464144
            ],
            [
              74.26112365722668,
              33.924797058105526
            ],
            [
              74.26274108886724,
              33.9742889404298
            ],
            [
              74.24886703491211,
              34.013822984463424
            ],
            [
              74.2236328125,
              34.03174970291349
            ],
            [
              74.2620849609375,
              34.03160744227741
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "author": "khawaja Hamad",
        "id": "samba",
        "title": "Samba",
        "district": "Samba",
        "details": "Samba, located in the union territory of Jammu and Kashmir, India. A district in Jammu known for its agricultural activities, industrial growth, and historical significance. This diverse region, often called the City of Sheets due to its textile industry, offers a delightful blend of historical significance, scenic landscapes, and cultural experiences for travelers seeking something beyond the ordinary. Purmandal: Discover the historical significance and scenic beauty of this town, known for its ancient temples and palaces. "
      },
        "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.19729614257812,
              32.40532776669168
            ],
            [
              75.19283294677734,
              32.419529783363124
            ],
            [
              75.18871307373047,
              32.42489118797403
            ],
            [
              75.17995834350585,
              32.425760574910775
            ],
            [
              75.16073226928711,
              32.416196857732615
            ],
            [
              75.14768600463867,
              32.413298561453146
            ],
            [
              75.13893127441406,
              32.41489263592728
            ],
            [
              75.12725830078125,
              32.421123747748766
            ],
            [
              75.12107849121094,
              32.44082861688846
            ],
            [
              75.08245468139648,
              32.48022543421519
            ],
            [
              75.03679275512695,
              32.49224382520596
            ],
            [
              75.02151489257812,
              32.485438427431504
            ],
            [
              74.99954223632812,
              32.45879106783458
            ],
            [
              74.98992919921875,
              32.4535765315941
            ],
            [
              74.97962951660156,
              32.447492524518225
            ],
            [
              74.94941711425781,
              32.45415593941475
            ],
            [
              74.93362426757812,
              32.46777095045055
            ],
            [
              74.89946365356444,
              32.466322642847096
            ],
            [
              74.8828125,
              32.47964619410741
            ],
            [
              74.87886428833008,
              32.48225274523601
            ],
            [
              74.87937927246094,
              32.48428001059022
            ],
            [
              74.88418579101562,
              32.490651118673334
            ],
            [
              74.87749099731445,
              32.49412607839163
            ],
            [
              74.86804962158203,
              32.48529362614186
            ],
            [
              74.86444473266602,
              32.486307230276296
            ],
            [
              74.85877990722656,
              32.49369171576737
            ],
            [
              74.85431671142578,
              32.49485001143792
            ],
            [
              74.85363006591797,
              32.49267819482123
            ],
            [
              74.84968185424805,
              32.49253340518249
            ],
            [
              74.84813690185547,
              32.490940703776516
            ],
            [
              74.84607696533203,
              32.490795911341436
            ],
            [
              74.84539031982422,
              32.49209903486813
            ],
            [
              74.84075546264648,
              32.49311256233922
            ],
            [
              74.83543395996094,
              32.49586350791503
            ],
            [
              74.82856750488281,
              32.49586350791503
            ],
            [
              74.82032775878906,
              32.498180028410744
            ],
            [
              74.88555908203125,
              32.565333160841035
            ],
            [
              74.90890502929688,
              32.57574897397112
            ],
            [
              74.8828125,
              32.58384932565662
            ],
            [
              74.90890502929688,
              32.61508668275336
            ],
            [
              74.9432373046875,
              32.64053128969919
            ],
            [
              74.97482299804688,
              32.66828079034104
            ],
            [
              74.970703125,
              32.71566625570318
            ],
            [
              75.00091552734375,
              32.7503226078097
            ],
            [
              75.0421142578125,
              32.77688335023984
            ],
            [
              75.1409912109375,
              32.7295304134847
            ],
            [
              75.23712158203125,
              32.704111144407406
            ],
            [
              75.34149169921875,
              32.64053128969919
            ],
            [
              75.421142578125,
              32.6266533099821
            ],
            [
              75.43212890625,
              32.60583230501211
            ],
            [
              75.42800903320312,
              32.58616357743131
            ],
            [
              75.35385131835938,
              32.55607364492026
            ],
            [
              75.29617309570312,
              32.553179850239346
            ],
            [
              75.22750854492188,
              32.507445513754526
            ],
            [
              75.19729614257812,
              32.40532776669168
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "author": "khawaja hamad",
        "id": "kathua",
        "title": "Kathua",
        "district": "Kathua",
        "details": "Kathua, located in the union territory of Jammu and Kashmir, Located in the southern part of Jammu, Kathua is known for its historical monuments, religious sites, and scenic beauty. Whether you're an adventure seeker, a history buff, or simply looking for a serene escape, Kathua has something to offer everyone. Basohli: This historical town boasts ancient temples like the Mata Vaishno Devi Temple and the Shiva Temple, each echoing the rich religious heritage of the region. Basantar River: Enjoy a relaxing picnic by the banks of the Basantar River, known for its scenic beauty and diverse birdlife. Rock Inscriptions: Explore the ancient rock inscriptions and petroglyphs found in villages like Ramkot and Jasrota, offering a glimpse into the lives of Kathua's earliest inhabitants."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.90458679199224,
              32.615219116210994
            ],
            [
              75.87158203125,
              32.57574897397112
            ],
            [
              75.83690643310547,
              32.53610456255132
            ],
            [
              75.81424713134766,
              32.49846958927782
            ],
            [
              75.80433654785168,
              32.48706054687506
            ],
            [
              75.79948425292967,
              32.48254235736829
            ],
            [
              75.77579498291016,
              32.465743313283596
            ],
            [
              75.75759887695312,
              32.45908075544229
            ],
            [
              75.73322296142578,
              32.45908075544229
            ],
            [
              75.71153259277344,
              32.41815948486328
            ],
            [
              75.6851577758789,
              32.40141457347703
            ],
            [
              75.6463623046875,
              32.38489033216679
            ],
            [
              75.5801010131836,
              32.37474237006811
            ],
            [
              75.54061889648438,
              32.340810911053424
            ],
            [
              75.52173614501953,
              32.30817250478896
            ],
            [
              75.50216674804688,
              32.276102887033986
            ],
            [
              75.49495697021484,
              32.2758126124583
            ],
            [
              75.49049377441406,
              32.287713108384494
            ],
            [
              75.49022674560541,
              32.310211181640625
            ],
            [
              75.46775817871094,
              32.31756210327154
            ],
            [
              75.47223663330084,
              32.34004974365246
            ],
            [
              75.41499328613287,
              32.32427215576172
            ],
            [
              75.37839889526367,
              32.32906244013147
            ],
            [
              75.33256530761719,
              32.3445817018489
            ],
            [
              75.32569885253906,
              32.33907049307107
            ],
            [
              75.32295227050781,
              32.34313141629363
            ],
            [
              75.31196594238281,
              32.344871756170356
            ],
            [
              75.30750274658203,
              32.3445817018489
            ],
            [
              75.29926300048828,
              32.34777224824119
            ],
            [
              75.30132293701172,
              32.35212281198644
            ],
            [
              75.2999496459961,
              32.35400799129423
            ],
            [
              75.29016494750977,
              32.356183149362046
            ],
            [
              75.28810501098633,
              32.36154831558241
            ],
            [
              75.28879165649413,
              32.36502825318913
            ],
            [
              75.29067993164062,
              32.36966796163583
            ],
            [
              75.289306640625,
              32.37068286611427
            ],
            [
              75.27076721191406,
              32.377931852397595
            ],
            [
              75.26922225952148,
              32.37604717190024
            ],
            [
              75.26235580444336,
              32.37358253040168
            ],
            [
              75.25720596313477,
              32.377496929617855
            ],
            [
              75.26046752929688,
              32.38749962390624
            ],
            [
              75.25772094726562,
              32.38749962390624
            ],
            [
              75.25686264038086,
              32.389673976112896
            ],
            [
              75.2505111694336,
              32.39126846779702
            ],
            [
              75.24158477783203,
              32.38691978781105
            ],
            [
              75.2347183227539,
              32.39039874854987
            ],
            [
              75.2315292358399,
              32.39918899536133
            ],
            [
              75.19740295410168,
              32.40409088134771
            ],
            [
              75.22881317138678,
              32.50748062133789
            ],
            [
              75.29712677001959,
              32.55268859863287
            ],
            [
              75.35227966308605,
              32.555160522460994
            ],
            [
              75.42766571044922,
              32.586452854702216
            ],
            [
              75.43879699707031,
              32.63447189331066
            ],
            [
              75.62233734130865,
              32.67876052856445
            ],
            [
              75.62178802490246,
              32.7391090393067
            ],
            [
              75.63881683349604,
              32.75645065307623
            ],
            [
              75.70730590820312,
              32.758480072021484
            ],
            [
              75.75798797607433,
              32.777969360351676
            ],
            [
              75.78582000732433,
              32.81427001953125
            ],
            [
              75.79519271850586,
              32.869170747331744
            ],
            [
              75.82111358642578,
              32.84296207852914
            ],
            [
              75.87378692626964,
              32.81369018554682
            ],
            [
              75.89973449707031,
              32.7702439075344
            ],
            [
              75.91248321533214,
              32.757122039795036
            ],
            [
              75.91346740722656,
              32.74108223150125
            ],
            [
              75.89657592773443,
              32.691059112548885
            ],
            [
              75.8987045288086,
              32.68099643258195
            ],
            [
              75.92543792724621,
              32.654270172119254
            ],
            [
              75.90458679199224,
              32.615219116210994
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "author": "khawaja hamad",
        "id": "jammu",
        "title": "Jammu",
        "district": "Jammu",
        "details": " Jammu district, nestled amidst the Shivalik range in the Jammu division of Jammu and Kashmir, serves as the winter capital of the union territory. This vibrant region boasts a rich tapestry of history, breathtaking natural landscapes, and diverse cultural experiences, making it a captivating destination for travelers seeking something beyond the ordinary. Bahu Fort Standing tall since the 17th century, this majestic fort offers panoramic views of the city and narrates tales of valor and resilience. Ranbireshwar Temple: Dedicated to Lord Shiva, this intricately carved temple dates back to the 19th century and is a renowned pilgrimage site. Witness the mesmerizing Shivalinga (representation of Lord Shiva) bathed in holy water and experience the spiritual aura of the temple."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.70445632934569,
              32.83474098382776
            ],
            [
              74.70462799072266,
              32.83207257014915
            ],
            [
              74.70531463623047,
              32.81728666309672
            ],
            [
              74.7018814086914,
              32.81339142924961
            ],
            [
              74.69930648803711,
              32.812958614948045
            ],
            [
              74.69758987426758,
              32.81166015939479
            ],
            [
              74.6963882446289,
              32.80487902771385
            ],
            [
              74.69398498535156,
              32.80177684820327
            ],
            [
              74.69038009643555,
              32.79564431439544
            ],
            [
              74.68523025512695,
              32.78720237019107
            ],
            [
              74.68402862548828,
              32.783810936237415
            ],
            [
              74.68239784240723,
              32.77998639816063
            ],
            [
              74.68171119689941,
              32.77594519816977
            ],
            [
              74.6799087524414,
              32.773275018913
            ],
            [
              74.67870712280273,
              32.770965610082975
            ],
            [
              74.67759132385254,
              32.76136648755334
            ],
            [
              74.6737289428711,
              32.7540040532286
            ],
            [
              74.6660041809082,
              32.74498063213084
            ],
            [
              74.66377258300781,
              32.73848320299107
            ],
            [
              74.65965270996094,
              32.73342931945132
            ],
            [
              74.65493202209473,
              32.729169395046895
            ],
            [
              74.66188430786133,
              32.727364280927766
            ],
            [
              74.66377258300781,
              32.72519809573988
            ],
            [
              74.65965270996094,
              32.715955114309224
            ],
            [
              74.6572494506836,
              32.7038222474499
            ],
            [
              74.66720581054688,
              32.69515490382517
            ],
            [
              74.6769905090332,
              32.68489745994719
            ],
            [
              74.69123840332033,
              32.66799177743802
            ],
            [
              74.69673156738281,
              32.6659686609434
            ],
            [
              74.69501495361328,
              32.65989903665079
            ],
            [
              74.69226837158203,
              32.654407116645416
            ],
            [
              74.68832015991211,
              32.65050475793421
            ],
            [
              74.68196868896484,
              32.65166103012337
            ],
            [
              74.68093872070312,
              32.64905939667127
            ],
            [
              74.67802047729492,
              32.648770321615196
            ],
            [
              74.67235565185547,
              32.64443408363816
            ],
            [
              74.67081069946289,
              32.63865210595732
            ],
            [
              74.66720581054688,
              32.63749566557198
            ],
            [
              74.6634292602539,
              32.635038180117945
            ],
            [
              74.65742111206053,
              32.6363392102376
            ],
            [
              74.65536117553711,
              32.63648376797192
            ],
            [
              74.65673446655273,
              32.632725190969275
            ],
            [
              74.65347290039061,
              32.631279542388256
            ],
            [
              74.65639114379883,
              32.63012300670739
            ],
            [
              74.65570449829102,
              32.62564128976466
            ],
            [
              74.65553283691406,
              32.62318347873869
            ],
            [
              74.65484619140625,
              32.61523127481736
            ],
            [
              74.65055465698242,
              32.613640749271305
            ],
            [
              74.64609146118164,
              32.613785343670216
            ],
            [
              74.6414566040039,
              32.61494209045587
            ],
            [
              74.63939666748047,
              32.61450831216246
            ],
            [
              74.64008331298828,
              32.60872440078541
            ],
            [
              74.63922500610352,
              32.603952392681954
            ],
            [
              74.64214324951172,
              32.60265089180301
            ],
            [
              74.64214324951172,
              32.60033706577325
            ],
            [
              74.64591979980467,
              32.59918013034806
            ],
            [
              74.64969635009766,
              32.598457038120664
            ],
            [
              74.6524429321289,
              32.59990321673959
            ],
            [
              74.65415954589844,
              32.59932474809324
            ],
            [
              74.65999603271484,
              32.59686621467839
            ],
            [
              74.65690612792969,
              32.59137042547153
            ],
            [
              74.65415954589844,
              32.58920094221472
            ],
            [
              74.65192794799805,
              32.58818849872313
            ],
            [
              74.65106964111328,
              32.58312610972461
            ],
            [
              74.65639114379883,
              32.580956426922675
            ],
            [
              74.65381622314452,
              32.57820808666309
            ],
            [
              74.65227127075195,
              32.571987847649325
            ],
            [
              74.65227127075195,
              32.56518848825358
            ],
            [
              74.65639114379883,
              32.56446512181675
            ],
            [
              74.65913772583008,
              32.56489914237877
            ],
            [
              74.66239929199219,
              32.56359707439351
            ],
            [
              74.66789245605469,
              32.56808189567349
            ],
            [
              74.67304229736328,
              32.56866056595834
            ],
            [
              74.67527389526367,
              32.56446512181675
            ],
            [
              74.6737289428711,
              32.55549489344868
            ],
            [
              74.67269897460938,
              32.552601080106484
            ],
            [
              74.66136932373045,
              32.55419268899129
            ],
            [
              74.66102600097656,
              32.547826084108564
            ],
            [
              74.6627426147461,
              32.544932023520936
            ],
            [
              74.66995239257812,
              32.54030133253481
            ],
            [
              74.67733383178711,
              32.537262311791075
            ],
            [
              74.69106674194336,
              32.53422318820547
            ],
            [
              74.6902084350586,
              32.52307885527122
            ],
            [
              74.68505859374999,
              32.51743379560992
            ],
            [
              74.6821403503418,
              32.511788381363374
            ],
            [
              74.68626022338867,
              32.50353675354452
            ],
            [
              74.68557357788086,
              32.49919348736698
            ],
            [
              74.68128204345703,
              32.49238861531074
            ],
            [
              74.68626022338867,
              32.49166466245649
            ],
            [
              74.68832015991211,
              32.48674162855308
            ],
            [
              74.6934700012207,
              32.48688642751267
            ],
            [
              74.69501495361328,
              32.48804481080083
            ],
            [
              74.69741821289062,
              32.488623996852596
            ],
            [
              74.70085144042967,
              32.49238861531074
            ],
            [
              74.70548629760742,
              32.49369171576737
            ],
            [
              74.70909118652344,
              32.49340213951936
            ],
            [
              74.7121810913086,
              32.49151987118656
            ],
            [
              74.71132278442383,
              32.490216739270906
            ],
            [
              74.71389770507812,
              32.48790001370537
            ],
            [
              74.71200942993163,
              32.47848770270873
            ],
            [
              74.71458435058594,
              32.477184382062376
            ],
            [
              74.71750259399414,
              32.47747401050349
            ],
            [
              74.72694396972656,
              32.476460306882494
            ],
            [
              74.73020553588867,
              32.48065986184966
            ],
            [
              74.73501205444336,
              32.47993581462727
            ],
            [
              74.74205017089844,
              32.478777326956305
            ],
            [
              74.75200653076172,
              32.479066950271914
            ],
            [
              74.75578308105469,
              32.48254235736829
            ],
            [
              74.76539611816406,
              32.48109428738728
            ],
            [
              74.77020263671875,
              32.48225274523601
            ],
            [
              74.79595184326172,
              32.47732919639942
            ],
            [
              74.8000717163086,
              32.478777326956305
            ],
            [
              74.80144500732422,
              32.48254235736829
            ],
            [
              74.81208801269531,
              32.48138390324739
            ],
            [
              74.81964111328125,
              32.49731134021632
            ],
            [
              74.88452911376953,
              32.56475446909139
            ],
            [
              74.90787506103516,
              32.57574897397112
            ],
            [
              74.8828125,
              32.5838493256566
            ],
            [
              74.90787506103516,
              32.61508668275333
            ],
            [
              74.9435806274414,
              32.64110949213925
            ],
            [
              74.97447967529297,
              32.668280790341015
            ],
            [
              74.9703598022461,
              32.71624397197978
            ],
            [
              75.00057220458984,
              32.7503226078097
            ],
            [
              75.04211425781251,
              32.77746066967457
            ],
            [
              75.1409912109375,
              32.7292415988514
            ],
            [
              75.23746490478516,
              32.704111144407406
            ],
            [
              75.25428771972655,
              32.77255333509089
            ],
            [
              75.22544860839844,
              32.830269542537174
            ],
            [
              75.15953063964844,
              32.871802001540985
            ],
            [
              75.06820678710938,
              32.88045214991982
            ],
            [
              75.03318786621094,
              32.931182680502246
            ],
            [
              74.970703125,
              32.917349963900215
            ],
            [
              74.95765686035155,
              32.931758996763314
            ],
            [
              74.96040344238281,
              33.02046686750982
            ],
            [
              74.93980407714844,
              33.05327769634488
            ],
            [
              74.79904174804688,
              33.03773724796046
            ],
            [
              74.79423522949217,
              32.990523525732755
            ],
            [
              74.77432250976562,
              32.96978756663045
            ],
            [
              74.67681884765624,
              33.00175347002964
            ],
            [
              74.65089797973633,
              33.0054964671851
            ],
            [
              74.63098526000977,
              32.98447604071901
            ],
            [
              74.61502075195311,
              32.99743442981911
            ],
            [
              74.564208984375,
              32.95884162509528
            ],
            [
              74.53193664550781,
              32.95884162509528
            ],
            [
              74.44953918457033,
              32.99973794424245
            ],
            [
              74.39872741699219,
              32.94558946052765
            ],
            [
              74.33469772338867,
              32.954736547403854
            ],
            [
              74.32044982910156,
              32.94155579869799
            ],
            [
              74.3173599243164,
              32.93060636048645
            ],
            [
              74.31770324707031,
              32.91965556683586
            ],
            [
              74.34688568115234,
              32.90812695155533
            ],
            [
              74.41349029541016,
              32.90409158150097
            ],
            [
              74.4279098510742,
              32.88477690756445
            ],
            [
              74.4275665283203,
              32.882182078302655
            ],
            [
              74.4162368774414,
              32.874973820619836
            ],
            [
              74.41349029541016,
              32.86776497678013
            ],
            [
              74.42035675048828,
              32.84873081317057
            ],
            [
              74.42035675048828,
              32.84036602561056
            ],
            [
              74.42447662353516,
              32.83228893100241
            ],
            [
              74.43065643310547,
              32.83084651535614
            ],
            [
              74.43408966064455,
              32.826230627841184
            ],
            [
              74.43992614746094,
              32.817575192144126
            ],
            [
              74.4437026977539,
              32.809496024645036
            ],
            [
              74.44610595703125,
              32.799684619501896
            ],
            [
              74.45091247558592,
              32.79362409299568
            ],
            [
              74.45709228515625,
              32.78987213149016
            ],
            [
              74.46224212646484,
              32.78438821072233
            ],
            [
              74.45915222167967,
              32.78207909030684
            ],
            [
              74.46121215820312,
              32.780347210663045
            ],
            [
              74.49417114257812,
              32.76475877693074
            ],
            [
              74.53468322753906,
              32.75003386055739
            ],
            [
              74.54086303710938,
              32.749745112369084
            ],
            [
              74.542236328125,
              32.752343812367535
            ],
            [
              74.54326629638673,
              32.749745112369084
            ],
            [
              74.54463958740233,
              32.74628006110293
            ],
            [
              74.54875946044922,
              32.752343812367535
            ],
            [
              74.55425262451172,
              32.756963536267065
            ],
            [
              74.56489562988281,
              32.759562025650126
            ],
            [
              74.5693588256836,
              32.75985074201202
            ],
            [
              74.57622528076172,
              32.75580862775797
            ],
            [
              74.5748519897461,
              32.749745112369084
            ],
            [
              74.57691192626953,
              32.75090009950632
            ],
            [
              74.57948684692381,
              32.74916761318447
            ],
            [
              74.58189010620117,
              32.753643133934915
            ],
            [
              74.58377838134764,
              32.7533543974469
            ],
            [
              74.5887565612793,
              32.7503226078097
            ],
            [
              74.59098815917967,
              32.75133321582173
            ],
            [
              74.59304809570312,
              32.75551989829046
            ],
            [
              74.59733963012695,
              32.75681917352253
            ],
            [
              74.60060119628906,
              32.75681917352253
            ],
            [
              74.60678100585936,
              32.75797406892618
            ],
            [
              74.61313247680664,
              32.75811842979854
            ],
            [
              74.61313247680664,
              32.759562025650126
            ],
            [
              74.61828231811522,
              32.76143866526882
            ],
            [
              74.6220588684082,
              32.75985074201202
            ],
            [
              74.62480545043947,
              32.75638608388469
            ],
            [
              74.62875366210939,
              32.75407623691184
            ],
            [
              74.63235855102539,
              32.7517663300311
            ],
            [
              74.63476181030273,
              32.75118884395058
            ],
            [
              74.6356201171875,
              32.7517663300311
            ],
            [
              74.63699340820312,
              32.751477587458865
            ],
            [
              74.63888168334961,
              32.75710789877759
            ],
            [
              74.6385383605957,
              32.75941766711809
            ],
            [
              74.63218688964844,
              32.76403702405818
            ],
            [
              74.63081359863281,
              32.76649095995106
            ],
            [
              74.63064193725586,
              32.76908917128478
            ],
            [
              74.63150024414062,
              32.77067692976577
            ],
            [
              74.63253021240234,
              32.77587303221623
            ],
            [
              74.63459014892578,
              32.77789365679254
            ],
            [
              74.63716506958008,
              32.779192605504846
            ],
            [
              74.63922500610352,
              32.78179044604068
            ],
            [
              74.64128494262695,
              32.7819347682908
            ],
            [
              74.64574813842773,
              32.78092450762348
            ],
            [
              74.65106964111328,
              32.78063585961151
            ],
            [
              74.65347290039061,
              32.78063585961151
            ],
            [
              74.65544700622557,
              32.783738776663405
            ],
            [
              74.65785026550293,
              32.78648079932746
            ],
            [
              74.65278625488281,
              32.79037721245106
            ],
            [
              74.64943885803223,
              32.792253202336
            ],
            [
              74.64617729187012,
              32.79347978971087
            ],
            [
              74.64179992675781,
              32.79636581091521
            ],
            [
              74.6396541595459,
              32.79795308265157
            ],
            [
              74.6330451965332,
              32.807259696706375
            ],
            [
              74.63750839233398,
              32.81454559041408
            ],
            [
              74.63948249816895,
              32.815555469135404
            ],
            [
              74.64111328125,
              32.81808011572246
            ],
            [
              74.64763641357422,
              32.82435535500958
            ],
            [
              74.65433120727539,
              32.828827094089085
            ],
            [
              74.65862274169922,
              32.8296925659695
            ],
            [
              74.66025352478027,
              32.82673550222331
            ],
            [
              74.6630859375,
              32.824499608171514
            ],
            [
              74.66437339782715,
              32.82403078453862
            ],
            [
              74.66703414916992,
              32.82406684798284
            ],
            [
              74.67647552490234,
              32.83113500035979
            ],
            [
              74.67887878417969,
              32.83373132321816
            ],
            [
              74.68317031860352,
              32.836471803875156
            ],
            [
              74.68737602233887,
              32.837553549270126
            ],
            [
              74.69432830810547,
              32.84079870636961
            ],
            [
              74.70522880554199,
              32.8418803990402
            ],
            [
              74.70445632934569,
              32.83474098382776
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "rajauri",
        "title": "Rajauri",
        "district": "Rajouri",
        "details": "Rajouri, located in the union territory of Jammu and Kashmir, India. Rajouri, a district in Jammu, unfolds with a rich historical and cultural heritage amidst diverse landcapes. Situated around 155 km from Jammu, the region experiences cold winters with temperatures around 0°C and warm summers ascending to 39°C. Key attractions include the picturesque Noori Chamb, the historic Darhal showcasing architectural brilliance, and the culturally rich Thanamandi, known for its ancient structures. Rajouri&#39;s scenic beauty and historical significance make it a captivating destination, offering a delightful mix of heritage and natural splendor."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.72763061523438,
              33.50418651485953
            ],
            [
              74.75321197509777,
              33.507877349853516
            ],
            [
              74.80481719970709,
              33.39916229248053
            ],
            [
              74.7779388427735,
              33.32960128784187
            ],
            [
              74.80481719970709,
              33.23632431030274
            ],
            [
              74.72260284423834,
              33.242649078369205
            ],
            [
              74.68643188476562,
              33.21599947992415
            ],
            [
              74.6902084350586,
              33.19388015067254
            ],
            [
              74.739990234375,
              33.17807720840895
            ],
            [
              74.73841857910156,
              33.15411758422852
            ],
            [
              74.70016479492186,
              33.03485908625329
            ],
            [
              74.67647552490234,
              33.001609505429
            ],
            [
              74.65087890625006,
              33.00555038452155
            ],
            [
              74.6312026977539,
              32.984279632568466
            ],
            [
              74.61498260498048,
              32.99728012084962
            ],
            [
              74.5650482177735,
              32.95871734619147
            ],
            [
              74.53279113769531,
              32.95871734619147
            ],
            [
              74.44892120361334,
              32.99957656860352
            ],
            [
              74.39945983886719,
              32.94581604003917
            ],
            [
              74.33489227294933,
              32.954761505126946
            ],
            [
              74.34654235839844,
              32.96546696224175
            ],
            [
              74.35317993164068,
              32.98313140869148
            ],
            [
              74.32868957519531,
              33.00175347002964
            ],
            [
              74.32336807250977,
              33.00952720958259
            ],
            [
              74.32165145874023,
              33.01514115094913
            ],
            [
              74.32130813598633,
              33.01974719488182
            ],
            [
              74.31941986083984,
              33.02219405784735
            ],
            [
              74.32439804077148,
              33.02766327065953
            ],
            [
              74.32491302490236,
              33.03111733547554
            ],
            [
              74.31461334228516,
              33.0305416674074
            ],
            [
              74.30740356445312,
              33.03255648919334
            ],
            [
              74.30173873901367,
              33.032124745545914
            ],
            [
              74.28646087646484,
              33.03716162314079
            ],
            [
              74.27907943725586,
              33.0381689641071
            ],
            [
              74.27513122558594,
              33.03917629355546
            ],
            [
              74.2734146118164,
              33.041334817873235
            ],
            [
              74.2549667358399,
              33.04877471923834
            ],
            [
              74.21633720397949,
              33.057845778802076
            ],
            [
              74.20672416687012,
              33.063132948364306
            ],
            [
              74.19646739959717,
              33.06863558347019
            ],
            [
              74.19342041015624,
              33.06924696613244
            ],
            [
              74.19054508209229,
              33.0700381609192
            ],
            [
              74.18530941009521,
              33.06996623441438
            ],
            [
              74.1808032989502,
              33.07072145978267
            ],
            [
              74.17003154754639,
              33.074137874505595
            ],
            [
              74.17088985443115,
              33.08039496377108
            ],
            [
              74.17144775390625,
              33.08089838838918
            ],
            [
              74.17355060577393,
              33.0805747586083
            ],
            [
              74.17415142059326,
              33.081581602904976
            ],
            [
              74.1738510131836,
              33.08301993190663
            ],
            [
              74.17483806610107,
              33.08391887558772
            ],
            [
              74.17629718780518,
              33.084673981178966
            ],
            [
              74.17672634124756,
              33.085824605803154
            ],
            [
              74.17608261108398,
              33.08661565149925
            ],
            [
              74.17784214019775,
              33.08747860232477
            ],
            [
              74.17925834655762,
              33.087119040509975
            ],
            [
              74.1812753677368,
              33.08722690920878
            ],
            [
              74.18200492858887,
              33.08773029472033
            ],
            [
              74.18230533599854,
              33.088916834881424
            ],
            [
              74.18312072753906,
              33.08913256773594
            ],
            [
              74.1837215423584,
              33.09082245678383
            ],
            [
              74.18230533599854,
              33.09157750309439
            ],
            [
              74.18166160583496,
              33.093339252602526
            ],
            [
              74.1802453994751,
              33.094130230694
            ],
            [
              74.17947292327881,
              33.09625145498569
            ],
            [
              74.17827129364014,
              33.09758168817222
            ],
            [
              74.17724132537842,
              33.09963092693021
            ],
            [
              74.17337894439696,
              33.10103301013125
            ],
            [
              74.17131900787354,
              33.101787968744254
            ],
            [
              74.16938781738281,
              33.10304621868762
            ],
            [
              74.16844367980956,
              33.10369331164375
            ],
            [
              74.16837930679321,
              33.105490767076844
            ],
            [
              74.16857242584229,
              33.10671301577042
            ],
            [
              74.16737079620361,
              33.10793524746291
            ],
            [
              74.16217803955077,
              33.11066723329925
            ],
            [
              74.1571569442749,
              33.121486500569105
            ],
            [
              74.15325164794922,
              33.13084894342617
            ],
            [
              74.11170196533209,
              33.16444015502937
            ],
            [
              74.08321380615236,
              33.18080701119418
            ],
            [
              74.02587890625,
              33.1887085924766
            ],
            [
              74.01427459716808,
              33.198249816894524
            ],
            [
              74.01077270507812,
              33.23868752757414
            ],
            [
              74.02030181884766,
              33.258781433105526
            ],
            [
              74.03643608093262,
              33.264671031825735
            ],
            [
              74.07909393310547,
              33.26022136672177
            ],
            [
              74.10298156738293,
              33.27054214477551
            ],
            [
              74.17074584960938,
              33.34664916992189
            ],
            [
              74.1848373413086,
              33.38357955709389
            ],
            [
              74.1796875,
              33.42485115958141
            ],
            [
              74.19342041015624,
              33.45264111550275
            ],
            [
              74.23324584960938,
              33.5328095926933
            ],
            [
              74.25796508789062,
              33.544828460753685
            ],
            [
              74.2840576171875,
              33.55627344791359
            ],
            [
              74.41117858886724,
              33.51594161987304
            ],
            [
              74.452133178711,
              33.565719604492294
            ],
            [
              74.55023193359386,
              33.579051971435554
            ],
            [
              74.607406616211,
              33.56209945678712
            ],
            [
              74.696044921875,
              33.49846076288059
            ],
            [
              74.72763061523438,
              33.50418651485953
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "kishtwar",
        "title": "Kishtwar",
        "district": "Kishtwar",
        "details": "Kishtwar, located in the union territory of Jammu and Kashmir, Located in the eastern part of Jammu, Kishtwar is known for its dense forests, rivers, and diverse topography. Kishtwar is one of the largest district of Jammu and Kashmir locked in Himalayan mountains. Some major tourist attractions in Kishtwar are : Sinthan Top, Saffron Valley, Sapphire Mines at Paddar, Mughal Maidan, Machail Valley, Kishtwar National Park, Dachhan Valley, Marwah and Warwan valley is too picturesque. Kishtwar possesses a unique position in Jammu and Kashmir because of Kishtwar High Altitude National Park spread over an area of 2190.50 Sq. Kms providing a natural habitat to various species of mammals and and of birds, this sanctuary has an altitude range of 1700 to 4800 Metres."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.72086334228516,
              34.20186451799857
            ],
            [
              75.6463623046875,
              34.14818102254435
            ],
            [
              75.6243896484375,
              34.057210513510306
            ],
            [
              75.673828125,
              33.98436372829188
            ],
            [
              75.5145263671875,
              34.01168859910852
            ],
            [
              75.55435180664062,
              33.9416510267666
            ],
            [
              75.59692382812499,
              33.87041555094183
            ],
            [
              75.6298828125,
              33.792843773631844
            ],
            [
              75.6298828125,
              33.71977077483141
            ],
            [
              75.61065673828125,
              33.53910539867444
            ],
            [
              75.7012939453125,
              33.422272258866045
            ],
            [
              75.8056640625,
              33.35347332342168
            ],
            [
              75.849609375,
              33.27543541298162
            ],
            [
              75.849609375,
              33.19732768648872
            ],
            [
              75.9210205078125,
              33.17434155100208
            ],
            [
              76.08032226562499,
              33.17434155100208
            ],
            [
              76.17919921875,
              33.215712251730736
            ],
            [
              76.234130859375,
              33.22949814144951
            ],
            [
              76.2396240234375,
              33.19732768648872
            ],
            [
              76.2066650390625,
              33.142150831105354
            ],
            [
              76.1627197265625,
              33.100745405144245
            ],
            [
              76.1407470703125,
              33.063924198120645
            ],
            [
              76.168212890625,
              33.050112271849656
            ],
            [
              76.18486404418945,
              33.026799733303214
            ],
            [
              76.23722076416016,
              33.03284431711645
            ],
            [
              76.25507354736328,
              33.05270217308248
            ],
            [
              76.27464294433594,
              33.10390900823696
            ],
            [
              76.29249572753906,
              33.120587941077815
            ],
            [
              76.35738372802734,
              33.15508602250826
            ],
            [
              76.39240264892577,
              33.1869846718891
            ],
            [
              76.46896362304688,
              33.18066333948355
            ],
            [
              76.49436950683594,
              33.19617852296372
            ],
            [
              76.53453826904297,
              33.19991324928074
            ],
            [
              76.5468978881836,
              33.20996748987798
            ],
            [
              76.58088684082031,
              33.20709496754046
            ],
            [
              76.58500671386719,
              33.19100710048419
            ],
            [
              76.59805297851562,
              33.187559315854564
            ],
            [
              76.59942626953125,
              33.17434155100208
            ],
            [
              76.62620544433592,
              33.16284622181141
            ],
            [
              76.6519546508789,
              33.17204260575893
            ],
            [
              76.68525695800781,
              33.17491627789081
            ],
            [
              76.69692993164062,
              33.18210004598892
            ],
            [
              76.72988891601562,
              33.18066333948355
            ],
            [
              76.75872802734374,
              33.22317987833434
            ],
            [
              76.76233291625975,
              33.239118256353414
            ],
            [
              76.7559814453125,
              33.247301699949205
            ],
            [
              76.7556381225586,
              33.24988578612424
            ],
            [
              76.77898406982422,
              33.255627926285285
            ],
            [
              76.80267333984375,
              33.236677431879365
            ],
            [
              76.81434631347656,
              33.247301699949205
            ],
            [
              76.79443359375,
              33.251895578102406
            ],
            [
              76.77452087402342,
              33.280888859602065
            ],
            [
              76.77005767822266,
              33.324504165325195
            ],
            [
              76.71409606933594,
              33.35691456311512
            ],
            [
              76.70654296875,
              33.40994978703926
            ],
            [
              76.63410186767577,
              33.4397499747159
            ],
            [
              76.53453826904297,
              33.534812853422785
            ],
            [
              76.47342681884766,
              33.53452667616054
            ],
            [
              76.42158508300781,
              33.59174327144985
            ],
            [
              76.40098571777344,
              33.59517506146791
            ],
            [
              76.32373809814453,
              33.555701234563486
            ],
            [
              76.3065719604492,
              33.56199537293026
            ],
            [
              76.3330078125,
              33.704920213014425
            ],
            [
              76.2289810180664,
              33.864143979919355
            ],
            [
              76.0964584350586,
              33.98692580828029
            ],
            [
              75.9982681274414,
              33.96215580011896
            ],
            [
              75.9375,
              33.98066280973604
            ],
            [
              75.83484649658203,
              34.07171548887981
            ],
            [
              75.79330444335938,
              34.1496016605685
            ],
            [
              75.73837280273438,
              34.203284252545856
            ],
            [
              75.72695732116699,
              34.206265617265565
            ],
            [
              75.72086334228516,
              34.20186451799857
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "doda",
        "title": "Doda",
        "district": "Doda",
        "details": "Doda, located in the union territory of Jammu and Kashmir, India.Known for its diverse topography, including mountains and forests, Doda is a district with natural beauty. Dotted across the district, temples like Shiv Mandir Bhaderwah and Mata Chandi Shrine showcase the region's rich religious heritage. Witness the majestic flow of the Chenab River and the breathtaking landscapes it carves through the valley. Savor authentic flavors of Doda with dishes like Rogan Josh, Kashmiri Pulao, and local trout delicacies. Doda Fort  Standing tall since the 18th century, this imposing fort narrates tales of valor and resilience. Explore its walls, bastions, and courtyards, and delve into the fascinating history of the region."
        
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.69580078125,
              33.422272258866045
            ],
            [
              75.59692382812499,
              33.408516828002675
            ],
            [
              75.4541015625,
              33.48185394054361
            ],
            [
              75.4815673828125,
              33.37182502950726
            ],
            [
              75.531005859375,
              33.25246979589199
            ],
            [
              75.5145263671875,
              33.17893926058104
            ],
            [
              75.48688888549805,
              33.10060160229781
            ],
            [
              75.52001953125,
              33.073130945006625
            ],
            [
              75.618896484375,
              33.08693925905123
            ],
            [
              75.640869140625,
              33.03629817885956
            ],
            [
              75.6243896484375,
              32.95336814579932
            ],
            [
              75.65185546874999,
              32.93492866908233
            ],
            [
              75.7232666015625,
              32.91187391621322
            ],
            [
              75.79519271850586,
              32.869206792437446
            ],
            [
              75.78781127929688,
              32.89313750722354
            ],
            [
              75.80017089843749,
              32.904091581501
            ],
            [
              75.79793930053711,
              32.91936736975363
            ],
            [
              75.8082389831543,
              32.929597791424136
            ],
            [
              75.82815170288086,
              32.93406422425989
            ],
            [
              75.88102340698242,
              32.92477090886759
            ],
            [
              75.91346740722656,
              32.906109289516756
            ],
            [
              75.93406677246094,
              32.884272363380525
            ],
            [
              75.98762512207031,
              32.90084874015597
            ],
            [
              76.01715087890625,
              32.917638167551885
            ],
            [
              76.0279655456543,
              32.9372338139709
            ],
            [
              76.05079650878906,
              32.944725119912576
            ],
            [
              76.08169555664062,
              32.96892346265865
            ],
            [
              76.09362602233887,
              33.004632712706915
            ],
            [
              76.1283016204834,
              33.01003103943056
            ],
            [
              76.15242004394531,
              33.02298567544262
            ],
            [
              76.18486404418945,
              33.026799733303214
            ],
            [
              76.168212890625,
              33.049536727897674
            ],
            [
              76.1407470703125,
              33.06334874445787
            ],
            [
              76.16203308105469,
              33.1001701923468
            ],
            [
              76.20597839355469,
              33.1427257689991
            ],
            [
              76.23893737792969,
              33.19847683493303
            ],
            [
              76.23344421386719,
              33.22892377275307
            ],
            [
              76.17988586425781,
              33.21743560674893
            ],
            [
              76.07894897460938,
              33.17491627789081
            ],
            [
              75.9210205078125,
              33.17434155100208
            ],
            [
              75.84823608398438,
              33.19790226259598
            ],
            [
              75.84892272949219,
              33.276583535317876
            ],
            [
              75.80497741699219,
              33.354046872818536
            ],
            [
              75.69923400878906,
              33.42399153452156
            ],
            [
              75.69580078125,
              33.422272258866045
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ramban",
        "title": "Ramban",
        "district": "Ramban",
        "details": "Ramban, nestled amidst the Pir Panjal Range in the Jammu and Kashmir region, is a destination that blends serene natural beauty with historical charm and adventure opportunities. This district, situated on the banks of the Chenab River along the National Highway-44, offers a captivating experience for travelers seeking breathtaking landscapes, historical exploration, and outdoor activities. Ramban Fort Standing as a silent sentinel since the 18th century, this fort narrates the history of the region. Explore its walls, bastions, and courtyards, and let your imagination travel back in time. Loran This quaint village, boasts the Lohar Kot fort and ancient temples like Mata Chandi and Baba Balak Nath. Immerse yourself in the rich cultural heritage and feel the whispers of the past come alive. "
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.48688888549805,
              33.10060160229781
            ],
            [
              75.29617309570312,
              33.151349385342506
            ],
            [
              75.20004272460938,
              33.17434155100208
            ],
            [
              75.16983032226562,
              33.19847683493303
            ],
            [
              75.13412475585938,
              33.235241620946844
            ],
            [
              75.12554168701172,
              33.25103424434307
            ],
            [
              75.20896911621094,
              33.33425723498022
            ],
            [
              75.1351547241211,
              33.45493267328553
            ],
            [
              75.14665603637695,
              33.489871424805116
            ],
            [
              75.21514892578124,
              33.46925353734004
            ],
            [
              75.19454956054688,
              33.535671379525525
            ],
            [
              75.22167205810547,
              33.569147246109395
            ],
            [
              75.2237319946289,
              33.54253928139885
            ],
            [
              75.2398681640625,
              33.53109247512311
            ],
            [
              75.28793334960938,
              33.52422366383016
            ],
            [
              75.34423828125,
              33.51162942617925
            ],
            [
              75.41015624999999,
              33.489871424805116
            ],
            [
              75.4541015625,
              33.48185394054361
            ],
            [
              75.4815673828125,
              33.37182502950726
            ],
            [
              75.531005859375,
              33.252182687468874
            ],
            [
              75.5141830444336,
              33.178364560075266
            ],
            [
              75.48688888549805,
              33.10060160229781
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "reasi",
        "title": "Reasi",
        "district": "Reasi",
        "details": "Reasi, located in the union territory of Jammu and Kashmir, I Famous for the Mata Vaishno Devi Shrine, Reasi is situated in the Trikuta Mountains and has diverse landscapes. Shiv Khori Shrine Embark on a spiritual journey to this cave temple dedicated to Lord Shiva, adorned with intricate carvings and a serene atmosphere. Sanasar: Hike through this charming hill station with stunning views of the valley below. Spot diverse birdlife, indulge in picnics amidst nature, and experience the tranquility of the Himalayas. Reasi Fort: Standing tall since the 8th century, this imposing fort narrates tales of valor and resilience. Explore its walls, bastions, and courtyards, and delve into the fascinating history of the region."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.135498046875,
              33.45493267328553
            ],
            [
              75.06683349609374,
              33.456651301881855
            ],
            [
              75.02014160156249,
              33.463525475613785
            ],
            [
              74.98992919921875,
              33.458942753687644
            ],
            [
              74.96520996093749,
              33.445193134508465
            ],
            [
              74.88779067993164,
              33.42671365134311
            ],
            [
              74.80865478515625,
              33.408516828002675
            ],
            [
              74.77844238281249,
              33.330528249028085
            ],
            [
              74.80453491210938,
              33.23639027157906
            ],
            [
              74.72248077392578,
              33.24242043982672
            ],
            [
              74.68643188476562,
              33.215712251730736
            ],
            [
              74.6905517578125,
              33.19388015067254
            ],
            [
              74.739990234375,
              33.17778985580038
            ],
            [
              74.73793029785156,
              33.15422373575258
            ],
            [
              74.70016479492186,
              33.035146906654944
            ],
            [
              74.67681884765624,
              33.00175347002964
            ],
            [
              74.7744083404541,
              32.96985957491321
            ],
            [
              74.79423522949217,
              32.99023555965106
            ],
            [
              74.79938507080078,
              33.03802505895996
            ],
            [
              74.93946075439453,
              33.0535654565654
            ],
            [
              74.96040344238281,
              33.02104260138234
            ],
            [
              74.95765686035155,
              32.93204715348585
            ],
            [
              74.970703125,
              32.91648534731439
            ],
            [
              75.03284454345703,
              32.931182680502246
            ],
            [
              75.03387451171875,
              33.04320549616557
            ],
            [
              75.07781982421875,
              33.052414410040605
            ],
            [
              75.06134033203125,
              33.098444531367186
            ],
            [
              75.06271362304688,
              33.14100094401691
            ],
            [
              75.091552734375,
              33.17664043594348
            ],
            [
              75.12863159179688,
              33.20996748987798
            ],
            [
              75.13378143310547,
              33.23581594814914
            ],
            [
              75.12588500976562,
              33.25132135653952
            ],
            [
              75.20828247070312,
              33.334544073442025
            ],
            [
              75.135498046875,
              33.45493267328553
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "udhampur",
        "title": "Udhampur",
        "district": "Udhampur",
        "details": "Udhampur, located in the union territory of Jammu and Kashmir. It is located in the foothills of the Himalayas, and is known for its natural beauty, historical sites, and religious shrines. A district in Jammu known for its military importance, Udhampur has scenic locales and historical significance. Patnitop: A hill station located at an altitude of 2,024 meters (6,640 feet), Patnitop is known for its scenic beauty, and is a popular destination for trekking, camping, and paragliding. Sudh Mahadev Temple A Hindu temple dedicated to Lord Shiva, the Sudh Mahadev Temple is located in a cave, and is a popular pilgrimage destination. Krimchi Temples: A group of ancient Hindu temples, the Krimchi Temples are located in a village called Krimchi, and are known for their intricate architecture."
        
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.43212890625,
              32.60641073163761
            ],
            [
              75.43865203857422,
              32.634604499207136
            ],
            [
              75.51555633544922,
              32.653539940539815
            ],
            [
              75.62232971191406,
              32.67897361056713
            ],
            [
              75.62095642089844,
              32.73906077143313
            ],
            [
              75.63880920410156,
              32.7572522610541
            ],
            [
              75.7071304321289,
              32.75869587094758
            ],
            [
              75.75828552246094,
              32.77890395187418
            ],
            [
              75.78609466552734,
              32.8149783969858
            ],
            [
              75.7950210571289,
              32.86949515275563
            ],
            [
              75.7236099243164,
              32.91129747043409
            ],
            [
              75.6521987915039,
              32.93492866908233
            ],
            [
              75.6243896484375,
              32.95336814579932
            ],
            [
              75.64018249511719,
              33.0365859945602
            ],
            [
              75.6185531616211,
              33.08693925905123
            ],
            [
              75.52001953125,
              33.07341864032513
            ],
            [
              75.4874038696289,
              33.100745405144245
            ],
            [
              75.19969940185545,
              33.17434155100208
            ],
            [
              75.17017364501953,
              33.19847683493303
            ],
            [
              75.13412475585938,
              33.23610311033566
            ],
            [
              75.12863159179688,
              33.21082922819622
            ],
            [
              75.0918960571289,
              33.17778985580038
            ],
            [
              75.06271362304688,
              33.14100094401691
            ],
            [
              75.06168365478516,
              33.09901975545824
            ],
            [
              75.07781982421875,
              33.0532776963449
            ],
            [
              75.03456115722656,
              33.04320549616557
            ],
            [
              75.03215789794922,
              32.931758996763335
            ],
            [
              75.06820678710938,
              32.88045214991984
            ],
            [
              75.15953063964844,
              32.872090353419075
            ],
            [
              75.22613525390625,
              32.82998105472195
            ],
            [
              75.25428771972655,
              32.77313068261673
            ],
            [
              75.23780822753906,
              32.704400040429604
            ],
            [
              75.34149169921875,
              32.64053128969919
            ],
            [
              75.42045593261719,
              32.6266533099821
            ],
            [
              75.43212890625,
              32.60641073163761
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "anantang",
        "title": "Anantang",
        "district": "Anantang",
        "details": "Anantnag, located in the union territory of Jammu and Kashmir, India.  Located in southern Kashmir, Anantnag is known for its religious sites, including the revered Amarnath Cave. Key Attractions are Amarnath Cave, Martand Sun Temple, and the scenic Aharbal Waterfall.Lofty mountains with snowy peaks and sweet streams of freshwater are the true identity of this heavenly place. Moreover, its close proximity to other famous places such as Pahalgam, Srinagar, and Patnitop makes it a worth-visiting destination.The top sightseeing places in Anantnag are Martand Sun Temple, Amarnath Ji's cave, Kishtwar national park, Aishmuqam Shrine, Jhelum River, Kherbawani Asthapan."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.6134033203125,
              34.23451236236987
            ],
            [
              75.5694580078125,
              34.18454183141725
            ],
            [
              75.4815673828125,
              34.20725938207231
            ],
            [
              75.4266357421875,
              34.17999758688084
            ],
            [
              75.26664733886719,
              34.21236998742515
            ],
            [
              75.1845932006836,
              34.225996753139725
            ],
            [
              75.0970458984375,
              34.20271636159618
            ],
            [
              75.12176513671874,
              34.09588492955209
            ],
            [
              75.1629638671875,
              34.03217648339047
            ],
            [
              75.311279296875,
              33.97753113740941
            ],
            [
              75.333251953125,
              33.95247360616282
            ],
            [
              75.3277587890625,
              33.911454454267606
            ],
            [
              75.201416015625,
              33.8247936182649
            ],
            [
              75.18356323242186,
              33.79854997801964
            ],
            [
              75.05859375,
              33.7243396617476
            ],
            [
              75.07232666015625,
              33.68092541950744
            ],
            [
              75.17326354980469,
              33.6689255105912
            ],
            [
              75.18905639648438,
              33.65578083204094
            ],
            [
              75.223388671875,
              33.63291573870479
            ],
            [
              75.2786636352539,
              33.52565471117594
            ],
            [
              75.34767150878905,
              33.51048440456585
            ],
            [
              75.40740966796875,
              33.49101671911273
            ],
            [
              75.45547485351562,
              33.48185394054361
            ],
            [
              75.59623718261719,
              33.407943637769115
            ],
            [
              75.69786071777344,
              33.42399153452156
            ],
            [
              75.61065673828125,
              33.540250041407845
            ],
            [
              75.6298828125,
              33.714059324224124
            ],
            [
              75.62850952148436,
              33.79398504493932
            ],
            [
              75.59829711914062,
              33.86927529957081
            ],
            [
              75.51315307617188,
              34.01282694464166
            ],
            [
              75.67520141601561,
              33.985502440044165
            ],
            [
              75.62370300292969,
              34.05749494865576
            ],
            [
              75.6350326538086,
              34.10327655697847
            ],
            [
              75.6460189819336,
              34.147896892072424
            ],
            [
              75.72687149047852,
              34.20619463361675
            ],
            [
              75.63400268554688,
              34.23337699755914
            ],
            [
              75.61820983886719,
              34.24529756448196
            ],
            [
              75.6134033203125,
              34.23451236236987
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "bandipora",
        "title": "Bandipora",
        "district": "Bandipora",
        "details": "Nestled in the northern expanse of Kashmir, Bandipora is renowned for its captivating landscapes and the majestic Wular Lake, one of Asia's largest freshwater lakes. Positioned approximately 65 km from Srinagar, the town experiences chilly winters with temperatures dropping to -6°C and pleasant summers ranging up to 32°C. The Wular Lake, Arin Nallah, and the historic Madumati Temple are key attractions, offering visitors a harmonious blend of natural beauty and cultural heritage in this tranquil region. Famous destinations at Bandipora are. Wular Lake  One of Asia's largest freshwater lakes, offering scenic boat rides.      Arin Nallah: A picturesque stream enhancing the natural beauty of Bandipora. Madumati Temple: A historic temple with cultural significance and architectural charm."

       
        
       
        
        
        
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.47761917114258,
              34.37362035751525
            ],
            [
              75.45822143554688,
              34.3649771414993
            ],
            [
              75.43899536132812,
              34.36837785748377
            ],
            [
              75.41290283203125,
              34.37517887533528
            ],
            [
              75.39230346679688,
              34.38764597384264
            ],
            [
              75.38063049316406,
              34.38141265660888
            ],
            [
              75.3826904296875,
              34.371778435431224
            ],
            [
              75.3607177734375,
              34.3649771414993
            ],
            [
              75.32501220703125,
              34.37064492478658
            ],
            [
              75.2398681640625,
              34.379712580462204
            ],
            [
              75.1959228515625,
              34.397844946449865
            ],
            [
              75.17669677734375,
              34.43636318093373
            ],
            [
              75.16021728515624,
              34.474863669009004
            ],
            [
              75.12176513671874,
              34.472599425831355
            ],
            [
              75.12176513671874,
              34.45448326886294
            ],
            [
              75.135498046875,
              34.42277053587108
            ],
            [
              74.849853515625,
              34.45221847282654
            ],
            [
              74.805908203125,
              34.42730166315869
            ],
            [
              74.783935546875,
              34.3774457585774
            ],
            [
              74.74822998046875,
              34.35477416538757
            ],
            [
              74.6905517578125,
              34.36611072883117
            ],
            [
              74.5590591430664,
              34.371778435431224
            ],
            [
              74.54429626464842,
              34.453917075612
            ],
            [
              74.52163696289062,
              34.474014585017066
            ],
            [
              74.5257568359375,
              34.52918706954935
            ],
            [
              74.50309753417969,
              34.55633549113736
            ],
            [
              74.5037841796875,
              34.58686687870012
            ],
            [
              74.586181640625,
              34.649590620657044
            ],
            [
              74.61141586303711,
              34.709726932134586
            ],
            [
              74.64866638183594,
              34.72299056435158
            ],
            [
              74.67132568359375,
              34.70083628588871
            ],
            [
              74.70256805419922,
              34.6932149715449
            ],
            [
              74.75578308105469,
              34.686863340119274
            ],
            [
              74.83268737792967,
              34.677829069312956
            ],
            [
              74.85397338867188,
              34.67924073910247
            ],
            [
              74.87182617187499,
              34.681781684080676
            ],
            [
              74.88693237304688,
              34.67613503380097
            ],
            [
              74.89053726196289,
              34.674582137474545
            ],
            [
              74.90615844726562,
              34.673452740040084
            ],
            [
              74.91113662719727,
              34.67415861524134
            ],
            [
              75.01859664916992,
              34.641540900714524
            ],
            [
              75.14219284057617,
              34.66258150231496
            ],
            [
              75.20742416381836,
              34.64620136014535
            ],
            [
              75.25016784667969,
              34.64718990877372
            ],
            [
              75.26613235473633,
              34.639422423473846
            ],
            [
              75.25840759277344,
              34.617387052407175
            ],
            [
              75.26184082031249,
              34.610888325786846
            ],
            [
              75.35110473632812,
              34.56184893215098
            ],
            [
              75.3962516784668,
              34.548418116253366
            ],
            [
              75.46440124511717,
              34.543469374381196
            ],
            [
              75.44174194335938,
              34.487881874939866
            ],
            [
              75.48259735107422,
              34.3923206572182
            ],
            [
              75.47761917114258,
              34.37362035751525
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "ganderbal",
        "title": "Ganderbal",
        "district": "Ganderbal",
        "details": "Known for the iconic Dal Lake, Naranag Temple, and stunning landscapes, Ganderbal is a captivating destination approximately 23 km from Srinagar. The temperature ranges from -5°C in winter to 30°C in summer, offering diverse exploration opportunities. Key attractions feature the famous Dal Lake, renowned for shikara rides, houseboats, and vibrant floating markets; the tranquil Manasbal Lake, surrounded by lush gardens and migratory birds, and the ancient Naranag Temple, an architectural marvel reflecting the region's rich history. Famous destinations at Ganderbal: Dal Lake: Famous for shikara rides, houseboats, and vibrant floating markets, Manasbal Lake: A peaceful lake surrounded by lush gardens and migratory birds, Naranag Temple: An ancient Hindu temple reflecting architectural brilliance."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.9271011352539,
              34.20158056821986
            ],
            [
              74.81277465820312,
              34.18510984477345
            ],
            [
              74.69673156738281,
              34.20725938207231
            ],
            [
              74.61381912231445,
              34.17999758688084
            ],
            [
              74.5418930053711,
              34.19192570670952
            ],
            [
              74.62480545043945,
              34.27083595165
            ],
            [
              74.55940246582031,
              34.371778435431224
            ],
            [
              74.63493347167969,
              34.36951139880403
            ],
            [
              74.68986511230469,
              34.36611072883117
            ],
            [
              74.74634170532227,
              34.35477416538757
            ],
            [
              74.78410720825194,
              34.37631232462591
            ],
            [
              74.80607986450194,
              34.426168904360736
            ],
            [
              74.85071182250977,
              34.452784677593776
            ],
            [
              75.13429641723631,
              34.424469737383646
            ],
            [
              75.12262344360352,
              34.45561564384829
            ],
            [
              75.12125015258789,
              34.472599425831355
            ],
            [
              75.16038894653319,
              34.47542972020379
            ],
            [
              75.19472122192383,
              34.39897808891371
            ],
            [
              75.23935317993164,
              34.379712580462204
            ],
            [
              75.32312393188477,
              34.371211682026164
            ],
            [
              75.36088943481444,
              34.364410342082074
            ],
            [
              75.3830337524414,
              34.371778435431224
            ],
            [
              75.38080215454102,
              34.38141265660888
            ],
            [
              75.39230346679688,
              34.38778763475007
            ],
            [
              75.41238784790039,
              34.375745601897954
            ],
            [
              75.43933868408203,
              34.36837785748377
            ],
            [
              75.45804977416992,
              34.36469374226994
            ],
            [
              75.47761917114256,
              34.37347867263908
            ],
            [
              75.57735443115233,
              34.27679379300553
            ],
            [
              75.61820983886719,
              34.2455104164077
            ],
            [
              75.61331748962402,
              34.2344414025177
            ],
            [
              75.56962966918944,
              34.18482583857345
            ],
            [
              75.48242568969727,
              34.20754331272217
            ],
            [
              75.42646408081055,
              34.18056563083384
            ],
            [
              75.26647567749022,
              34.21265390085775
            ],
            [
              75.18476486206055,
              34.226280620653775
            ],
            [
              75.09721755981445,
              34.20264537495848
            ],
            [
              74.9271011352539,
              34.20158056821986
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "baramullah",
        "title": "Baramullah",
        "district": "Baramullah",
        "details": "Baramulla, a major district in the Kashmir Valley, is steeped in historical significance and vibrant culture, located around 55 km from Srinagar. Winter temperatures drop to -3°C, while pleasant summers reach 34°C. Renowned destinations include the picturesque Gulmarg, a hill station and popular skiing destination; the historic Uri, significant for its military importance and breathtaking scenery, and the culturally rich town of Pattan. Baramulla seamlessly combines natural beauty with historical charm, creating a compelling experience for those exploring Kashmir.Famous destinations at Baramulla: Gulmarg: A renowned hill station with scenic landscapes and a popular skiing destination, Uri: A historic town known for its military importance and breathtaking scenery. "
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.53674316406249,
              34.01965669732604
            ],
            [
              74.5920181274414,
              34.02762404762424
            ],
            [
              74.62944030761719,
              34.037582183607775
            ],
            [
              74.61090087890625,
              34.06517433677496
            ],
            [
              74.59510803222656,
              34.09645353919802
            ],
            [
              74.58686828613281,
              34.130563126215506
            ],
            [
              74.61296081542969,
              34.17999758688084
            ],
            [
              74.54154968261719,
              34.19192570670952
            ],
            [
              74.6246337890625,
              34.27055223438805
            ],
            [
              74.55940246582031,
              34.371778435431224
            ],
            [
              74.54360961914062,
              34.45448326886294
            ],
            [
              74.52232360839844,
              34.474297613974336
            ],
            [
              74.46395874023438,
              34.44032728601712
            ],
            [
              74.44335937499999,
              34.37631232462591
            ],
            [
              74.40078735351562,
              34.31621838080741
            ],
            [
              74.3341827392578,
              34.30090455174585
            ],
            [
              74.16595458984375,
              34.338900400404995
            ],
            [
              74.13505554199219,
              34.30600947175969
            ],
            [
              74.09385681152344,
              34.30544227374884
            ],
            [
              74.05334472656249,
              34.34003434042203
            ],
            [
              74.00871276855469,
              34.34740457681595
            ],
            [
              73.91189575195312,
              34.34740457681595
            ],
            [
              73.91962051391602,
              34.34244391205242
            ],
            [
              73.92768859863281,
              34.326993104644515
            ],
            [
              73.93592834472656,
              34.31224839701962
            ],
            [
              73.94073486328125,
              34.30218081084058
            ],
            [
              73.96202087402344,
              34.279914398549934
            ],
            [
              73.9764404296875,
              34.26459395075398
            ],
            [
              73.98073196411133,
              34.245581366929976
            ],
            [
              73.98210525512695,
              34.2389117564072
            ],
            [
              73.98141860961914,
              34.23479620118046
            ],
            [
              73.97918701171875,
              34.22713221745561
            ],
            [
              73.9767837524414,
              34.21293781333383
            ],
            [
              73.90365600585938,
              34.121894910007406
            ],
            [
              73.90571594238281,
              34.106687859570926
            ],
            [
              73.90399932861328,
              34.10384511696172
            ],
            [
              73.9046859741211,
              34.10071798977707
            ],
            [
              73.9046859741211,
              34.09787504660015
            ],
            [
              73.89301300048828,
              34.07086232376631
            ],
            [
              73.88751983642578,
              34.046685742159525
            ],
            [
              73.89816284179688,
              34.03274552068691
            ],
            [
              73.9215087890625,
              34.01311152864026
            ],
            [
              73.9445114135742,
              34.01339611168494
            ],
            [
              73.97369384765625,
              34.012542359689206
            ],
            [
              73.99085998535155,
              34.01538816628851
            ],
            [
              74.00596618652344,
              34.02221771291966
            ],
            [
              74.01420593261717,
              34.035590649919314
            ],
            [
              74.0866470336914,
              34.037582183607775
            ],
            [
              74.12355422973633,
              34.05493499798558
            ],
            [
              74.16080474853516,
              34.039289175269076
            ],
            [
              74.21436309814453,
              34.03872018186559
            ],
            [
              74.2239761352539,
              34.031891963311
            ],
            [
              74.3001937866211,
              34.03146518140282
            ],
            [
              74.46258544921875,
              33.998311895944404
            ],
            [
              74.53674316406249,
              34.01965669732604
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "shopian",
        "title": "Shopian",
        "district": "Shopian",
        "details": "Shopian, nestled amidst the Pir Panjal range in southern Kashmir, beckons travelers with its captivating blend of historical significance, breathtaking landscapes, and vibrant culture. Often called the Apple Town of Kashmir due to its abundance of orchards, Shopian offers a delightful experience for history buffs, nature enthusiasts, and those seeking a glimpse into local life. Shopian Town: This ancient town, with its winding lanes and traditional houses, reflects a rich past. Explore the historic Jamia Mosque and the nearby ruins of ancient structures, whispering tales of bygone eras. Shopian Wildlife Sanctuary: Home to diverse flora and fauna, this sanctuary offers an opportunity to spot wildlife like leopard, musk deer, and various bird species."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.71698760986328,
              33.610330500723556
            ],
            [
              74.77603912353517,
              33.65463772160771
            ],
            [
              74.81723785400389,
              33.693209305576026
            ],
            [
              74.88040924072266,
              33.7140593242241
            ],
            [
              74.90100860595705,
              33.69806524140501
            ],
            [
              74.96143341064455,
              33.70948989018833
            ],
            [
              74.99301910400392,
              33.71977077483141
            ],
            [
              75.03696441650392,
              33.7243396617476
            ],
            [
              75.05893707275392,
              33.7243396617476
            ],
            [
              75.07198333740236,
              33.680354033245564
            ],
            [
              75.17223358154298,
              33.66892551059118
            ],
            [
              75.22441864013673,
              33.63234403356961
            ],
            [
              75.27866363525392,
              33.52536850360117
            ],
            [
              75.2395248413086,
              33.53137866375304
            ],
            [
              75.22441864013673,
              33.54196697708567
            ],
            [
              75.22167205810548,
              33.568861182555565
            ],
            [
              75.1948928833008,
              33.53509902973769
            ],
            [
              75.21549224853516,
              33.46925353734004
            ],
            [
              75.14682769775392,
              33.489871424805116
            ],
            [
              75.135498046875,
              33.45478945269823
            ],
            [
              75.06580352783205,
              33.45722417051078
            ],
            [
              75.0204849243164,
              33.463525475613785
            ],
            [
              74.99095916748048,
              33.459515607176485
            ],
            [
              74.9658966064453,
              33.44533637094363
            ],
            [
              74.80899810791017,
              33.408516828002675
            ],
            [
              74.8048782348633,
              33.39934533042092
            ],
            [
              74.75269317626955,
              33.507621784243526
            ],
            [
              74.7279739379883,
              33.50418651485953
            ],
            [
              74.69501495361328,
              33.58773934387021
            ],
            [
              74.71698760986328,
              33.610330500723556
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "srinagar",
        "title": "Srinagar",
        "district": "Srinagar",
        "details": "Srinagar, located in the union territory of Jammu and Kashmir, India, is a popular tourist destination known for its breathtaking natural beauty and cultural richness. It is situated in the centre of the Kashmir Valley on the banks of the Jhelum River and is surrounded by four districts and Ladakh UT.The city is known for its natural environment, various gardens, waterfronts and houseboats. It is also known for traditional Kashmiri handicrafts like the Kashmir shawl (made of pashmina and cashmere wool), papier-mache, wood carving, carpet weaving, and jewel making, as well as for dried fruits.Famously referred to as “Heaven on Earth ”, Srinagar is known for the static houseboats and gondola-type boats- Shikaras in Dal Lake. Decorated with serene Dal Lake & Nigeen Lake."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.62909698486328,
              34.03786668460356
            ],
            [
              74.68643188476562,
              34.025347738147936
            ],
            [
              74.70977783203125,
              34.023071367612125
            ],
            [
              74.73072052001953,
              34.03473712116845
            ],
            [
              74.75372314453125,
              34.023071367612125
            ],
            [
              74.79766845703125,
              34.025347738147936
            ],
            [
              74.83612060546875,
              34.03103839734782
            ],
            [
              74.86358642578125,
              34.01738017414994
            ],
            [
              74.88418579101562,
              34.0094118622566
            ],
            [
              74.90753173828125,
              34.025347738147936
            ],
            [
              74.93362426757812,
              34.05834824836519
            ],
            [
              74.93654251098633,
              34.063894487079125
            ],
            [
              74.94186401367186,
              34.06929816540965
            ],
            [
              74.9761962890625,
              34.07199987534163
            ],
            [
              75.00640869140625,
              34.07199987534163
            ],
            [
              75.04623413085938,
              34.07199987534163
            ],
            [
              75.07507324218749,
              34.07427493266743
            ],
            [
              75.12167930603027,
              34.09602708232169
            ],
            [
              75.11738777160645,
              34.11507339376369
            ],
            [
              75.0967025756836,
              34.20271636159618
            ],
            [
              74.92813110351562,
              34.20158056821986
            ],
            [
              74.81208801269531,
              34.18510984477345
            ],
            [
              74.69673156738281,
              34.20725938207231
            ],
            [
              74.61227416992188,
              34.18056563083384
            ],
            [
              74.58686828613281,
              34.129994745824746
            ],
            [
              74.59442138671875,
              34.09702214502393
            ],
            [
              74.61090087890625,
              34.06517433677496
            ],
            [
              74.62909698486328,
              34.03786668460356
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "budgam",
        "title": "budgam",
        "district": "Budgam",
        "details": "Nestled in central Kashmir, Budgam is a haven for nature enthusiasts, boasting scenic beauty historic sites and lush agricultural landscapes. Located approximately 18 km from Srinagar the district experiences chilly winters with temperatures as low as -4°C and mild summers reaching up to 30°C. Budgam is renowned for its lush meadows and vibrant flora, creating a picturesque setting. Yusmarg, a meadow surrounded by dense forests, offers an enchanting escape, while theserene Doodhpathri provides a tranquil retreat. The ancient Jamia Masjid, with its architectural charm, stands as a testament to the rich cultural history of the region. Budgam promises a serene getaway, inviting visitors to explore the beauty of nature and the allure of historical sites."
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.88075256347656,
              33.71434490577805
            ],
            [
              74.9102783203125,
              33.770015152780125
            ],
            [
              74.91851806640624,
              33.823652757842055
            ],
            [
              74.94049072265625,
              33.865854454071865
            ],
            [
              74.92675781249999,
              33.89435731090067
            ],
            [
              74.86907958984375,
              33.9285481685662
            ],
            [
              74.8004150390625,
              33.9672812214173
            ],
            [
              74.79766845703125,
              34.025347738147936
            ],
            [
              74.77569580078125,
              34.024209560512354
            ],
            [
              74.75337982177734,
              34.02335591726823
            ],
            [
              74.73106384277344,
              34.03473712116845
            ],
            [
              74.70977783203125,
              34.023071367612125
            ],
            [
              74.68643188476562,
              34.025347738147936
            ],
            [
              74.62875366210938,
              34.03786668460356
            ],
            [
              74.59167480468749,
              34.02762404762424
            ],
            [
              74.53691482543945,
              34.01965669732604
            ],
            [
              74.46258544921875,
              33.99802726234877
            ],
            [
              74.55854415893555,
              33.88295731069693
            ],
            [
              74.564208984375,
              33.80767911381059
            ],
            [
              74.62875366210938,
              33.75003511886674
            ],
            [
              74.67681884765624,
              33.64663552343716
            ],
            [
              74.71664428710938,
              33.610044573695646
            ],
            [
              74.77569580078125,
              33.654066160697056
            ],
            [
              74.81689453125,
              33.69349495645655
            ],
            [
              74.88075256347656,
              33.71434490577805
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {
        "id": "pulwama",
        "title": "Pulwama",
        "district": "Pulwama",
        "details": "Pulwama District, located in the union territory of Jammu and Kashmir, India. Famous for its saffron fields, Pulwama is an agricultural hub and a picturesque district in the Kashmir Valley.Pulwama district unfolds its splendor as a land of captivating beauty and bountiful resources. It's known for its fertile valleys, snow-capped mountains, serene lakes, and rich cultural heritage, offering a delightful experience for travelers seeking diverse experiences.Pulwama's crown jewel is the Pahalgam Valley, renowned for its breathtaking scenery, apple orchards, and gurgling streams. The Aru Valley, with its meadows and glaciers, and the Dachigam National Park, teeming with wildlife, offer unparalleled natural beauty. "
      },
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              74.79775428771973,
              34.025347738147936
            ],
            [
              74.8359489440918,
              34.03103839734782
            ],
            [
              74.86358642578125,
              34.017309031816914
            ],
            [
              74.88435745239258,
              34.00948301120727
            ],
            [
              74.9077033996582,
              34.025490009279075
            ],
            [
              74.93362426757812,
              34.05834824836519
            ],
            [
              74.93637084960938,
              34.063894487079125
            ],
            [
              74.9417781829834,
              34.06929816540965
            ],
            [
              74.97602462768555,
              34.07214206821454
            ],
            [
              75.02099990844727,
              34.07207097180793
            ],
            [
              75.04606246948242,
              34.07207097180793
            ],
            [
              75.07498741149902,
              34.07427493266743
            ],
            [
              75.12167930603027,
              34.09602708232169
            ],
            [
              75.16287803649902,
              34.03203422346999
            ],
            [
              75.3116226196289,
              33.97753113740941
            ],
            [
              75.333251953125,
              33.95218881907283
            ],
            [
              75.3274154663086,
              33.91202429999497
            ],
            [
              75.201416015625,
              33.82507883099226
            ],
            [
              75.18321990966797,
              33.79883527825399
            ],
            [
              75.05893707275389,
              33.72462520910535
            ],
            [
              75.03662109375,
              33.72462520910535
            ],
            [
              74.99336242675781,
              33.72005633738808
            ],
            [
              74.96074676513672,
              33.709775486938895
            ],
            [
              74.90100860595703,
              33.69892214276973
            ],
            [
              74.88040924072266,
              33.71434490577805
            ],
            [
              74.9102783203125,
              33.77030054809329
            ],
            [
              74.91748809814453,
              33.823082321922676
            ],
            [
              74.94014739990234,
              33.865854454071865
            ],
            [
              74.92692947387695,
              33.894214820301436
            ],
            [
              74.8004150390625,
              33.9672812214173
            ],
            [
              74.79775428771973,
              34.025347738147936
            ]
          ]
        ]
      }
    }
  ]
}