import React from "react";
import './header.css';

function App() {
  return (
    <div>
        {/* // <!>/-- Topbar Start --> */}
            <div class="container-fluid bg-light pt-3 ">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 text-center text-lg-left mb-2 mb-lg-0">
                        <div class="d-inline-flex align-items-center">
                            <p  className="mb-0"><i class="fa fa-envelope mr-2"></i>info@jktdc.co.in</p>
                            <p class="text-body px-3 d-none d-lg-inline">|</p>
                            <p  className="mb-0"><i class="fa fa-phone-alt mr-2"></i> 0194-2502274</p>
                        
                        </div>
                    </div>
                     <div class="col-lg-6 text-center text-lg-right"> 
                        <div class="d-inline-flex align-items-center">
                            <a class="text-primary px-3" href="https://www.facebook.com/jandktdc/">
                                <i class="fab fa-facebook-f mr-4"></i>
                            </a>
                            <a href="https://www.twitter.com" target="blank" aria-label="twitter" title="Twitter" class="Rv9f2H mr-4">
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none">
        <path fill="currentColor" d="m.036 0 5.782 7.73L0 14.017h1.31l5.093-5.503 4.116 5.503h4.456L8.868 5.851 14.284 0h-1.31L8.283 5.068 4.493 0H.036Zm1.926.965H4.01l9.04 12.087h-2.047L1.962.965Z"></path>
    </svg>
</a>
                         {/* <a class="text-primary px-3" href="https://in.linkedin.com/company/jammu-and-kashmir-tourism-development-corporation-limited"> 
                                <i class="fab fa-linkedin-in"></i>
                            </a>  */}
                            {/* <a class="text-primary px-3" href="https://www.instagram.com/jktdcofficial/">
                                <i class="fab fa-instagram"></i>
                            </a> */}
                            <a class="text-primary pl-3" href="###">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* // <!-- Topbar End --> */}
     </div>
     );
    }
    
    export default App;