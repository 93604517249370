// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/padri-pass.jpg";
import baderwah2 from "../../img/padri-pass2.jpg";
import religiousImage1 from "../../img/guptganga.png";
import religiousImage2 from "../../img/chandi-mata.jpg";
import historicalImage1 from "../../img/lalpora.jpg";
import FoodsImage3 from "../../img/Rajma_Chawal.jpg";
import FoodsImage4 from "../../img/kabab.jpg";


const padRi = {
    name: "Padri Pass",
    
    description: "Padri Pass",
    linkMap:"https://www.google.com/maps?q=Padri Pass, Forest Block 184206",
    description1: "Padri Pass lies at the intersection of the union territory of Jammu & Kashmir and the state of Himachal Pradesh, and the Bhal Padri meadows are just 7 kilometers away from this road head. Although Padri to Bhal Padri is a well known trail among the locals of Jammu, it remains quite a secret to the rest of the world",
    p1: "Padri Top or Padri Pass is a hill station situated 41 kilometres North of Bhaderwah on Bhaderwah Chamba National Highway. It has 99.7 kilometres long meadows and used for adventure sports like snow-skiing in winters and Paragliding in summers. ",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Situated at a distance of 40 kilometers from Bhaderwah, Padri is located at a height of 10,500 feet above sea-level.",
    about1: "The landscape runs in kilometers and is ideal for adventure sports like Snow-skiing in winters and Paragliding in summers. One feels extremely delighted reaching there.  ",
    about2: "It is also highest Pass on Bhadarwah Chamba National Highway (The inter-state link). The visitor must carry woolens to enjoy the beauty of Padri even in summers. ",
    about3: "  This is one of the important Passes which connects Jammu and Kashmir with Himachal. In older times Bhaderwah was a part of the powerful Chamba Kingdom which held Kishtwar as well and even Zanskar. For many years Bhaderwah was the bone of contention between the Jammu Kingdom and the Chamba Kingdom until in the mid 1800’s it was taken over by Raja Gulab Singh of Jammu as terms of settlement of the Territorial boundary with the Chamba Kingdom after he acquired Kashmir as per the Treaty of Amritsar. ",
    about4: " ",
    arrivalbycab1: "To arrange transportation to Padri Pass in Bhaderwah, you would typically follow these steps ",
    arrivalbycab2: "Find Local Transport Options: Look for local cab services or taxi stands in Bhaderwah. You can search online, ask your accommodation provider for recommendations, or inquire with locals.  ",
    arrivalbycab3: "Booking: If the cab service is available and suitable for your needs, proceed to book your cab. Provide them with details such as your name, pickup location, desired pickup time, and any other specific requirements you may have. ",
    arrivalbycab4: " The cab should pick you up from your designated location in Bhaderwah and transport you to Padri Pass",
    cimate0: "The climate in the area around Padri Pass in Bhaderwah, Jammu and Kashmir, is typically cold and temperate. However, as with any mountainous region, the climate can vary significantly depending on the season and elevation. ",
    cimate1: "Summer (May to August): During the summer months, the weather in Bhaderwah is relatively pleasant with temperatures ranging from mild to warm. Daytime temperatures can vary from around 15°C to 25°C (59°F to 77°F) in the lower areas. At higher elevations, temperatures may be cooler. This period is generally considered the best time to visit Padri Pass for trekking and outdoor activities due to milder weather conditions.  ",
    cimate2: " Monsoon (July to September): The region experiences some rainfall during the monsoon season, which typically starts in July and lasts through September. Rainfall can vary, but it's generally not as heavy as in other parts of India. However, precipitation can make trekking and outdoor activities more challenging during this time.",
    cimate3: "Autumn (September to November): After the monsoon season, autumn brings cooler temperatures and drier weather. The landscape can be particularly scenic during this time with the changing colors of the foliage. ",
    cimate4: " Winter (December to February): Winter in Bhaderwah is cold, with temperatures often dropping below freezing, especially at higher elevations such as Padri Pass. Snowfall is common during these months, making it difficult to access certain areas. Daytime temperatures can range from 0°C to 10°C (32°F to 50°F), while nighttime temperatures can drop significantly lower.",
    climate5:"Spring (March to April): Spring is a transitional period characterized by gradually rising temperatures and melting snow. It's a good time to visit if you prefer milder weather and want to avoid the extreme cold of winter.",
    headinghotels: "List Of Hotels",
    hotelsName: ["THE SAZ VALLEY RESORT ","Hotel vasuki international(hotel and restaurant) Bhaderwah", "Hotel Dreamland Bhaderwah","Hotel Piece Of Paradise Bhaderwah"],
    hotelsPhone: ["099060 74475","06006072953","07006916383","07006672707" ],
    hotelsViewLinks: ["NA", "https://www.booking.com/hotel/in/vasuki-international-bhaderwah.en-gb.html", "http://www.dreamlandhotelsbhaderwah.com/","https://pieceinparadise.business.site/?utm_source=gmb&utm_medium=referral"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=THE SAZ VALLEY RESORT, Bani Basholi Road Nalthi, Bhaderwah, Jammu and Kashmir 182222","https://www.google.com/maps?q=vasuki dehra, near vasuki nag temple, Bhaderwah, Jammu and Kashmir 182222","https://www.google.com/maps?q=College Link Rd, opposite New Bus Stand, Bhaderwah, Forest Block, Jammu and Kashmir 182222","https://www.google.com/maps?q=near SBI, Udrana, Bhaderwah, Jammu and Kashmir 182222"],
    headingfood: "Must try in Padri Pass",
    foodsName: ['Rajma Chawal', 'Kebabs'],
    foodsDescription: [ " This popular dish consists of red kidney beans cooked in a flavorful gravy of tomatoes, onions, and spices, served with steamed rice. It's a hearty and satisfying meal enjoyed by locals and visitors alike. "," Various types of kebabs, such as seekh kebabs (minced meat skewers) and tandoori kebabs (marinated meat cooked in a tandoor oven), are popular in the region and make for delicious appetizers or main courses."],
    religiousPlaces: ["Gupt Ganga Temple","Chandi Mata Temple"],
    religiousPlacesDescription: [" Situated in Bhaderwah town itself, Gupt Ganga Temple is a significant religious site dedicated to Goddess Bhadrakali. It's located near the holy Bhaderwah river and is considered a sacred spot by devotees.","This temple is located in the Chinta Valley near Bhaderwah. It's dedicated to Goddess Chandi, and devotees visit here to seek blessings. The temple offers picturesque views of the surrounding mountains."],
    historicalPlacesDescription: [""],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default padRi;