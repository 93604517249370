import sonamargBackground from "../../img/srinagar-background.jpg";
import image2 from "../../img/mac.jpg";
import image1  from "../../img/machail2.jpg";
import religiousImage1 from "../../img/amarnathcave.jfif";
import historicalImage1 from "../../img/zojila.jfif";
import historicalImage2 from "../../img/bringhiriver.jfif";
import gurazFoodsImage3 from "../../img/kehwa.jfif";
import gurazFoodsImage4 from "../../img/wazwan.jfif";
import gurazFoodsImage1 from "../../img/rogan-josh.jfif";
import gurazFoodsImage2 from "../../img/yakhni.jpg";


const ganderbalData = {
  name: "Machail",
  area: "Area 5 sq km",
  temp: "-2",
  population: "NA",
  // capital: "Sonamarg Town",
  language: "Kashmiri, Urdu, English",
  description: "Machail",
  linkMap:"https://www.google.com/maps?q=8Q95+W8V Machail Chandi Temple, Matta, Kishtwar, 182204",
  description1: "It is the beautiful, picturesque and splendid spot in Kishtwar district. It is about 30 kms from Gulabgarh at an elevation of 2958 meters above sea level. ",
  p1: "Machail, abode of Chandi Mata, is a Tourist destination. In the months of July- August every year Machail yatra with Chhari Mubarak( holy mace) starts from Jammu on 17th August via Bhaderwah and reaches to Machail on 22nd August. A big fair is held at Machail in front of the temple on 23rd August every year. Tourists and common people visit this place for worship, darshan and paying obeisance to the deity.",
 p2:"It is a place on the historical route of Dogra forces under Wazir Zorawar Singh who crossed Umasi La pass after conquering Zanskar ladakh. It offers adventure tourism like Trekking, Camping,  etc. It is  famous for religious tourism.",
  backgroundImage: `url(${sonamargBackground})`,
  images: [image1, image2],
  distanceFromSrinagar: "Machail lies within the Padder Valley, known for its picturesque meadows, snow-capped peaks, and gushing streams. The village itself is charming, with traditional houses lining narrow lanes and locals clad in vibrant attires. The fresh mountain air, the serenity of the surroundings, and the breathtaking views create a truly magical atmosphere.",
  about1: "The most common route from Jammu to Machail goes via Udhampur, Patnitop, Kud, Batote, and Kishtwar. This scenic route offers breathtaking views of the Pir Panjal Range, lush valleys, and charming villages. However, remember that the roads can be narrow, winding, and prone to landslides during monsoon season.",
  about2: "While the village offers a peaceful retreat, it also caters to adventurous souls. Trekking enthusiasts can embark on challenging trails through the surrounding mountains, offering breathtaking panoramas and encounters with diverse flora and fauna.",
  about3: "Machail offers a glimpse into the unique culture and traditions of the Doda district. The locals are known for their warm hospitality and are always willing to share their stories and customs. Tourists can witness traditional music and dance performances, savor delicious local cuisine, and delve into the rich heritage of the region.",
  // about4: "Photographer's Delight: Thajiwas Glacier offers a haven for photographers, with every frame capturing the ethereal beauty of the Himalayan landscape. The interplay of light and shadows, coupled with the glacier's pristine white, creates a visual spectacle.",
  arrivalbycab1: "Machail is nestled deep within the Himalayas, roughly 220 kilometers (137 miles) away from Jammu city. The travel time can vary depending on road conditions, weather, and traffic, but typically ranges from 8 to 12 hours.",
  arrivalbycab2: "Book your cab in advance, especially during peak season or holidays.",
  arrivalbycab3: "Consider hiring a local guide familiar with the area for a more enriching experience.",
  arrivalbycab4: "Be prepared for unpredictable weather conditions and pack accordingly.",
  cimate0: "Temperature: -2°C",
  // cimate1: "NA",
  // cimate2: "NA",
  // cimate3: "NA",
  // cimate4: "NA",
  headinghotels: "Accommodation Nearby",
  hotelsName: ["Snowber resorts", "Hotel Neel Gagan", "Hotel Glacier Heights", "Radisson Hotel Sonamarg","Country Inn & Suites"],
  hotelsPhone: ["+91-9149450023", "+91-9596176769", "+91-9419903357", "+91-88 99 005266","60067 50321"],
  hotelsViewLinks: ["https://www.makemytrip.com/hotels/snowber_resorts-details-sonmarg.html", "http://www.hotelneelgagan.in/", "https://www.hotelglacierheights.com/", "https://www.radissonhotels.com/en-us/hotels/radisson-sonamarg","https://www.radissonhotels.com/en-us/hotels/country-inn-sonamarg?cid=a%3Ase+b%3Agmb+c%3Aapac+i%3Alocal+e%3Acis+d%3Aind+h%3AINJKJAMSON"],
  hotelsMapsLinks: ["https://www.google.com/maps?q=Snowber+resorts+Sonamarg", "https://www.google.com/maps?q=Hotel Neel Gagan Sonamarg", "https://www.google.com/maps?q=Hotel Glacier Heights Sonamarg", "https://www.google.com/maps?q=Radisson Hotel Sonamarg","https://www.google.com/maps?q=Country Inn & Suites by Radisson Sonamarg"],
  headingfood: "Dining Options",
  foodsName: ['Kashmiri Wazwan', 'Kahwa', 'Rogan Josh', 'Yakhni'],
  foodsDescription: ["Indulge in the traditional Kashmiri Wazwan, a grand feast comprising a variety of flavorful dishes that showcase the rich culinary heritage of the region.", 'Sip on the aromatic Kahwa, a traditional Kashmiri tea infused with spices, offering warmth amidst the chilly surroundings.', 'Savor the iconic Rogan Josh, a delectable Kashmiri curry known for its rich flavors and tender meat.', 'Enjoy the comforting Yakhni, a traditional Kashmiri yogurt-based curry that complements the cold climate of the region.'],
  religiousPlaces: ["Stakna Sahib Gurudwara","Kishtwar Shiv Temple"],
  religiousPlacesDescription: ["A revered Sikh pilgrimage site nestled amidst scenic mountains","An ancient temple perched on a hilltop, offering panoramic views and rich historical significance."],
  historicalPlaces: ["Zoji La Pass"],
  historicalPlacesDescription: ["Explore the historical significance of Zoji La Pass, a high mountain pass that connects Kashmir Valley to Ladakh. The pass has witnessed historical events and is a vital route for trade and travel.", "Nichnai Pass, an ancient mountain pass along the trekking route to Thajiwas Glacier, offers a glimpse into the historical trade routes and the cultural exchange that occurred in the region."],
  foodsImages:[gurazFoodsImage4,gurazFoodsImage3,gurazFoodsImage1,gurazFoodsImage2,],
  imagesforreligious: [religiousImage1 ], 
  imagesforhistorical: [historicalImage1, historicalImage2],
  };
  
  export default ganderbalData;