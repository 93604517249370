// Map.js
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, GeoJSON, } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { districtData } from "../data";
import DistrictDetails from "../District/District";
import "../Map/Map.css";


const Map = () => {
  const mapStyle = {
    height: "554px",
    width: "100%",
    // backgroundColor: "transparent", 
    backgroundColor:"#7595b1",
    border: "1px solid black",
    borderRadius: "19px",
    position: "relative",
    marginTop: "10px",  // Adjust the top margin as needed
    marginBottom: "10px",  // Adjust the bottom margin as needed  
  };

  // const center = [33.7782, 76.5762]; // Jammu and Kashmir coordinates
  const center = [33.6, 74.9];
  

  const getDistrictColor = (feature) => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };


  const geoJSONStyle = {
    // color: 'red', // Line color for GeoJSON features
    // background:'red',
    weight: 2, // Line weight
    opacity: 0.7, // Line opacity
    // fillColor: '#0066cc', // Fill color for GeoJSON features
    fillOpacity: 0.4, // Fill opacity
  };

  // Find the default district feature by its name, for example, 'Srinagar'
  const defaultDistrictFeature = districtData.features.find(
    (feature) => feature.properties.district === "Srinagar"
  );

  const [hoveredFeature, setHoveredFeature] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(
    defaultDistrictFeature
  );

  const handleDistrictClick = (feature) => {
    setSelectedFeature(feature);
  };

  const [weather, setWeather] = useState(null);
  const getColor = (feature) => {
    switch (feature.properties.id)  {
      case "Poonch":
        return "green";
      case "Kupwara":
        return "blue";
      //   case "Srinagar":
      //     return "blue"
      //     case "Kishtwar":
      //   return "green";
      // case "Anantnag":
      //   return "blue";
      //   case "Baramulla":
      //     return "blue"
      //     case "Ganderbal":
      //   return "green";
      // case "Udampur":
      //   return "blue";
      //   case "Kathua":
      //     return "blue"
      //     case "Samba":
      //   return "green";
      // case "Jammu":
      //   return "blue";
      //   case "Reasi":
      //     return "blue"
      //     case "Shopian":
      //   return "green";
      // case "Doda":
      //   return "blue";
      //   case "Budgam":
      //     return "blue"
      //     case "Pulwama":
      //     return "green";
      //   case "Kupwara":
      //     return "blue";
      //     case "Srinagar":
      //       return "blue"
      // Add more cases for each district
      // case "YourNextDistrict":
      //   return "yourColor";
      default:
        // Default color for districts not explicitly handled
        return "red";
    }
  };



  useEffect(() => {
    // Fetch weather data when the selected district changes
    if (selectedFeature) {
      const apiKey = "c0b29958f988088a72cdf6fff07f99cc";
      const districtName = selectedFeature.properties.district;
      const apiUrl = `http://api.weatherstack.com/current?access_key=${apiKey}&query=${districtName}`;


      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          // Update the weather state with the retrieved data
          setWeather(data);
        })
        .catch((error) => {
          console.error("Error fetching weather data:", error);
        });
    }
  }, [selectedFeature]); // Trigger the effect when the selected district changes


  return (
    <div>
    <div className="backgroundImg">
      <div className="container-fluid ">
        <div className="container pt-5 pb-3 ">
      <h1 class="text-center pb-3">JAMMU AND KASHMIR-OVERVIEW</h1>
          <div className="row">
            <div className="col-lg-5 col-md-6 mb-4 card border p-5">
              <DistrictDetails
                districtData={districtData}
                selectedFeature={selectedFeature}
                weather={weather}
                onClose={() => setSelectedFeature(null)}
              />
            </div>
            <div className="col-lg-7 col-md-6 mb-4">
               

              <MapContainer
                center={center}
                zoom={8}
                style={{ ...mapStyle, marginBottom: "0px" }}
                // style={mapStyle}
                scrollWheelZoom={false}
                dragging={false}
                
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                 // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <GeoJSON
        data={districtData}
        style={(feature) => ({
          color: feature === selectedFeature ? "brown" : "white",
          fillColor: hoveredFeature === feature ? getColor(feature) : getDistrictColor(feature),
          fillOpacity: feature === selectedFeature ? 0.6 : 0,
          weight: 2,
          ...geoJSONStyle,
        })}
        onEachFeature={(feature, layer) => {
          layer.on({
            mouseover: () => {
              layer
                .bindPopup(feature.properties.district)
                .openPopup();
              setHoveredFeature(feature);
            },
            mouseout: () => {
              layer.closePopup();
              setHoveredFeature(null);
            },
            click: () => handleDistrictClick(feature),
          });
        }}
      />
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Map;
