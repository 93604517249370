import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/laldraman.jpg";
import baderwah2 from "../../img/laldraman2.jpg";
import religiousImage1 from "../../img/guptatample.png";
import religiousImage2 from "../../img/Padri.png";
import historicalImage1 from "../../img/JaiValley.jpg";
import historicalImage2 from "../../img/chintaValley.jpg";
import gurazBandiporahistorical3 from "../../img/sheikhporaguraz.jpg";
import FoodsImage1 from "../../img/sindi-doda-barfi.jpg";
import FoodsImage2 from "../../img/kaladi.jpg";



const lalDramanData = {
    name: "Lal Draman",
          
          description: "Lal Draman ",
          linkMap:"https://www.google.com/maps?q=Lal Draman, 4GWX+76V, Thalanga, Doda, Jammu and Kashmir 182202",
          description1: "It is a beautiful spot 16 km North-West of Doda town. Amidst lofty and fir trees near village Sazan. It has a lot of tourism significance and offers adventure tourism.",
          p1: "Lal Draman is a picturesque hill station nestled in the Doda district of Jammu and Kashmir, India. It's a place where verdant meadows meet lofty mountains, offering breathtaking scenery and a refreshing escape from the urban bustle.",
          p2: "Spring paints the meadows in vibrant hues, making it a haven for nature lovers. Summer offers pleasant weather, perfect for picnics, camping, and leisurely strolls. Monsoon transforms the landscape into a lush paradise, while winter cloaks it in a serene blanket of snow, ideal for winter sports enthusiasts.",
        //   p3: "",    
          backgroundImage: `url(${gurazBandiporaBackground})`,
          images: [baderwah1, baderwah2], // Add images array
          distanceFromSrinagar:"Baderwah is situated approximately 200 kilometres from Srinagar, the summer capital of Jammu and Kashmir. The journey from Srinagar to Baderwah takes around 6-7 hours by road, providing travellers with a scenic drive through picturesque landscapes and charming villages.",
          about1: "The name Lal Draman translates to Red Meadow in the local language. Its apt name arises from the vibrant wildflowers that paint the meadows in shades of red during the spring season. This scenic haven is perched at an altitude of 2,700 meters (8,858 feet), approximately 23 kilometers (14 miles) north of Doda town.",
          about2: "Lal Draman boasts captivating landscapes that allure nature enthusiasts and photographers alike. Imagine sprawling meadows blanketed in wildflowers, majestic deodar and fir trees whispering in the breeze, and snow-capped peaks painting the horizon. This mesmerizing scenery changes its character with the seasons, offering distinct charm throughout the year.",
          about3: "",
          about4: "",
          arrivalbycab1: "The distance between Jammu and Lal Draman is approximately 200 kilometers (124 miles). The travel time can vary depending on road conditions, traffic, and weather, but typically ranges from 5 to 7 hours.  ",
          arrivalbycab2: "The most common route from Jammu to Lal Draman goes via Udhampur, Patnitop, Kud, and Batote. This route offers stunning views of the Himalayas, especially as you climb towards Patnitop, a popular hill station.           ",
          arrivalbycab3: "Book your cab in advance, especially during peak season.",
          arrivalbycab4: "Take breaks along the way to enjoy the scenery and stretch your legs.",
          cimate0: "Spring (March to May): Pleasant weather with temperatures ranging from 10°C to 22°C. This is the best time to witness the vibrant wildflower blooms in the meadows.",
          cimate1: "Summer (June to August): Warmer weather with temperatures between 15°C and 28°C. Ideal for outdoor activities like trekking, camping, and enjoying the scenic beauty.",
          cimate2: "Monsoon (September to November): Moderate rainfall occurs, adding to the lushness of the landscape. Temperatures typically range from 10°C to 20°C. Be prepared for occasional showers and slippery paths.",
          cimate3: "Winter (December to February): The coldest season with temperatures dipping below freezing, often reaching -5°C or lower. Snowfall is common, transforming the landscape into a winter wonderland. This season caters to winter sports enthusiasts.",
          // cimate4: "Winter (December to February): Cold temperatures with snowfall, transforming Baderwah into a winter wonderland. Temperatures can drop to freezing levels.",
        //   cimate5: "Spring in Gurez Bandipora is a delightful season with mild temperatures, ranging from a comfortable 10°C to 20°C. The blooming flora adds a burst of colors, creating a picturesque backdrop for outdoor activities.",
        //   cimate6: "Gurez Bandipora experiences minimal rainfall during the monsoon season, primarily from June to October. The average rainfall of 80.5 cm ensures lush greenery, enhancing the scenic beauty of the region.",
          headinghotels:"List Of Hotels",
          hotelsName:["Tamara Resort","Hotel Piece of Paradise Bhaderwah","The SAZ Valley Resort","Hotel Apple Tree Restaurant & Café","Hotel Dreamland Bhaderwah"],
          hotelsPhone:["+91-60055 20530","+91-70066 72707","+91-099060 74475","+91-90183 71077","+91-70069 16383"],
          hotelsViewLinks:["https://www.makemytrip.com/hotels/hotel-details/?hotelId=202308040828417096&Campaign=20803522043&_uCurrency=INR&au=&checkin=01262024&checkout=01272024&city=CTDOD&cmp=googlehoteldfinder_DH_META_Paid_default_IN_localuniversal_202308040828417096&country=IN&gclid=CjwKCAiAzJOtBhALEiwAtwj8tuRtC_wxPJ9VbITHI42GSPb6AllcPJ2qEr1QRYBPdBEtNtl24y2ePhoCRoQQAvD_BwE&lat=32.94714&lng=75.71762&locusId=CTDOD&locusType=city&mtkeys=dad96bb9-6377-4889-8c88-a1f67e5cdcc1&rank=1&roomCount=1&roomStayQualifier=2e0e&topHtlId=202308040828417096&totalGuestCount=2","https://pieceinparadise.business.site/?utm_source=gmb&utm_medium=referral","https://mytravaly.com/hotels/hotel-details/?hotelid=HqohaBbG&check_in=2024-01-26&check_out=2024-01-27&no_of_days=1&no_of_rooms=1&adults=2&children=0&bookingSource={%22source%22:%22google%22,%22cin%22:%222024-01-26%22,%22cout%22:%222024-01-27%22,%22packageId%22:%229726e72585da850e7626cc2803d8b0cc%22,%22userDevice%22:%22desktop%22,%22userLanguage%22:%22en%22,%22filter%22:{%22adults%22:%222%22,%22children%22:%220%22}}&adType=1&booking_source=cpc&rid=1&clickType=OTA&packageId=9726e72585da850e7626cc2803d8b0cc","https://hotelappletree.in/","http://www.dreamlandhotelsbhaderwah.com/"],
          hotelsMapsLinks:["https://www.google.com/maps/dir//WPW9%2BV28,+Forest+Block,+Bhaderwah,+Jammu+and+Kashmir+182222/@32.9471605,75.6351613,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x391cffb2ec1391e1:0xbcf7d82c5a927eef!2m2!1d75.7175625!2d32.9471875?entry=ttu","https://www.google.com/maps/dir/34.0738279,74.7922199/hotel+piece+of+paradise+bhaderwah/@33.5317352,74.5845359,9z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x391dab428556ea97:0xefa0de0f7c0af9fe!2m2!1d75.7083817!2d32.993044?entry=ttu","https://www.google.com/maps/dir//Bani+Basholi+Road+Nalthi,+Bhaderwah,+Jammu+and+Kashmir+182222/@32.9309396,75.6342564,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x391cff6552e7dc0d:0x950e048b0574f030!2m2!1d75.7166576!2d32.9309666?entry=ttu","https://www.google.com/maps/dir//Jai+road,+near+Gupt+Ganga,+Bhaderwah,+Jammu+and+Kashmir+182222/@32.9802773,75.6390462,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x391d0189efb53605:0xb27600adadd5e965!2m2!1d75.7214474!2d32.9803044?entry=ttu","https://www.google.com/maps?hl=en-GB&gl=ZZ&um=1&ie=UTF-8&fb=1&sa=X&geocode=KRFerfFfqx05Ma7b6weknAnX&daddr=Hotel+Dreamland+Bhaderwah,+College+Link+Rd,+opposite+New+Bus+Stand,+Bhaderwah,+Forest+Block,+Jammu+and+Kashmir+182222"],
          headingfood: "Must try in Gurez",
          foodsName:['Sindhi Doda Roti','Rawa Kaladya'],
          foodsDescription:[" Sindhi Doda Roti, a traditional and flavorful flatbread originating from the Sindhi community. It's made with a unique combination of millet flour, spices, and vegetables, resulting in a thick, crispy exterior and a soft, chewy interior. ","Rawa is special wheat flour different from the normal one as it is gritty and coarse. Right from the delicious sooji ka halwa to a nutritious Upma, "],
          religiousPlaces: ["Gupt Ganga Temple.", "Padri."],
          religiousPlacesDescription: ["Gupt Ganga Temple, a significant ancient shrine in Bhaderwah, is dedicated to Lord Shiva and is believed to mark the spot where the Pandavas stayed during their exile. The temple, positioned on the Neru River's bank, features a dome made entirely of circular stone slabs. Legend has it that River Ganga once fell on the Shiv lingam at the temple, hence named Gupt Ganga, as it mysteriously disappeared afterward. One of the temple's rocks is said to bear the footprint of Bhim, the second Pandava brother.", "A mesmerizing meadow surrounded by dense forests, perfect for camping and picnics.Padri, situated 41 kilometers from Bhaderwah along the Bhaderwah-Chamba National Highway, boasts breathtaking landscapes that captivate visitors. This picturesque destination is not only an ideal spot for enjoying adventure sports like snow-skiing in winters and paragliding in summers but also provides a delightful experience for all who visit. The scenic beauty and diverse recreational opportunities make Padri a must-visit location for nature enthusiasts and adventure seekers alike."],
          historicalPlaces: ["Jai Valley","Chinta Valley"],
          historicalPlacesDescription: ["Known for its carpet of wildflowers during the summer season, Jai Valley is a popular trekking destination with breath-taking views of the surrounding mountains. Jai Valley, located 32 kilometers northeast of Bhaderwah town in Jammu and Kashmir, India, is an enchanting destination known for its green meadows in summers and snow-covered landscapes in winters. Stretching over 9 kilometers, the valley is bisected by the Jai Nallah stream, merging with a tributary of the Chenab River. Surrounded by corniferous deodar forests, the valley attracts thousands of tourists, offering activities like rock-climbing, trekking, and winter sports such as skiing and ice skating. ","Chinta Valley village located 20 kilometres (12 mi) northeast of Bhaderwah town on Bhaderwah-Jai road. It is covered with thick coniferous forests from all sides with a stream flowing through it known as Chinta Nallah.Chinta is one of the tourist destinations in Bhaderwah, Jammu and Kashmir. Chinta and Jai valleys have the favourable climate and environment for basics and training in paragliding. "],
          foodsImages:[FoodsImage1,FoodsImage2,],
          imagesforreligious: [religiousImage1, religiousImage2 ], // Add images array for religious
          imagesforhistorical: [historicalImage1, historicalImage2,gurazBandiporahistorical3], // Add images array for historical
  };
  
  export default lalDramanData;