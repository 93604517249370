// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwahFortImage1 from "../../img/baderwah_fort.jpg";
import baderwahFortImage2 from "../../img/baderwahfort2.jpg";
import religiousImage1 from "../../img/guptganga.png";
import historicalImage1 from "../../img/Bhaderwah_in_Snow.jpg";
import FoodsImage3 from "../../img/rajmabaderwah.jpg";
import FoodsImage4 from "../../img/mutton-roganjosh.webp";


const baderwahFort = {
    name: "Baderwah Fort",
    description: "Baderwah Fort",
    linkMap:"https://www.google.com/maps?q=Qila Bhadrwah, XPF6+WRX, 182222, Qila Top, Bhaderwah, Jammu and Kashmir 182222",
    description1: "Baderwah Fort is a historical fortress located in the picturesque town of Baderwah in the Doda district of Jammu and Kashmir, India. Situated amidst breathtaking natural beauty, the fort offers visitors a glimpse into the region's rich cultural heritage and architectural legacy.",
    images: [baderwahFortImage1, baderwahFortImage2],
    p1: "Nestled amidst the picturesque landscapes of Bhaderwah Valley, the Bhaderwah Fort, also  known as Ratangarh Fort, stands as a majestic emblem of historical and cultural significance. Perched on a small flat-topped hillock, the fort offers a breathtaking panorama of the surrounding pine trees and the sprawling Bhaderwah Valley. With a history dating back to its construction in 1733 by Raja Methni Pal, this fort has weathered the sands of time,  witnessing changes of ownership, historic confrontations, and even serving as a bastion of  freedom during the tumultuous years of India&#39;s struggle for independence.",
    
    distanceFromSrinagar: "The distance from Jammu to Baderwah Fort is approximately 165 kilometers (about 102 miles) by road. The journey typically takes around 4 to 5 hours, depending on traffic and road conditions. It's important to note that travel times may vary based on the specific route taken and any stops along the way.",
    about1: "Baderwah Fort, also known as Baderwah Qila, is an ancient fortress that dates back to the medieval period. The fort holds historical significance as a strategic stronghold and served as a bastion of defense for the rulers of the region.",
    about2: "Location: Baderwah Fort is situated in the town of Baderwah, which is located in the foothills of the Himalayas in the Doda district of Jammu and Kashmir, India.",
    about3: "Architectural Marvel: The architecture of Baderwah Fort reflects a blend of Mughal and Kashmiri styles, characterized by sturdy stone walls, intricate carvings, and imposing gates. The fort offers panoramic views of the surrounding landscape, including the lush green valleys and snow-capped mountains.",
    about4: "Tourist Attraction: Baderwah Fort is a popular tourist attraction in the region, drawing history enthusiasts, architecture lovers, and nature enthusiasts alike. Visitors can explore the fort's inner chambers, courtyards, and ramparts while soaking in the beauty of its surroundings.",
    arrivalbycab1: " The distance from Srinagar to Baderwah Fort is approximately 190 kilometers (about 118 miles) by road. The journey by car or taxi typically takes around 5 to 6 hours, depending on traffic and road conditions. Similarly, travel times may vary based on the specific route taken and any stops along the way.",
    arrivalbycab2: "Private Cab: You can hire a private cab or taxi from Srinagar to Baderwah, either through local travel agencies, tour operators, or online platforms. Pre-booking your cab ensures a comfortable and hassle-free journey.",
    arrivalbycab3: "Shared Cabs: Alternatively, you can inquire about shared cab services or local transportation options from Srinagar to Baderwah. Shared cabs are available at designated taxi stands or transportation hubs in the city.",
    arrivalbycab4: "It's advisable to plan your journey in advance and check the weather and road conditions, especially during the winter months when snowfall may affect travel.",
    cimate0: "The climate in Baderwah is characterized by four distinct seasons: summer, monsoon, autumn, and winter.",
    cimate1: "Summer (June to August): Summers in Baderwah are mild and pleasant, with daytime temperatures ranging from 15°C to 25°C (59°F to 77°F). The weather is ideal for exploring outdoor attractions such as Baderwah Fort and enjoying nature walks in the surrounding valleys.",
    cimate2: "Monsoon (July to September): The monsoon season brings moderate to heavy rainfall to Baderwah, rejuvenating the lush green landscapes and adding to the region's natural beauty. While outdoor activities may be limited during heavy rainfall, the monsoon season offers a unique opportunity to witness the region's biodiversity.",
    cimate3: "Autumn (September to November): Autumn is a beautiful time to visit Baderwah, as the valleys are adorned with vibrant hues of red, orange, and gold. The weather is cool and pleasant, with daytime temperatures ranging from 10°C to 20°C (50°F to 68°F). It's an excellent time for photography and sightseeing.",
    cimate4: "Winter (December to February): Winters in Baderwah are cold and snowy, with temperatures dropping below freezing point. The region receives heavy snowfall during these months, transforming it into a winter wonderland. Winter sports enthusiasts can enjoy activities such as skiing and snowboarding in the nearby slopes.",
    cimate5: "Spring (March to May): Spring is a delightful season in Baderwah, as the landscapes come alive with the blooming of wildflowers and blossoming trees. The weather gradually warms up, with daytime temperatures ranging from 5°C to 20°C (41°F to 68°F). It's an ideal time for nature walks and picnics in the countryside.",
    headinghotels: "List Of Hotels",
    hotelsName: ["Manni Guest House ","Hotel Ibrahim","Hotel Shangrila","The Royal Skyline"],
    hotelsPhone: ["+91-9419331100","094199 90061", "094192 49286","+91-7006196161"],
    hotelsViewLinks: ["https://manni-guest-house.business.site/", "https://www.google.com/maps?q=Hotel Ibrahim, University Road, Baderwah, Jammu and Kashmir 182204","https://www.google.com/maps?q=Hotel Shangrila, Near Mini Market, Baderwah, Jammu and Kashmir 182204","https://www.google.com/maps?q=The Royal Skyline, Opp. DC Office, Gulabgarh Rd, Baderwah, Jammu and Kashmir 182204"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Manni Guest House, Baderwah - Bhaderwah Road, Baderwah, Jammu and Kashmir 182204","https://www.google.com/maps?q=Hotel Ibrahim, University Road, Baderwah, Jammu and Kashmir 182204","https://www.google.com/maps?q=Hotel Shangrila, Near Mini Market, Baderwah, Jammu and Kashmir 182204","https://www.google.com/maps?q=The Royal Skyline, Opp. DC Office, Gulabgarh Rd, Baderwah, Jammu and Kashmir 182204"],
    headingfood: "Must try in Baderwah",
    foodsName: ['Mutton Rogan Josh', 'Rajma Chawal'],
    foodsDescription: [ "This is a classic Kashmiri dish that is also popular in Baderwah. It consists of tender pieces of mutton cooked in a rich gravy flavored with aromatic spices like cloves, cardamom, and cinnamon. It's often served with rice or naan. "," A simple yet delicious dish made with kidney beans cooked in a spiced tomato-based gravy and served with steamed rice. It's a staple dish in many households and is loved for its comforting flavors."],
    religiousPlaces: ["Gupt Ganga Temple"],
    religiousPlacesDescription: ["Temple:A sacred Hindu temple dedicated to Lord Shiva, located in the serene surroundings of Bhaderwah."],
    historicalPlaces: ["Bhaderwah Valley"],
    historicalPlacesDescription: ["Explore the scenic beauty of Bhaderwah Valley, known for its lush green landscapes  and picturesque views. For 200 kilometres, the road from Jammu to Bhaderwah, slips, slithers and snakes through some of India&#39;s most jaw-dropping terrains. Forested slopes and decrepit tuck shops pass by in an ivy green blur, and the reticent river, Neeru, glistens away into the horizon. And after five languorous hours and 40 odd minutes, the bowl-shaped valley of Bhaderwah comes into view.Sitting on the foothills of the middle Himalayas, the town sits at a lofty 5,295ft and has been monikered very aptly as Chhota Kashmir (mini Kashmir). The deodar forests are deep and shrouded in silence, and its meadows are swathed with fuchsia rhododendrons. There's history, rife with both local legends and political fervour. But what will cause you to lose your heart in mere seconds to the once sleepy valley, is its snow-dusted mountains. Perpetually shimmering under the winter sun, they beckon travellers from across the world, making it near impossible to leave."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default baderwahFort;