// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/bangus4.jpeg";
import baderwah2 from "../../img/bungus5.jpg";
// import religiousImage1 from "../../img/bangus1.jfif";
// import religiousImage2 from "../../img/bangus3.jpg";
import historicalImage1 from "../../img/nagmarg.jpg";
import historicalImage2 from "../../img/chitnar.jpg";
import FoodsImage3 from "../../img/kehwa.jfif";
import FoodsImage4 from "../../img/Matschgand.jpg";


const bangusValleyData = {
    name: "Bangus Valley",
    description: "Bangus Valley",
    linkMap:"https://www.google.com/maps?q=Bhaderwah 182222",
    description1: "Nestled in the northern realms of Kashmir, the Bangus Valley stands as a testament to untouched splendor, offering a retreat into the lap of tranquility. This hidden gem remains cocooned from the bustling tourist circuits, allowing visitors to immerse themselves in the unspoiled beauty of the landscape.",
    p1: "Bangus is not merely a valley; it is a canvas painted with lush meadows, dense forests, and snow-draped mountains that create an awe-inspiring panorama. As one ventures into the valley, the sense of isolation and the absence of urban clamor create an atmosphere of pure serenity.",
    p2: "Bangus Valley is a hidden gem in the Himalayas, offering a pristine and unspoiled environment for those seeking solace amidst the breathtaking beauty of the mountains.",
    p3: "The valley is nestled amidst the majestic Himalayan and Pir Panjal mountain ranges, with an average elevation of around 10,000 feet above sea level. It is characterized by vast grasslands, dense forests, sparkling streams, and snow-capped peaks.",    
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The distance from Srinagar to Bangus Valley is approximately 130 kilometers (about 81 miles) by road. However, the actual distance may vary depending on the specific route taken and road conditions. The journey from Srinagar to Bangus Valley typically takes around 4 to 6 hours by car, depending on traffic and weather conditions.",
    about1: "The journey to Bangus is a venture into the lesser-explored corners of Kashmir, where the verdant expanses unfold like a secret waiting to be discovered. Here, time seems to slow down, and the  silence is punctuated only by the rustling of leaves and the occasional call of distant wildlife. Bangus Valley welcomes those seeking an authentic communion with nature, away from the well-trodden paths.",
    about2: "Natural Beauty: Bangus Valley is renowned for its stunning natural beauty and pristine landscapes. The valley's meadows are carpeted with lush green grass and colorful wildflowers during the summer months, creating a picturesque setting that attracts nature lovers, photographers, and adventure enthusiasts.",
    about3: "Cultural Significance: Bangus Valley holds cultural significance for the indigenous communities residing in the region. The valley is inhabited by nomadic Gujjar and Bakarwal tribes, who migrate to the high-altitude pastures with their livestock during the summer months.",
    about4: "Wildlife: The valley is home to diverse flora and fauna, including various species of birds, mammals, and rare plants. It serves as a habitat for wildlife such as Himalayan black bear, musk deer, snow leopard, and various species of birds.",
    arrivalbycab1: "Pre-booked Cab: You can pre-book a cab service from Srinagar through local travel agencies, tour operators, or online platforms. This allows you to secure transportation in advance and ensures that a cab will be available to pick you up at your desired location and time.",
    arrivalbycab2: "Taxi Service: Taxis are readily available in Srinagar, and you can hire one directly from taxi stands or through taxi services operating in the city. Negotiate the fare with the driver beforehand, and make sure they are aware of your destination in Bangus Valley.",
    arrivalbycab3: "When arranging your cab journey, consider factors such as the distance, expected travel time, and road conditions. It's advisable to clarify the fare, confirm the availability of the cab for the round trip if needed, and discuss any additional charges for waiting time or detours.",
    arrivalbycab4: "Be prepared for unpredictable weather conditions, especially during monsoon season (July-September) or winter months (December-March). Consider road closures or potential detours.",
    cimate0: "Summer (June to August): Summers in Bangus Valley are mild and pleasant, with temperatures ranging from around 10°C to 25°C (50°F to 77°F). This is the best time to visit the valley, as the weather is comfortable for outdoor activities such as trekking, camping, and sightseeing. The days are long, and the valley is adorned with blooming flowers and lush greenery.",
    cimate1: "Monsoon (July to September): Bangus Valley experiences some rainfall during the monsoon season, particularly in July and August. While the rainfall may bring occasional disruptions to outdoor activities, it also contributes to the valley's lush vegetation and scenic beauty.",
    cimate2: "Autumn (September to November): Autumn is a beautiful time to visit Bangus Valley, as the temperatures start to cool down, and the valley transforms into a canvas of vibrant colors. The foliage changes to shades of red, orange, and gold, creating stunning landscapes that are ideal for photography and trekking.",
    cimate3: "Winter (December to February): Winter in Bangus Valley is cold and snowy, with temperatures dropping below freezing point. The valley receives heavy snowfall during these months, covering the landscape in a blanket of snow. Winter sports enthusiasts can enjoy activities such as skiing, snowboarding, and snowshoeing in the valley.",
    cimate4: "Spring (March to May): Spring is a transitional season in Bangus Valley, with temperatures gradually warming up and the snow starting to melt. The valley comes alive with the blooming of wildflowers, and it's a great time for nature walks and birdwatching.",
    // cimate5: "Spring in Gurez Bandipora is a delightful season with mild temperatures, ranging from a comfortable 10°C to 20°C. The blooming flora adds a burst of colors, creating a picturesque backdrop for outdoor activities.",
    // cimate6: "Gurez Bandipora experiences minimal rainfall during the monsoon season, primarily from June to October. The average rainfall of 80.5 cm ensures lush greenery, enhancing the scenic beauty of the region.",
    headinghotels: "List Of Hotels",
    hotelsName: ["AFSARA HOTEL AND RESTAURANT","Bangus Valley Resort", "Hotel Lolab valley Kashmir", "Valley View Inn", "Breeze Haven", "Green Meadows Resort"],
    hotelsPhone: ["+91-60050-44483","NA", "+91-70066 54321", "+91-98765 67890", "+91-90123 45678", "+91-80000 11222"],
    hotelsViewLinks: ["https://afsara-hotel-and-restaurant.business.site/", "NA", "http://www.hotellolabvalley.com/", "https://breezehavenresorts.com/", "https://greenmeadowsresort.in/"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=AFSARA HOTEL AND RESTAURANT, Gurez Valley, Izmarg, Jammu and Kashmir 193503", "https://www.google.com/maps?q=BANGUS VALLEY RESORT, Chowkibal, Jammu and Kashmir 193224", "https://www.google.com/maps?q=Hotel Lolab valley Kashmir, Lalpora, Lolab- Tekipora Rd, valley, Jammu and Kashmir 193223", "https://www.google.com/maps?q=Breeze+Haven+Bangus+Valley", "https://www.google.com/maps?q=Green+Meadows+Resort+Bangus+Valley"],
    headingfood: "Must try in Bangus Valley",
    foodsName: ['Kahwa', 'Matschgand'],
    foodsDescription: [ "Kahwa is a traditional Kashmiri green tea infused with spices like saffron, cardamom, cinnamon, and almonds. It is served hot and is known for its aromatic fragrance and refreshing taste.","Matschgand is a traditional Kashmiri dish made with minced meat (usually mutton) cooked in a rich and spicy gravy flavored with yogurt, ginger, garlic, and a blend of aromatic spices. It is often served with steamed rice or naan."],
    religiousPlaces: ["Ziyarat Shareef Reshnari Rajwar ", ""],
    religiousPlacesDescription: ["Ziyarat Shareef Reshnari Rajwar is a revered religious site located in the Rajwar area of the Kupwara district in Jammu and Kashmir, near Bangus Valley. It holds significant importance for Muslims in the region and is visited by devotees seeking blessings and spiritual fulfillment. The site is considered sacred by Muslims and is often visited by pilgrims and devotees for spiritual purposes. It may house the shrine or tomb of a revered saint or religious figure, and believers visit to offer prayers, seek blessings, and fulfill vows.",""],
    historicalPlaces: ["Nagmarg Meadows", "Chitternar"],
    historicalPlacesDescription: ["Nagmarg Meadows, also known as Nagmarg Valley, is a picturesque alpine meadow located in the Baramulla district of Jammu and Kashmir, near Bangus Valley. Nagmarg Meadows is situated in the Pir Panjal range of the Himalayas, approximately 50 kilometers northeast of Srinagar, the summer capital of Jammu and Kashmir. It is nestled amidst pristine forests and towering mountain peaks, offering stunning views of the surrounding landscape.  Nagmarg Meadows is renowned for its natural beauty and tranquility. The meadow is carpeted with lush green grasses, wildflowers, and alpine vegetation, creating a picturesque setting that attracts nature lovers, photographers, and adventure enthusiasts.","Chitternar, also spelled as Chiternar or Chiternarg, is a charming village nestled in the picturesque mountains of the Kashmir Valley, near Bangus Valley and close to the Line of Control (LoC) with Pakistan. Chitternar is renowned for its pristine natural beauty and tranquil surroundings. The village is surrounded by verdant forests, alpine meadows, and cascading streams, creating a serene ambiance that attracts nature lovers, trekkers, and photographers."],
    foodsImages:[FoodsImage3,FoodsImage4],
    // imagesforreligious: [religiousImage1, religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1, historicalImage2], // Add images array for historical
  };
  
  export default bangusValleyData;