import React from "react";
import images from '../img/carousel-11.jpg';


function App() {
  return (
    <div>
              {/* <!-- Carousel Start --> */}
    <div class="container-fluid p-0">
        <div id="header-carousel" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img class="w-100" src={images} alt="pic" />
                    <div class="carousel-caption d-flex flex-column align-items-center justify-content-center">
                        <div class="p-3 max-width-900" >
                            <h4 class="text-white text-uppercase mb-md-3">Discover </h4>
                            <h1 class="display-3 text-white mb-md-4"> Jammu & Kashmir </h1>
                            {/* <a href="" class="btn btn-primary py-md-3 px-md-5 mt-2">Explore Now</a> */}
                        </div>
                    </div>
                </div>
                {/* <div class="carousel-item"> */}
                    {/* <img class="w-100" src={images2} alt="Image" /> */}
                    {/* <div class="carousel-caption d-flex flex-column align-items-center justify-content-center"> */}
                        {/* <div class="p-3 max-width-900"> */}
                            {/* <h4 class="text-white text-uppercase mb-md-3">And</h4>
                            <h1 class="display-3 text-white mb-md-4">All Unexplored Destinations</h1> */}
                            {/* <a href="" class="btn btn-primary py-md-3 px-md-5 mt-2">Book Now</a> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
            </div>
            {/* <a class="carousel-control-prev" href="#header-carousel" data-slide="prev">
                <div class="btn btn-dark width-height-45" >
                    <span class="carousel-control-prev-icon mb-n2"></span>
                </div>
            </a>
            <a class="carousel-control-next" href="#header-carousel" data-slide="next">
                <div class="btn btn-dark width-height-45" >
                    <span class="carousel-control-next-icon mb-n2"></span>
                </div>
            </a> */}
        </div>
    </div>
    
    {/* <!-- Carousel End --> */}
     </div>
     );
    }
    
    export default App;