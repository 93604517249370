import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/amarmahal2.jpg";
import baderwah2 from "../../img/amar-mahal-palace.jpg";
import religiousImage1 from "../../img/Ranbireshwar_Temple.jpg";
import religiousImage2 from "../../img/peekh.jpg";
import historicalImage1 from "../../img/mubarakmandi.jpg";
import historicalImage2 from "../../img/ragunath.jpg";
import gurazFoodsImage1 from "../../img/Dogri Cuisine.webp";
import gurazFoodsImage2 from "../../img/Continental Delicacies.jpg";
import gurazFoodsImage3 from "../../img/roya feast.webp";
import gurazFoodsImage4 from "../../img/muglia.webp";


const amarPalaceData = {
    name: "Amar Palace",
    area: "Area 5 acres",
    temp: "28",
    population: "NA",
    // capital: "Jammu City",
    language: "Dogri, Hindi, English",
    description: "Amar Palace",
    linkMap:"https://www.google.com/maps?q=PVXC+3WM Amar Palace, Karan Nagar, Jammu, 180001",
    description1: "Amar Palace, a regal architectural marvel, graces the city of Jammu with its grandeur. Situated in the heart of the city, this palace stands as a testament to the rich history and cultural heritage of the region. Amar Palace is an epitome of architectural brilliance, blending influences of various styles, including Rajasthani and Mughal, creating a splendid masterpiece that captivates the beholder. The palace is surrounded by well-maintained gardens, adding to its charm and offering visitors a glimpse into the opulence of the bygone era.",
    p1: "Amar Palace is not just a historical structure; it's a journey through time, allowing visitors to immerse themselves in the royal legacy of Jammu. Explore the intricacies of its design, walk through its corridors, and witness the stories etched in its walls.",
    backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2],
    distanceFromSrinagar: "Amar Palace is situated in the heart of Jammu city, making it easily accessible for both locals and tourists. The distance from Jammu to Amar Palace is minimal, and you can reach the palace within a short drive or walk, depending on your location within the city.",
    about1: "Architectural Splendor: Amar Palace stands as an architectural gem, adorned with intricate carvings, majestic arches, and ornate balconies. The palace's design reflects a harmonious blend of Rajput and Mughal architectural styles, showcasing the opulence of its royal past.",
    about2: "Gardens of Tranquility: The palace is surrounded by well-maintained gardens that add to its beauty. Take a leisurely stroll through the landscaped lawns, adorned with vibrant flowers and fountains. The gardens offer a serene retreat, perfect for moments of reflection.",
    about3: "Historical Significance: Amar Palace holds historical significance as it served as the residence of the Dogra kings. Explore the various chambers, halls, and rooms that echo with tales of the royal court and witness artifacts that provide a glimpse into the regal lifestyle.",
    about4: "Panoramic Views: Climb to the palace's elevated vantage points to enjoy panoramic views of the city of Jammu. The strategic location of Amar Palace offers breathtaking vistas of the surrounding landscapes, making it a favorite spot for photographers.",
    arrivalbycab1: "The primary mode of reaching Amar Palace is by road.",
    arrivalbycab2: "Jammu is the nearest major city, and visitors can hire a taxi or take a local bus to reach Amar Palace. The road journey offers scenic views of the Jammu region, creating a memorable travel experience.",
    arrivalbycab3: "Amar Palace is well-connected by road, ensuring accessibility for visitors eager to explore its historical grandeur. Local transportation options are also available for convenient travel.",
    arrivalbycab4: "The palace is centrally located in Jammu, making it easily accessible from various parts of the city.",
    cimate0: "Temperature: 28°C",
    // cimate1: "NA",
    // cimate2: "NA",
    // cimate3: "NA",
    // cimate4: "NA",
    headinghotels: "Accommodation Nearby",
    hotelsName: ["Lemon Tree Hotel, Jammu", "Lords Inn Jammu, Jammu", "Zone By The Park Jammu", "Country Inn & Suites", "Asia One Earth Jammu"],
    hotelsPhone: ["+91-7051894278", "+91-73777 34777", "+91-8899900457", "+61 28 2935790", "+91-6009960097"],
    hotelsViewLinks: ["https://www.lemontreehotels.com/lemon-tree-hotel/jammu/hotel-jammu", "https://www.lordshotels.com/hotels/lords-inn-jammu", "https://www.zonebythepark.com/", "https://www.radissonhotels.com/en-us/brand/country-inn", "https://oneearthhotels.com/jammuh.aspx"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Lemon Tree Hotel, Jammu", "https://www.google.com/maps?q=Lords+Inn+Jammu, Jammu", "https://www.google.com/maps?q=Zone By The Park Jammu", "https://www.google.com/maps?q=Country Inn & Suites , Jammu", "https://www.google.com/maps?q=Asia One Earth Jammu"],
    headingfood: "Dining Options",
    foodsName: ['Royal Feast', 'Mughlai Delights', 'Dogri Cuisine', 'Continental Delicacies'],
    foodsDescription: ["Indulge in a royal feast at the on-site restaurant, offering a menu inspired by the culinary delights enjoyed by the Dogra kings.", 'Savor the richness of Mughlai cuisine at Amar Palace, with a selection of flavorful dishes that transport you to the era of grandeur.', 'Experience the unique flavors of Dogri cuisine, featuring local ingredients and traditional recipes.', 'For those seeking international flavors, the palace offers a range of continental delicacies prepared with finesse.'],
    religiousPlaces: ["Ranbireshwar Temple", "Peer Kho Cave Temple"],
    religiousPlacesDescription: ["Ranbireshwar Temple, located in Jammu, is dedicated to Lord Shiva. The temple is known for its imposing structure and intricate carvings, attracting devotees and tourists alike.", "Peer Kho Cave Temple, nestled in a cave, is dedicated to Lord Shiva. The temple's unique location and spiritual ambiance make it a popular pilgrimage site in Jammu."],
    historicalPlaces: ["Mubarak Mandi Palace", "Raghunath Temple"],
    historicalPlacesDescription: ["Explore the historical Mubarak Mandi Palace, a complex of royal buildings showcasing a blend of Rajasthani, Mughal, and Gothic architectural styles. The palace offers insights into the rich history of the Dogra rulers.", "Visit the Raghunath Temple, an ancient Hindu temple in Jammu dedicated to Lord Rama. The temple's architecture and religious significance make it a prominent landmark in the city."],
    foodsImages:[gurazFoodsImage3,gurazFoodsImage4,gurazFoodsImage1,gurazFoodsImage2],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
    imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default amarPalaceData;