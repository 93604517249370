// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/uri.jfif";
import baderwah2 from "../../img/uri1.jfif";
import religiousImage1 from "../../img/uri-religious1.jpg";
import historicalImage2  from "../../img/urihistorical.avif";
import historicalImage1  from "../../img/urihistorical1.jfif";
import FoodsImage3 from "../../img/mutton-roganjosh.webp";
import FoodsImage4 from "../../img/gushtaba.jpg";

const uriData= {
    name: "URI",
    description: "URI",
    linkMap: "https://www.google.com/maps?q=URI, Jammu and Kashmir",
    description1: "URI, located in the Baramulla district of Jammu and Kashmir, is a town of strategic importance near the Line of Control. It serves as a gateway to the picturesque valleys of Kashmir and is known for its beautiful landscapes and rich cultural heritage. The town is also a crucial point for military and trade activities between India and Pakistan.",
    p1: "URI is renowned for its natural beauty, encompassing rivers, mountains, and lush greenery. It's a popular destination for trekkers and nature enthusiasts looking to explore the serene landscapes of the Himalayas.",
    distanceFromSrinagar: "The distance from Srinagar to URI is approximately 100 kilometers (about 62 miles), and the journey typically takes around 3 to 4 hours by car, depending on road conditions and traffic.",
    about1: "URI holds strategic importance due to its proximity to the Line of Control. It has been a focal point for trade and military activities over the years.",
    about2: "The natural beauty of URI attracts tourists from all over. Its landscapes offer a peaceful retreat from the hustle and bustle of city life.",
    about3: "Security measures in URI are significant due to its location near the Line of Control. Both civilian and military movements are closely monitored to ensure safety and security.",
    about4: "Despite its strategic importance, URI remains a place of cultural and historical significance, with several sites worth exploring for their beauty and historical value.",
    arrivalbycab1: "Visitors can easily reach URI by road from Srinagar or Baramulla. The journey offers scenic views of the Kashmir Valley.",
    arrivalbycab2: "Given its strategic location, travelers should be aware of any travel advisories or restrictions when planning a visit to URI.",
    cimate0: "URI experiences a temperate climate, with distinct seasons offering different experiences to visitors throughout the year.",
    cimate1: "Summer in URI is mild and pleasant, making it an ideal time for outdoor activities and exploration.",
    cimate2: "The monsoon season brings moderate to heavy rainfall, transforming the landscape into a lush, green paradise.",
    cimate3: "Autumn in URI is marked by cool temperatures and clear skies, offering breathtaking views of the changing foliage.",
    cimate4: "Winter in URI can be cold, with snowfall adding to the region's beauty and offering opportunities for winter sports.",
    headinghotels: "List Of Hotels",
    hotelsName: ["Uri Hills Hotel & Restaurant","Hotel Shahi Darbar"],
    hotelsPhone: ["+91-9000000000","96222 54277"],
    hotelsViewLinks: ["https://m.facebook.com/p/Uri-Hills-Restaurant-100057420101370/?locale=hi_IN"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Uri Hills Hotel & Restaurant","https://www.google.com/maps?q=Hotel Shahi Darbar Uri"],
    headingfood: "Must try in URI",
    foodsName: ['Rogan Josh', 'Gushtaba'],
    foodsDescription: ["Rogan Josh is a signature Kashmiri dish consisting of tender lamb cooked in a rich, aromatic gravy, served with rice or bread.", "Gushtaba is a traditional Kashmiri delicacy made from minced mutton balls cooked in a creamy yogurt sauce, flavored with spices."],
    religiousPlaces: ["Datta Mandir"],
    religiousPlacesDescription: [" Datta Mandir located at Boniyar near Uri in Baramulla district.Datta Mandir is actually Detha Mandir Bandi according to the Archaeological Survey of India (ASI) and is believed to have been built in the 10th century in the typical Kashmiri style of architecture. There is a very old photograph taken by a renowned botanist, Ralph. R. Stewart in 1913 which shows the ancient Datta Mandir under repair."],
    historicalPlaces: ["Uri Dam","Kaman Post"],
    historicalPlacesDescription: ["The Uri Dam, located in the Baramulla district of Jammu and Kashmir, India, is a marvel of engineering nestled amidst the stunning Himalayan landscape. While not a traditional dam with a large wall, it's a run-of-the-river type utilizing the Jhelum River's flow for hydroelectric power generation.","Kaman Post (Kaman Setu) is also known as the Bridge of Peace and, believe it or not, it is quite a popular destination in Baramulla district. Located in Uri, Kaman Post falls on the Line of Control."],
    images: [baderwah1, baderwah2],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default uriData;