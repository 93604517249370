// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/sanasar-lake2.jpg";
import baderwah2 from "../../img/sanasar-tourism.jpg";
import religiousImage1 from "../../img/shanklpal.jpg";
import historicalImage1 from "../../img/shanta-gala-pass.jpg";
import FoodsImage3 from "../../img/patisa.webp";
import FoodsImage4 from "../../img/khatta-meat.jpg";


const sanasar = {
    name: "Sanasar",
    
    description: "Sanasar",
    linkMap:"https://www.google.com/maps?q=Sanasar 182143",
    description1: "Sanasar, a picturesque destination nestled in the Jammu province of Jammu and Kashmir, India, is a harmonious union of two quaint villages, Sana and Sar. Situated approximately 20 kilometers west of Patnitop in the Udhampur district, Sanasar charms visitors with its serene ambiance and natural beauty. The villages derive their names from the small local lakes, Sana and Sar, adding a touch of simplicity and authenticity to the surroundings.",
    p1: "Amidst the scenic landscape, the Shanta Ridge stands as a notable mountain range near Sanasar. At the pinnacle of the Shank Pal Ridge, which is part of this range, lies the Shank Pal Temple. Perched at an impressive altitude of 2,800 meters, the temple is a testament to ancient craftsmanship and devotion. Dedicated to Nag Shank Pal, this 400-year-old temple showcases remarkable architectural precision, with no mortar used in joining the stones, symbolizing the enduring strength of both faith and construction.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: " Distance from Srinagar to Sanasar is Approximately 107 kilometers (66 miles).It can take anywhere from 3 to 5 hours depending on road conditions, traffic, and stops.Distance from Jammu to Sanasar is Approximately 147 kilometers (91 miles). The journey usually takes around 4 to 6 hours depending on road conditions, traffic, and stops.",
    about1: "Sanasar, with its blend of natural beauty, cultural heritage, and spiritual significance, emerges as a destination that beckons travelers seeking tranquility and adventure alike. The simplicity of Sana and Sar villages, coupled with the majestic Shank Pal Temple and the panoramic views from Shanta Gala, creates an immersive experience that lingers in the hearts of those fortunate enough to explore this hidden gem in the Jammu and Kashmir state. ",
    about2: "For those seeking a more adventurous experience, a trek to the Shank Pal Temple offers not only spiritual enrichment but also a captivating journey through the pristine surroundings of Sanasar. The temple, with its rich history and sacred aura, becomes a focal point for pilgrims and nature enthusiasts alike. ",
    about3: " Adding to the allure of Sanasar is the Shanta Gala, a pass situated on the Shanta Ridge. This vantage point provides a breathtaking view of the Panchari valley on the opposite side of the ridge. The Shanta Gala pass serves as a gateway to the Lander area, inviting trekkers to explore the enchanting landscapes and panoramic vistas that characterize the region.",
    about4: " ",
    arrivalbycab1: "Reaching Sanasar by cab allows you to enjoy the beautiful landscapes and fresh mountain air of Jammu & Kashmir while someone else handles the driving. ",
    arrivalbycab2: "Book in advance: Especially during peak season or holidays, book your cab beforehand to ensure availability and potentially better rates. ",
    arrivalbycab3: "Consider stopping at Patnitop, a beautiful hill station, on your way from Jammu. ",
    arrivalbycab4: "If you have time, you can extend your trip to Katra, the gateway to Vaishno Devi shrine, and explore the area. ",
    cimate0: "Sanasar experiences a diverse climate throughout the year, making it an appealing destination for visitors seeking different atmospheres and activities during each season. ",
    cimate1: "Summer (April to June): During the summer months, Sanasar enjoys a pleasant climate with temperatures ranging from 10°C to 20°C. The weather is ideal for outdoor activities, making it a popular time for trekking, camping, and nature walks. The cool breeze and mild temperatures create a comfortable  environment for visitors to explore the lush landscapes surrounding Sanasar. This season is particularly suitable for those who prefer milder weather and outdoor adventures.  ",
    cimate2: "Monsoon (July to September): Sanasar receives moderate rainfall during the monsoon season. While the landscapes become lush and vibrant, the region&#39;s hilly terrain may pose challenges due to the possibility of landslides. Travelers are advised to exercise caution during this period. However, the monsoon season also brings a unique charm to Sanasar, with the mist-covered hills and fresh greenery creating a picturesque setting for those who enjoy the dramatic beauty of rainy landscapes. ",
    cimate3: " Autumn (October to November):Autumn brings a delightful change to Sanasar as the temperatures begin to drop, ranging from 5°C to 15°C. The crisp air and clear skies make it an excellent time for trekking and other outdoor  activities. The landscapes transform with the changing colors of the foliage, creating a captivating environment for nature enthusiasts and photographers.",
    cimate4: "Winter (December to March): Winters in Sanasar are characterized by cold temperatures, with the mercury dropping to sub- zero levels. Snowfall is a common occurrence, transforming the region into a winter wonderland. Winter sports enthusiasts can indulge in activities like snowboarding and skiing. The Shank Pal Ridge and nearby areas are blanketed in snow, offering a serene and tranquil atmosphere for those seeking a peaceful winter retreat. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Sanasar ","Sanasar Huts", ],
    hotelsPhone: ["+91-94191 43812","+91-NA", ],
    hotelsViewLinks: ["NA", "NA", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Lolab Valley , Lolab Valley, Jammu and Kashmir"],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Patisa: A Sweet Treat Fit for Royalty', 'Khatta Meat:'],
    foodsDescription: [ "Made with gram flour (besan), sugar, ghee, and often dry fruits like pistachios and almonds, it boasts a melt-in-your-mouth texture and a rich, nutty flavor..  ","Mutton or chicken cooked in a tangy gravy with tamarind or unripe mangoes. "],
    religiousPlaces: ["Shank Pal Temple"],
    religiousPlacesDescription: ["The Shank Pal Temple, located at the highest point of the Shank Pal Ridge at an altitude of 2,800 meters, is a 400-year-old architectural marvel dedicated to Nag Shank Pal. Remarkably constructed without the use of mortar, this ancient temple offers not only a spiritual retreat but also panoramic views of the surrounding landscapes, making it a must-visit destination for pilgrims and nature enthusiasts alike."],
    historicalPlaces: ["Shanta Gala Pass"],
    historicalPlacesDescription: ["Shanta Gala is a scenic pass situated on the Shanta Ridge near Sanasar, providing a mesmerizing view of the Panchari valley on the opposite side. This vantage point, reached through a trek along the ridge, offers a breathtaking panorama of the surrounding hills and valleys. Shanta Gala serves as a gateway to the Lander area, making it a favored destination for trekkers and nature lovers seeking unparalleled vistas."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default sanasar;