// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/tangdar1.jpg";
import baderwah2 from "../../img/tangdar2.jpg";
import religiousImage1 from "../../img/machail2.jpg";
import historicalImage2  from "../../img/RedwaniNallah.jpeg";
import historicalImage1  from "../../img/lolab.jpg";
import FoodsImage3 from "../../img/Sheer chaie.jfif";
import FoodsImage4 from "../../img/gushtaba.jpg";

const tangdarData= {
    name: "Tangdhar",
    description: "Tangdhar",
    linkMap: "https://www.google.com/maps?q=Tangdhar, Jammu and Kashmir",
    description1: "Tangdhar, located in the Kupwara district of Jammu and Kashmir, is a picturesque town nestled in the Himalayas. It serves as an important location for its strategic significance and its role in facilitating cross-border trade and connectivity. This town is surrounded by stunning landscapes and is known for its beautiful meadows, mountains, and rivers.",
    distanceFromSrinagar: "The distance from Srinagar to Tangdhar can vary depending on the route taken. It is approximately X kilometers (about Y miles), and the journey typically takes around Z hours by car, depending on road conditions and traffic.",
    about1: "Tangdhar holds strategic importance due to its location close to the Line of Control. It is a gateway to several border areas and is known for its picturesque landscapes.",
    about2: "The area facilitates trade and travel between the regions, serving as a crucial point for cross-border movement of goods and people, fostering economic and cultural exchanges.",
    about3: "Given its proximity to the Line of Control, Tangdhar is subject to heightened security measures. Security forces are stationed in the area to monitor movements and ensure border security.",
    about4: "Besides its strategic and trade importance, Tangdhar also attracts tourists due to its scenic beauty and tranquil environment. It offers a unique experience to explore the natural and cultural heritage of the region.",
    arrivalbycab1: "Visitors can reach Tangdhar by road. The town is accessible through various routes, and the roads are connected well for the convenience of travelers.",
    cimate0: "Tangdhar experiences a temperate climate with distinct seasons, each offering a unique experience to visitors.",
    cimate1: "Summer (Months): Warm and pleasant, ideal for exploring the outdoors.",
    cimate2: "Monsoon (Months): Characterized by rainfall, making the region lush and green.",
    cimate3: "Autumn (Months): Mild temperatures and clear skies, perfect for sightseeing.",
    cimate4: "Winter (Months): Cold, with snowfall in higher areas, creating beautiful winter landscapes.",
    headinghotels: "List Of Hotels",
    hotelsName: ["OYO Flagship 89130 Kohsheen Guest House"],
    hotelsPhone: ["+91-0124 620 1520"],
    hotelsViewLinks: ["https://www.oyorooms.com/178478/"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=OYO Flagship 89130 Kohsheen Guest House"],
    headingfood: "Must try in URI",
    foodsName: ['Noon Chai', 'Gushtaba'],
    foodsDescription: ["This traditional Kashmiri tea, brewed with green tea, milk, salt, and spices, is a must-try for a warm and comforting beverage.", "Gushtaba is a traditional Kashmiri delicacy made from minced mutton balls cooked in a creamy yogurt sauce, flavored with spices."],
    religiousPlaces: ["Machail Mata Temple"],
    religiousPlacesDescription: [" Machail Mata Temple is a significant pilgrimage site located in the neighboring Kishtwar district. It's dedicated to Goddess Durga and attracts a large number of devotees during the annual Machail Yatra."],
    historicalPlaces: ["Lolab Valley","Redwani Nallah"],
    historicalPlacesDescription: ["Tangdhar introduces travelers to the mesmerizing Lolab Valley, where nature unfolds in all its glory, offering panoramic views and a peaceful escape.","The serene Redwani Nallah is a charming stream in the vicinity, providing a perfect setting for relaxation amidst nature."],
    images: [baderwah1, baderwah2],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default tangdarData;