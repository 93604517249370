// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/apharwat-peak1.jpg";
import baderwah2 from "../../img/apharwat-peak2.jpg";
import religiousImage1 from "../../img/baba-reshi.jpg";
import historicalImage1 from "../../img/gondola.jpg";
import historicalImage2 from "../../img/alpather_lake.jpg";
import FoodsImage3 from "../../img/kabab.jpg";
import FoodsImage4 from "../../img/kashmiri-pulao.webp";


const apharwat = {
    name: "Apharwat Peak",
    
    description: "Apharwat Peak",
    linkMap:"https://www.google.com/maps?q=Afarwat",
    description1: "Nestled in the Pir Panjal range of the Himalayas, Apharwat Peak stands as a majestic sentinel, overseeing the picturesque landscapes near the renowned hill station of Gulmarg in the union territory of Jammu and Kashmir, India. This soaring peak,  with an impressive elevation of approximately 4,390 meters (14,403 feet) above sea level, commands attention with its snow- clad summits that glisten against the azure sky.",
    p1: "Apharwat Peak is not merely a geographical landmark; it is a sanctuary of natural beauty that beckons travelers to witness the grandeur of the Himalayas. Its lofty altitude offers a commanding view of the surrounding valleys, creating a canvas where the pristine white of the snow meets the deep greens of the pine forests, forming a visual symphony that resonates with every visitor.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The distance from Srinagar to Apharwat Peak in Gulmarg is approximately 50 kilometers (about 31 miles) by road. This distance can vary slightly depending on the specific route taken and road conditions. The journey from Srinagar to Gulmarg usually takes around 1.5 to 2 hours by road, depending on traffic and weather conditions.The primary mode of reaching Apharwat Peak is via the Gulmarg Gondola, one of the highest cable cars in the world. The  Gondola ride comprises two phases, with the first stage taking visitors to Kongdori Station and the second stage ascending to Apharwat Peak. The cable car journey offers mesmerizing panoramic views of the surrounding valleys and the snow-capped  Himalayan peaks. Additionally, during favorable weather conditions, trekking or skiing may be viable options for the more adventurous visitors.",
    about1: "This enchanting destination is not only a feast for the eyes but also a heaven for nature enthusiasts seeking tranquility amid the mountainous wilderness. The crisp mountain air, tinged with the scent of pine, invites exploration and adventure. Whether it&#39;s  the exhilaration of skiing down its slopes during winter or the serenity of a summer trek, Apharwat Peak embraces visitors with a diverse range of experiences. ",
    about2: "As a vital part of the Gulmarg landscape, Apharwat Peak is integral to the allure of this region. Its snow-clad peaks contribute to Gulmargs reputation as a winter wonderland and a coveted destination for those in search of both natural beauty and thrilling  escapades. In every season, Apharwat Peak unfolds its unique charm, making it an indispensable gem in the crown of Jammu and Kashmir&#39;s Himalayan treasures. ",
    about3: "Skiing: Apharwat Peak is famous for its excellent skiing conditions, especially during the winter months when it's covered in a thick blanket of snow. The Gulmarg Gondola, one of the highest cable cars in the world, takes visitors up to the peak. Skiing enthusiasts from around the world visit Gulmarg to experience its powder snow and challenging slopes. ",
    about4: "Snowboarding: Besides skiing, Apharwat Peak also offers fantastic opportunities for snowboarding. Its steep slopes and deep snow attract snowboarders seeking adrenaline-pumping adventures. ",
    arrivalbycab1: "If you're planning to travel from Srinagar to Apharwat Peak in Gulmarg by cab, you have a few options: ",
    arrivalbycab2: "Pre-booked Cab: You can arrange for a pre-booked cab service either through a local travel agency, hotel, or online booking platforms. This way, you can ensure that a cab will be waiting for you at your desired location and time. ",
    arrivalbycab3: "Taxi Service: Taxis are readily available in Srinagar, and you can hire one directly from taxi stands or through taxi services operating in the city. Negotiate the fare beforehand, and make sure the driver is aware of your destination in Gulmarg ",
    arrivalbycab4: "App-based Cab Services: Some app-based cab services might operate in Srinagar. You can use these services to book a cab for your journey to Gulmarg. Ensure that the service covers the route to Gulmarg and that the driver is willing to take you there. ",
    cimate0: "Apharwat Peak experiences a subarctic climate, characterized by cold winters and cool summers.",
    cimate1: "Spring (March to May): Spring in Gulmarg is characterized by mild temperatures, with daytime highs ranging from 10°C to 20°C (50°F to 68°F). The weather starts to warm up, and the snow begins to melt, revealing lush greenery and blooming flowers. ",
    cimate2: "Summer (June to August): Summer brings relatively pleasant weather to Gulmarg, with daytime temperatures ranging from 20°C to 30°C (68°F to 86°F). It's the best time for outdoor activities such as hiking, golfing, and sightseeing. However, occasional rain showers can occur, so it's advisable to carry rain gear. ",
    cimate3: "Autumn (September to November): Autumn is a beautiful time to visit Gulmarg when the weather starts to cool down, and the landscape transforms into vibrant hues of red, orange, and yellow as the leaves change color. Daytime temperatures range from 10°C to 20°C (50°F to 68°F), making it ideal for trekking and photography. ",
    cimate4: "Winter (December to February): Winter is the peak tourist season in Gulmarg, especially for skiing and snowboarding enthusiasts. Daytime temperatures range from -2°C to 10°C (28°F to 50°F), but temperatures can drop significantly below freezing at night. Heavy snowfall blankets the region, creating perfect conditions for winter sports. It's essential to dress warmly and be prepared for cold weather conditions. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Pine Palace Resort ","Welcome Hotel Gulmarg","Hotel Hilltop" ,"The Khyber Himalayan Resort & Spa"],
    hotelsPhone: ["+91-84470 88882","+91-9906499392"," 060065 02122"," 01954 350 666" ],
    hotelsViewLinks: ["https://www.pinepalacegulmarg.com/", "https://www.welcomehotelsgr.com/","https://hilltophotels.in/","https://www.khyberhotels.com/" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Pine Palace Resort, near, Gondola, Gulmarg, Jammu and Kashmir 193403","https://www.google.com/maps?q=Welcome Hotel Gulmarg, Near Main Market Circular Road, Gulmarg, Jammu and Kashmir 193403","https://www.google.com/maps?q=Hotel Hilltop, Gandola Rd, Gulmarg, Forest Block, Jammu and Kashmir 193403","https://www.google.com/maps?q=The Khyber Himalayan Resort & Spa, 29RP+R2, Hotel Khyber Rd, Forest Block, Jammu and Kashmir 193403"],
    headingfood: "Must try in Apharwat",
    foodsName: ['Kashmiri Pulao’', 'Kebabs'],
    foodsDescription: [ "Kashmiri Pulao is a fragrant rice dish cooked with a blend of aromatic spices, saffron, dried fruits like raisins and nuts, and sometimes, fresh vegetables. It's a mildly sweet and savory dish that pairs well with meat curries. ","Various types of kebabs, such as Seekh Kebabs (minced meat skewers) and Tabak Maaz (fried lamb ribs), are popular in Kashmiri cuisine and are often served as appetizers or snacks. "],
    religiousPlaces: ["Shrine of Baba Reshi"],
    religiousPlacesDescription: ["Located in the village of Babareshi near Gulmarg, this shrine is dedicated to the Sufi saint Baba Payam-Uddin Reshi, also known as Baba Reshi. It is a revered site for both Muslims and Hindus and is visited by devotees seeking blessings and spiritual solace."],
    historicalPlaces: ["Gulmarg Gondola","Alpather Lake"],
    historicalPlacesDescription: ["The starting point for the journey to Apharwat Peak, the Gulmarg Gondola is an attraction in itself, offering breathtaking views as it ascends through the picturesque landscapes.","Situated at the foot of Apharwat Peak, this pristine lake remains frozen for most of the year, creating a stunning backdrop against the snow-covered terrain."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default apharwat;