import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import image2 from "../../img/daksum2.webp";
import image1  from "../../img/daksum1.jfif";
import religiousImage1 from "../../img/masjiddaksum.jpg";
import historicalImage1 from "../../img/sinthantop.jpeg";
import historicalImage2 from "../../img/bringhiriver.jfif";
import gurazFoodsImage3 from "../../img/kehwa.jfif";
import gurazFoodsImage4 from "../../img/wazwan.jfif";


const daksumValleyData = {
    name: "Daksum Valley",
        area: "Area 200 km²",
        temp: "15",
        population: "15,000",
        // capital: "Daksum City",
        language: "Kashmiri, Urdu, English",
        description: "Daksum Valley",
        linkMap:"https://www.google.com/maps?q=Daksum, Forest Block 192202",
        description1: "Daksum Valley, often hailed as the 'Emerald Paradise,' is a pristine gem nestled in the Anantnag district of the union territory of Jammu and Kashmir, India. Cradled by the majestic Pir Panjal range, Daksum is celebrated for its lush greenery, enchanting landscapes, and serene ambiance. With an elevation of approximately 7,000 feet above sea level, Daksum Valley offers a tranquil retreat, making it an idyllic haven for nature enthusiasts and those seeking respite amidst verdant beauty.",
        p1: "Daksum Valley unfolds a tapestry of natural splendor, cultural richness, and historical significance. Immerse yourself in the charm of Daksum, where every step is a journey into the heart of nature's beauty.",
        backgroundImage: `url(${gurazBandiporaBackground})`,
        images: [image1, image2],
        distanceFromSrinagar: "Daksum Valley is situated around 90 kilometers from Srinagar, the summer capital of Jammu and Kashmir. The journey from Srinagar to Daksum Valley takes approximately 3-4 hours by road, offering travelers a scenic drive through quaint villages and picturesque landscapes.",
        about1: "Nature's Canvas: Daksum Valley is a canvas painted with the hues of nature. Explore the vibrant meadows, stroll through dense forests, and witness the valley's ever-changing panorama. Daksum is a haven for those seeking solace in the lap of Mother Nature.",
        about2: "Cultural Charms: Immerse yourself in Daksum's rich cultural tapestry. Admire local craftsmanship in traditional markets, savor the flavors of Kashmiri cuisine, and experience the warmth of hospitality. Daksum's cultural encounters are as captivating as its natural beauty.",
        about3: "Historical Whispers: Daksum Valley echoes with whispers of history. Visit ancient sites like Daksum Fort, which stands as a testament to the valley's historical legacy. The architectural remnants and landscapes tell stories of a bygone era.",
        about4: "Tranquil Retreats: Seek tranquility in Daksum's spiritual sanctuaries. Visit revered shrines like Daksum Mosque and immerse yourself in moments of serenity. The spiritual essence adds a profound dimension to Daksum Valley's allure.",
        arrivalbycab1: "The primary mode of reaching Daksum Valley is by road.",
        arrivalbycab2: "The nearest major city is Anantnag, from where one can hire a taxi or take a bus to embark on the scenic journey to Daksum Valley.",
        arrivalbycab3: "The road journey offers breathtaking views of the Pir Panjal range, creating a memorable travel experience for those seeking a peaceful getaway.",
        arrivalbycab4: "Daksum Valley is well-connected by road, ensuring accessibility for travelers eager to explore its natural and cultural treasures.",
        cimate0: "Temperature: 10°C",
        cimate1: "Rainfall: 50 cm",
        cimate2: "Summer (May to August): Mild temperatures ranging from 10°C to 20°C, providing an ideal climate for outdoor activities.",
        cimate3: "Autumn (September to November): Cool temperatures and the landscape adorned with autumn colors create a picturesque setting.",
        cimate4: "Winter (December to February): Cold temperatures with occasional snowfall, transforming Daksum Valley into a serene winter wonderland. Temperatures can drop to freezing levels.",
        headinghotels: "List Of Hotels",
        hotelsName: ["Paradise Retreat Daksum.", "DAKSUM VIEW Hotel & Restaurant", "Malik Resort Hotel & Restaurant Vailoo", "JKTDC Daksum Resort", "Park inn cottage"],
        hotelsPhone: ["+91-97979 65786","+91-97971-32752", "+91-70064 98504", "+91-0194 2502274"],
        hotelsViewLinks: ["http://www.paradiseretreatdaksum.in/", "https://daksum-view-hotel-restaurant.business.site/", "https://malik-resort-hotel-restaurant.business.site/?utm_source=gmb&utm_medium=referral", "https://www.jktdc.co.in/CityHotels.aspx?Cid=8", "https://www.instagram.com/parkinncottage/"],
        hotelsMapsLinks: ["https://www.google.com/maps?q=Paradise Retreat Daksum, JC6P+F3R", "https://www.google.com/maps?q=DAKSUM VIEW Hotel & Restaurant, Daksum", "https://www.google.com/maps?q=Malik Resort Hotel & Restaurant Vailoo Daksum, H976+X46", "https://www.google.com/maps?q=Jktdc daksum, JC6P+RCJ, Daksum, Forest Block, 192202", "https://www.google.com/maps?q=Park inn cottage, Daksum, Kokernag, Now Bugh, Jammu and Kashmir 192101"],
        headingfood: "Must try in Daksum Valley",
        foodsName: ['Kahwa', 'Rogan Josh', 'Yakhni'],
        foodsDescription: ["A traditional Kashmiri tea infused with aromatic spices, offering a delightful beverage experience.", "Rogan Josh, a flavorful and aromatic meat curry that is a culinary delight.", "Yakhni, a traditional Kashmiri dish featuring tender meat cooked in a yogurt-based gravy, known for its rich and mild flavors."],
        religiousPlaces: ["Masjid Sharif Bilaliya Daksum Barkhana"],
        religiousPlacesDescription: ["The mosque's architecture is a captivating blend of traditional Kashmiri and Ladakhi styles. Its wooden façade, adorned with intricate carvings, reflects the region's rich artistic heritage. The towering minarets pierce the sky, adding a touch of grandeur to the structure."],
        historicalPlaces: ["Sinthan Top", "Bringhi River"],
        historicalPlacesDescription: ["Sinthan Top is a less-known tourist spot in the outskirts of Anantnag that is gradually gaining popularity because of its natural beauty. The Sinthan pass connects Anantnag with Kishtwar and is the only road that connects the people of Warwan and Marwah of Kishtwar district with district headquarters in Kishtwar town. It is 12,500 feet (3,800 m) above sea level and is snow-capped for most of the year. It is located on the Anantnag–Kokernag–Kishtwar section of NH 244, and is 80 km from Kishtwar town, 130 km south of Srinagar and 73 km from Anantnag and 48 km from Kokernag. Sinthan Top has no local population. Shepherds from adjoining places like Kokernag, Daksum, and tourists and those working in the tourism industry may stay overnight in tents.","The Bringhi river passes through the Daksum and it is known to attract tourists for the serene environment that it presents. Surrounding this region is coniferous trees that can give you a perfect Instagrammable shot. The nearby area makes for a great picnic spot that you can visit with your friends and family members."],
        foodsImages:[gurazFoodsImage3,gurazFoodsImage4],
        imagesforreligious: [religiousImage1 ], 
        imagesforhistorical: [historicalImage1, historicalImage2],
        };
        
        export default daksumValleyData;