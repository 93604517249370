// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import GathaLake from "../../img/gatha-lake2.jpeg";
import GathaLake2 from "../../img/gatha-lake.webp";
import religiousImage1 from "../../img/chandi-mata.jpg";
import religiousImage2 from "../../img/vasuki-naag.jpg";
import historicalImage1 from "../../img/khelani-top.jpg";
import historicalImage2 from "../../img/guptganga.png";
import FoodsImage1 from "../../img/modur-pulao.jpg";
import FoodsImage2 from "../../img/chole-bature.jpg";


const gathaLakeData = {
    name: "Gatha Lake",
    
    description: "Gatha Lake",
    linkMap:"https://www.google.com/maps?q=2P23+J94 Gatha lake park, Forest Block, 182222",
    description1: "It is located within Bhaderwah town and having a garden and small lake, this resort is situated in the heart of Bhaderwah town.It welcomes guests with a restaurant, a water park, and a lake. It offers a beautiful view of the Kailash and Ashapati Glaciers. Gatha Lake, nestled in the enchanting region of Baderwah in Jammu and Kashmir, stands as a serene and picturesque water body surrounded by the grandeur of the Himalayan landscape. This natural gem is likely to offer visitors breathtaking views of the surrounding mountains and valleys, creating a tranquil and awe-inspiring atmosphere. ",
    p1: "Typical of lakes in the Himalayan region, Gatha Lake may boast a diverse array of flora and fauna, contributing to the area's ecological richness. Birdwatchers and nature enthusiasts might find delight in observing various avian species in and around the lake. Moreover, lakes in this region often hold cultural and religious significance, with local communities having traditional stories or legends associated with these pristine waters.    ",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [GathaLake , GathaLake2], // Add images array
    distanceFromSrinagar: "The journey from Jammu to Baderwah by road takes approximately 5 to 6 hours, depending on the road conditions and traffic. ",
    about1: "Accessibility to Gatha Lake would depend on the specific location and local infrastructure. Travelers typically embark on a journey through well-connected roads, each stretch unfolding scenic landscapes that add to the overall experience. Depending on the tourism infrastructure, Gatha Lake could potentially offer recreational activities such as boating, picnicking, and photography, allowing visitors to immerse themselves in the natural beauty of the surroundings.    ",
    about2: "To plan a visit to Gatha Lake, it is advisable to check with local authorities, tourism offices, or reliable online resources for the latest information on accessibility, weather conditions, and any regulations in place. As nature's haven, Gatha Lake in Baderwah is likely to provide a peaceful retreat and an opportunity for visitors to connect with the pristine beauty of the Himalayas.    ",
    about3: "Potential:Boating activities and light music by the local artist also add to the charm of the place. Boating activity, light, music, nature, walk picnic etc. ",
    about4: "Nearby Spots: Jai valley, Padri, Guldanda, Khellani top, Bhaderwah Fort, Gupt Ganga, Kailash kund, Seoj dhar, Vasuki Naag temple, and Chandi Mata etc    ",
    arrivalbycab1: "To reach Gatha Lake in Baderwah by cab, you would typically need to hire a taxi from a nearby town or city. Jammu is the nearest major city with good road connectivity to Baderwah. From Jammu, you can hire a cab or taxi service to reach Baderwah. ",
    arrivalbycab2: " Once you reach Baderwah, you can further hire a cab or arrange for local transportation to reach Gatha Lake. ",
    arrivalbycab3: "It's advisable to plan your trip in advance and book a cab service with a reputable provider to ensure a comfortable and safe journey. ",
    arrivalbycab4: "Additionally, it's a good idea to inquire about the road conditions and weather forecast before starting your journey, especially if you're traveling during the winter months when the region experiences snowfall. ",
    cimate0: "Bhaderwah experiences a subtropical highland climate with distinct seasons. ",
    cimate1: "Summer (April - June): Warm to hot, with average temperatures ranging from 20°C to 30°C (68°F to 86°F). Occasional showers. ",
    cimate2: "Monsoon (July - September): Moderate to heavy rainfall, with average temperatures around 25°C (77°F). ",
    cimate3: "Autumn (October - November): Pleasant, with average temperatures dropping from 20°C to 10°C (68°F to 50°F). Some snowfall possible at higher altitudes. ",
    cimate4: "Winter (December - March): Cold, with average temperatures ranging from 5°C to -5°C (41°F to 23°F). Frequent snowfall, especially at higher altitudes. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Lake Resort","The Chamba Resort and Camps", ],
    hotelsPhone: ["+91-99067 10008","+91-788-9958554", ],
    hotelsViewLinks: ["", "https://www.chambaresortnubra.com/", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q= "],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Mitha Bhaat', 'Chole Bhature'],
    foodsDescription: [ "Mitha Bhaat is a sweet rice dish made with Basmati rice, sugar, ghee, and dry fruits. ","This is a popular dish consisting of spicy chickpea curry (chole) served with deep-fried bread (bhature). It's a hearty and flavorful meal often enjoyed for breakfast or lunch. "],
    religiousPlaces: ["Chandi Mata ","Vasuki Nag"],
    religiousPlacesDescription: ["Chandi, (Sanskrit: “The Fierce”) demon-destroying form of the Hindu goddess Shakti, particularly popular in eastern India. She is known by various names, such as Mahamaya (“Great Magic”) or Abhaya (“She Who Is Without Fear”). Her representation is similar to that of Durga, another form of Shakti.","Vasuki Nag Temple dedicated to snake god (Son of Kashyapa and Kadru), is located in Bhaderwah in Doda district. The temple enshrines a single black stone piece idol of Vasuki Naag and Raja Jamute Vahan. It is a marvelous piece of art and sculpture of the region    "],
    historicalPlaces: ["Khellani top","Gupt Ganga"],
    historicalPlacesDescription: ["Khellani Top is a scenic mountain pass located in the Baderwah region of Jammu and Kashmir, India. It offers breathtaking views of the surrounding Himalayan landscape and is a popular destination for nature lovers, trekkers, and adventure enthusiasts. The pass is situated at an elevation of around 3,500 meters (approximately 11,500 feet) above sea level, providing visitors with panoramic vistas of snow-capped peaks, lush valleys, and meandering streams.","Gupt Ganga is a significant natural wonder located in the Baderwah region of Jammu and Kashmir, India. It is a revered underground stream that originates from the Bhaderwah Valley and is believed to have immense religious significance among the locals.The stream flows through a cave system, and the exact length and depth of the underground course are not fully explored. However, it is known that the water emerges from the cave system at various points along its course. The Gupt Ganga stream eventually merges with the Neeru River, adding to the natural beauty of the region."],
    foodsImages:[FoodsImage1,FoodsImage2],
    imagesforreligious: [religiousImage1 ,religiousImage2], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default gathaLakeData;