// DistrictDetails.js
import React from 'react';

const District = ({  selectedFeature, weather }) => {
  if (!selectedFeature) {
    return null;
  }

  const properties = selectedFeature.properties;

  return (
    <div className="min-width">
      <h2 class="text-center colorHeder">{properties.district}</h2>
      <p class="text-justify">{properties.details}</p>
      <br />
      
      {/* <p><i class="fas fa-cloud"></i>  {`Temperature: ${weather?.current?.temperature}°C`}</p> */}
      {/* <p>{`Description: ${weather?.current?.weather_descriptions[0]}`}</p> */}
    </div>
  );
};

export default District;
