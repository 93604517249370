import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import gurazData from "./Data/gurezdata";
import srinagarData from "./Data/srinagardata";
import ganderbalData from "./Data/ganderbaldata";
import lalDramanData from "./Data/laldraman";
import amarMahalPalace from "./Data/amarplace";
import bangusValleyData from "./Data/bangusvalleydata";
import akhnoorData from "./Data/akhnoor"; 
import lolabData from "./Data/lolabvalley"; 
import daksum from "./Data/daksumvalley";
import gathaLake from "./Data/gathalake";
import baderwahData from "./Data/baderwah";
import jantronDhar from "./Data/jantrondhar";
import siyedBaba from "./Data/siyedbaba";
import sanaSar from "./Data/sanasar"
import panjNara from "./Data/panjnara";
import apharWat from "./Data/apharwat";
import nilNag from "./Data/nilnag";
import tattaPani from "./Data/tattapani";
import tarsarLake from "./Data/tarsarlake";
import mantaliaLake from "./Data/mantalialake";
import astanMarg from "./Data/astanmarg";
import baderwahFortData from "./Data/baderwahfort";
import sheeshMahal from "./Data/seeshmahal";
import warWan from "./Data/warwan";
import padRi from "./Data/padri";
import patniTop from "./Data/patnitop";
import shalimarBagh from "./Data/shalimar";
import pariMahal from "./Data/parimahal";
import Ramnagar from "./Data/ramnagar";
import Batote from "./Data/batote";
import chakkanData from "./Data/chakkan";
import uriData from "./Data/uri";
import tangdharData from "./Data/tangdar";
import pallanwalasectorData from "./Data/pallanwala";
import Heder from "../Dashboard/custom-header";
import Footer from "../Dashboard/footer";
import "../Destination/destination.css";



const Destination = () => {
  const { location } = useParams();
  const [destinationData, setDestinationData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [historicalPlaceIndex, setHistoricalPlaceIndex] = useState(0);
  const [religiousPlaceIndex, setReligiousPlaceIndex] = useState(0);
  const [foodsIndex, setFoodsIndex] = useState(0);
  const [showHistoricalPlace, setShowHistoricalPlace] = useState(false);
  const [showReligiousPlace, setShowReligiousPlace] = useState(false);


  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const locationParam = queryParams.get("location");

    if (locationParam === "ganderbal") {
      setDestinationData(ganderbalData);
    } else if (locationParam === "srinagar") {
      setDestinationData(srinagarData);
    } 
    else if (locationParam === "guraz") {
      setDestinationData(gurazData);
    }
    else if (locationParam === "laldraman") {
      setDestinationData(lalDramanData);
    }
    else if (locationParam === "baderwah") {
      setDestinationData(baderwahData);
    }
    else if (locationParam === "bangusvalley") {
      setDestinationData(bangusValleyData);
    }
    else if (locationParam === "daksumvalley") {
      setDestinationData(daksum);
    }
    else if (locationParam === "akhnoor") {
      setDestinationData(akhnoorData);
    }
    else if (locationParam === "lolabvalley") {
      setDestinationData(lolabData);
    }
    else if (locationParam === "amarpalace") {
      setDestinationData(amarMahalPalace);
    }
    else if (locationParam === "gathalake") {
      setDestinationData(gathaLake);
    }
    else if (locationParam === "jantrondhar"){
      setDestinationData(jantronDhar);
    }
    else if(locationParam === "syedbaba"){
      setDestinationData(siyedBaba);
    }
    else if(locationParam === "sanasar"){
      setDestinationData(sanaSar);
    }
    else if(locationParam === "panjnara"){
      setDestinationData(panjNara);
    }
    else if(locationParam === "apharwat"){
      setDestinationData(apharWat);
    }
    else if(locationParam === "nilnag"){
      setDestinationData(nilNag);
    }
    else if(locationParam === "tattapani"){
      setDestinationData(tattaPani);
    }
    else if(locationParam === "tarsarlake"){
      setDestinationData(tarsarLake);
    }
    else if(locationParam === "mantalialake"){
      setDestinationData(mantaliaLake);
    }
    else if(locationParam === "astanmarg"){
      setDestinationData(astanMarg);
    }
    else if(locationParam === "baderwahfort"){
      setDestinationData(baderwahFortData);
    }
    else if(locationParam === "seeshmahal"){
      setDestinationData(sheeshMahal);
    }
    else if(locationParam === "warwan"){
      setDestinationData(warWan);
    }
    else if(locationParam === "padri"){
      setDestinationData(padRi);
    }
    else if(locationParam === "patnitop"){
      setDestinationData(patniTop);
    }
    else if(locationParam === "shalimar"){
      setDestinationData(shalimarBagh);
    }
    else if(locationParam === "parimahal"){
      setDestinationData(pariMahal);
    }
    else if(locationParam === "parimahal"){
      setDestinationData(pariMahal);
    }
    else if(locationParam === "parimahal"){
      setDestinationData(pariMahal);
    }
    else if(locationParam === "ramnagar"){
      setDestinationData(Ramnagar);
    }
    else if(locationParam === "batote"){
      setDestinationData(Batote);
    }
    else if(locationParam === "chakkan"){
      setDestinationData(chakkanData);
    }
    else if(locationParam === "uri"){
      setDestinationData(uriData);
    }
    else if(locationParam === "tangdhar"){
      setDestinationData(tangdharData);
    }
    else if(locationParam === "pallanwalasector"){
      setDestinationData(pallanwalasectorData);
    }
    

    
    
    
    
  }, [location]);

  const handleItemClick = (item) => {
    setSelectedItem((prevSelectedItem) => (prevSelectedItem === item ? null : item));
    setSelectedSubItem(null);
    setShowHistoricalPlace(null);
    setShowReligiousPlace(null);
    setShowModal(true);
  };
  const handleSubItemClick = (subItem) => {
    setSelectedSubItem((prevSelectedSubItem) => {
        const newSelectedSubItem = prevSelectedSubItem === subItem ? null : subItem;
        setSelectedSubItem(newSelectedSubItem);
        setHistoricalPlaceIndex(0); // Reset historical place index when the user clicks on Historical submenu
        setReligiousPlaceIndex(0); // Reset historical place index when the user clicks on Historical submenu
        setShowHistoricalPlace(newSelectedSubItem === 'historical');
        setShowReligiousPlace(newSelectedSubItem === 'religious');
        return newSelectedSubItem;
    });
    setSelectedItem(null);
};

;

  return (
    <div>
      <Heder />

      <div>{/* <h1></h1> */}</div>
      <div>
        <div class="container-fluid">
          <div class="">
            <div class="row">
              <div class="col-lg-3 pb-lg-3 customBackgroundforDes">
                <div class="position-relative bg-white customBackgroundforDes ">
                  <div class=" ">
                    <div class=" pt-5 customeMenuList">
                      <ul class=" ">

                        <li> <a href="/">Home </a>  </li>
                        <li onClick={() => handleItemClick('about')} data-toggle="modal" data-target=".bd-example-modal-lg">
                          <a href="###">About {destinationData.name} </a>
                        </li>

                        <li onClick={() => handleItemClick('mode of visit')} > 
                          <a href="###">Mode of Visit</a>
                          <ul class="modeOptions" style={{ display: selectedItem === 'mode of visit' ? 'block' : 'none' }}>
                            <li  onClick={() => handleSubItemClick('modeofvisitbycab')} data-toggle="modal" data-target=".bd-example-modal-lg">
                              <a href="###"><i class="fa fa-taxi" aria-hidden="true"></i>By Cab</a>
                            </li>
                            {/* <li onClick={() => handleSubItemClick('modeofvisitbytrain')} data-toggle="modal" data-target=".bd-example-modal-lg">
                              <a href="#"><i class="fa fa-train" aria-hidden="true"></i>By Train</a>
                            </li> */}
                          </ul>
                        </li>

                        <li onClick={() => handleItemClick('climate')} data-toggle="modal" data-target=".bd-example-modal-lg">
                          <a href="###" > Climate  </a>
                        </li>

                        <li onClick={() => handleItemClick('hotel')} data-toggle="modal" data-target=".bd-example-modal-lg">
                          <a href="##">Hotels </a>
                        </li>

                        <li onClick={() => handleItemClick('places of interest')} >
                          <a href="###">Places of Interest</a>
                          <ul class="modeOptions" style={{ display: selectedItem === 'places of interest' ? 'block' : 'none' }}>
                            <li  onClick={() => handleSubItemClick('religious')} data-toggle="modal" data-target=".bd-example-modal-lg">
                              <a href="###">Religious</a>
                            </li>
                            <li onClick={() => handleSubItemClick('historical')} data-toggle="modal" data-target=".bd-example-modal-lg">
                              <a href="###">Historical</a>
                            </li>
                          </ul>
                        </li >

                        <li onClick={() => handleItemClick('food')} data-toggle="modal" data-target=".bd-example-modal-lg">
                          <a href="###"> Food </a>
                        </li>

                      </ul>
                    </div>
                    {showModal && (
                    <div class="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true"
                    data-keyboard="false">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                      <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel" style={{ textTransform: 'uppercase' }}>{selectedItem ? selectedItem : selectedSubItem } </h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      {selectedItem === 'about' && (
                        <ul>
                          <p></p>
                          <p>{destinationData.about1}</p>
                          <p>{destinationData.about2}</p>
                          <p>{destinationData.about3}</p>
                          <p>{destinationData.about4}</p>
                          <p>{destinationData.about5}</p>
                        </ul>
                      )}
                      {selectedSubItem === 'modeofvisitbycab' && (
                        <ul>
                          <p></p>
                          <p>{destinationData.distanceFromSrinagar}</p>
                          <p>{destinationData.arrivalbycab1}</p>
                          <p>{destinationData.arrivalbycab2}</p>
                          <p>{destinationData.arrivalbycab3}</p>
                          <p>{destinationData.arrivalbycab4}</p>

                        </ul>
                      )}
                      {selectedItem === 'climate' && (
                        <ul>
                          <p></p>
                          <p>{destinationData.cimate0}</p>
                          <p>{destinationData.cimate1}</p>
                          <p>{destinationData.cimate2}</p>
                          <p>{destinationData.cimate3}</p>
                          <p>{destinationData.cimate4}</p>
                          <p>{destinationData.cimate5}</p>
                          <p>{destinationData.cimate6}</p>
                          
                        </ul>
                      )}
                      {selectedItem === 'hotel' && (
                        <table class="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Hotel Name</th>
                            <th scope="col">Contact<i class="fa fa-telephone"></i></th>
                            <th scope="col">Get Direction</th>
                            <th scope="col">View</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{destinationData.hotelsName[0]}</th>
                            <td ><a target="blank" href={`tel:${destinationData.hotelsPhone[0]}`}><i class="fa fa-phone" ></i>{destinationData.hotelsPhone[0]}</a></td>
                            <td><a  target="blank" href={`${destinationData.hotelsMapsLinks[0]}`}><i class="fa fa-map-marker" aria-hidden="true"></i>Get Direction</a></td>
                            <td><a href={`${destinationData.hotelsViewLinks[0]}`} target="blank">Visit</a></td>
                          </tr>
                          <tr>
                            <th scope="row">{destinationData.hotelsName[1]}</th>
                            <td ><a target="blank" href={`tel:${destinationData.hotelsPhone[1]}`}><i class="fa fa-phone" ></i>{destinationData.hotelsPhone[1]}</a></td>
                            <td><a  target="blank"  href={`${destinationData.hotelsMapsLinks[1]}`}><i class="fa fa-map-marker" aria-hidden="true"></i>Get Direction</a></td>
                            <td><a href={`${destinationData.hotelsViewLinks[1]}`} target="blank">Visit</a></td>
                          </tr>
                          <tr>
                            <th scope="row">{destinationData.hotelsName[2]}</th>
                            <td ><a target="blank" href={`tel:${destinationData.hotelsPhone[2]}`}><i class="fa fa-phone"></i>{destinationData.hotelsPhone[2]}</a></td>
                            <td><a  target="blank"  href={`${destinationData.hotelsMapsLinks[2]}`}><i class="fa fa-map-marker" aria-hidden="true"></i>Get Direction</a></td>
                            <td><a href={`${destinationData.hotelsViewLinks[2]}`} target="blank">Visit</a></td>
                          </tr>
                          <tr>
                            <th scope="row">{destinationData.hotelsName[3]}</th>
                            <td ><a target="blank" href={`tel:${destinationData.hotelsPhone[3]}`}><i class="fa fa-phone" ></i>{destinationData.hotelsPhone[3]}</a></td>
                            <td><a  target="blank"  href={`${destinationData.hotelsMapsLinks[3]}`}><i class="fa fa-map-marker" aria-hidden="true"></i>Get Direction</a></td>
                            <td><a href={`${destinationData.hotelsViewLinks[3]}`} target="blank">Visit</a></td>
                          </tr>
                          {/* <tr>
                            <th scope="row">{destinationData.hotelsName[4]}</th>
                            <td ><a target="_blank" href={`tel:${destinationData.hotelsPhone[4]}`}><i class="fa fa-phone" ></i>{destinationData.hotelsPhone[4]}</a></td>
                            <td><a  target="_blank" href={`${destinationData.hotelsMapsLinks[4]}`}><i class="fa fa-map-marker" aria-hidden="true"></i>Get Direction</a></td>
                            <td><a href={`${destinationData.hotelsViewLinks[4]}`} target="_blank">Visit</a></td>
                          </tr> */}
                        </tbody>
                      </table>
                      )}
                      {selectedSubItem === 'modeofvisitbytrain' && (
                        <ul>
                          <p></p>
                          <h3>Train service not available in this area</h3>
                          
                        </ul>
                      )}
                      {showHistoricalPlace && (
                        <ul>
                        <div class="row mb-4">
                            <div class="col-12">
                                <p>{destinationData.historicalPlaces[historicalPlaceIndex]}</p>
                                {/* <p>{destinationData.historicalPlaceDescriptions[historicalPlaceIndex]}</p> */}
                                <div style={{ width: '100%', height: '370px', overflow: 'hidden' }}>
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid" src={destinationData.imagesforhistorical && destinationData.imagesforhistorical[historicalPlaceIndex]} alt="historical"  width="700"  height ="300"/>
                            </div>
                            <h6 class="mt-3">Description</h6>
                            <p>{destinationData.historicalPlacesDescription[historicalPlaceIndex]}</p>
                            </div>
                        </div>
                        <div class="text-right"> 
                            <button  type="button" class="btn btn-success"
                                  onClick={() => setHistoricalPlaceIndex((prevIndex) => Math.max(0, prevIndex - 1))}
                                  disabled={historicalPlaceIndex === 0} > Previous </button>
                            <button  type="button" onClick={() => setHistoricalPlaceIndex((prevIndex) => Math.min(destinationData.historicalPlaces.length - 1, prevIndex + 1))}
                                disabled={historicalPlaceIndex === destinationData.historicalPlaces.length - 1}  class="btn btn-success"> Next   </button>
                        </div>
                            </ul>
                        )}

                        {/* Religious Places */}
                      {showReligiousPlace &&  (
                        <ul>
                        <div class="row mb-4">
                            <div class="col-12">
                                <p>{destinationData.religiousPlaces[religiousPlaceIndex]}</p>
                                <div style={{ width: '100%', height: '370px', overflow: 'hidden' }}>
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid" src={destinationData.imagesforreligious && destinationData.imagesforreligious[religiousPlaceIndex]} alt="religious" />
                            </div>
                                <h6>Description</h6>
                                <p>{destinationData.religiousPlacesDescription[religiousPlaceIndex]}</p>
                            </div>
                        </div>
                        <div class="text-right"> 
                            <button  type="button" class="btn btn-success"
                                  onClick={() => setReligiousPlaceIndex((prevIndex) => Math.max(0, prevIndex - 1))}
                                  disabled={religiousPlaceIndex === 0} >  Previous  </button>
                            <button  type="button" onClick={() => setReligiousPlaceIndex((prevIndex) => Math.min(destinationData.religiousPlaces.length - 1, prevIndex + 1))}
                                disabled={religiousPlaceIndex === destinationData.religiousPlaces.length - 1}  class="btn btn-success"> Next   </button>
                        </div>
                            </ul>
                        )}


                        {/* Foods Places */}
                      {selectedItem === 'food' && destinationData.foodsName && destinationData.foodsImages && destinationData.foodsDescription &&(
                        <ul>
                        <div class="row mb-4">
                            <div class="col-6">
                                <p>{destinationData.foodsName[foodsIndex]}</p>
                                <div style={{ width: '100%', height: '200px', overflow: 'hidden' }} class="mb-2">
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid" src={destinationData.foodsImages && destinationData.foodsImages[foodsIndex]} alt="religious" />
                                </div>
                                <h6>Description</h6>
                                <p>{destinationData.foodsDescription[foodsIndex]}</p>
                            </div>
                            <div class="col-6">
                                <p>{destinationData.foodsName[foodsIndex + 1]}</p>
                                <div style={{ width: '100%', height: '200px', overflow: 'hidden' }} class="mb-2">
                                <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid" src={destinationData.foodsImages && destinationData.foodsImages[foodsIndex + 1]} alt="religious" />
                                </div>
                                <h6>Description</h6>
                                <p>{destinationData.foodsDescription[foodsIndex + 1]}</p>
                            </div>
                        </div>
                        <div class="text-right mt-9"> 
                            <button  type="button" class="btn btn-success"
                                  onClick={() => setFoodsIndex((prevIndex) => Math.max(0, prevIndex - 2))}
                                  disabled={foodsIndex === 0} >  Previous  </button>
                            <button  type="button" onClick={() => setFoodsIndex((prevIndex) => Math.min(destinationData.foodsName.length - 2, prevIndex + 2))}
                                disabled={foodsIndex === destinationData.foodsName.length - 2}  class="btn btn-success"> Next   </button>
                        </div>
                            </ul>
                        )}
                      </div>
                    </div>
                    </div>
                    </div>
                  )}
                  </div>
                </div>
              </div>
              <div class="col-lg-9 pb-lg-9">
                <div>
                  <div class=" bg-white p-4 p-lg-5 ">
                    <div>
                    <div class="row ">
                    <div class="col-6">
                      <h6 class="text-primary text-uppercase">
                        {destinationData.description}
                      </h6>
                      </div>
                      <div class="col-6">
                      <h6 class="text-primary text-uppercase text-end">
                      <a target="blank" href={`${destinationData.linkMap}`}>Map</a>
                      </h6>
                      </div>
                      </div>
                      <p> </p>
                      <p> {destinationData.description1}</p>
                      <p>{destinationData.p1}</p>
                      <p>{destinationData.p2}</p>
                      <p>{destinationData.p3}</p>
                    </div>
                    <div class="row mb-4">
                      <div class="col-6">
                      <div style={{ width: '100%', height: '280px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }}  class="img-fluid" src={destinationData.images && destinationData.images[0]} alt="Broken"  />
                      </div>
                      </div>
                      <div class="col-6">
                      <div style={{ width: '100%', height: '280px', overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid" src={ destinationData.images && destinationData.images[1] }alt="Broken"/>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );
};

export default Destination;
