// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/mantai-lake.jpg";
// import baderwah2 from "../../img/lolab2.jfif";
// import religiousImage1 from "../../img/goose.jpg";
import historicalImage1 from "../../img/sheshnag_lake.jpg";
import historicalImage2 from "../../img/aru-valley..jpg";
// import FoodsImage3 from "../../img/morelcurry.jfif";
// import FoodsImage4 from "../../img/Sheer chaie.jfif";


const mantaliaLake = {
    name: "Mantalai Lake",
    
    description: "Mantalai Lake",
    linkMap:"https://www.google.com/maps?q=",
    description1: "Nestled in the heart of the Pir Panjal range, Mantalai Lake stands as a testament to the untouched beauty of the Jammu and Kashmir region. Tucked away from the bustling tourist routes, this pristine lake offers a serene haven for those seeking a communion with nature. Surrounded by towering snow-capped peaks, the lake exudes a sense of tranquility that captivates visitors. The journey to Mantalai is not just a trek but an odyssey through dense forests, meadows adorned with vibrant wildflowers, and surreal landscapes that seem to change with every step.",
    p1: "As you ascend towards Mantalai, the air becomes crisper, the surroundings more enchanting, and the anticipation of  reaching the secluded lake intensifies. The area's remoteness adds to the allure, providing a genuine escape from the noise of modern life. Mantalai is not just a destination; it's a sanctuary for those seeking solace amidst the unspoiled grandeur of the Himalayas.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1,], // Add images array
    distanceFromSrinagar: "",
    about1: " ",
    about2: " ",
    about3: " ",
    about4: " ",
    arrivalbycab1: "Accessible by trekking, the journey to Mantalai Lake is an adventurous experience, taking visitors through picturesque trails, dense forests, and panoramic vistas. Trekking enthusiasts often embark on the trek from Pahalgam, reveling in the natural beauty along the way. ",
    arrivalbycab2: " ",
    arrivalbycab3: " ",
    arrivalbycab4: " ",
    cimate0: "Mantalai Lake experiences a cool alpine climate. ",
    cimate1: "Summers are generally mild, making it an ideal time for trekking and exploration.  ",
    cimate2: " The region may witness snowfall during winters, adding a magical touch to the already breathtaking surroundings.",
    cimate3: " ",
    cimate4: " ",
    headinghotels: "List Of Hotels",
    hotelsName: ["NA","NA", ],
    hotelsPhone: ["NA","NA", ],
    hotelsViewLinks: ["NA","NA", ],
    hotelsMapsLinks: ["NA","NA", ],
    headingfood: "Must try in Mantalai Lake",
    foodsName: ['', ''],
    foodsDescription: [ " Food:*As Mantalai Lake is primarily a trekking destination, visitors often carry packed meals and snacks. It's advisable to bring lightweight, energy-rich food items for sustenance during the trek."," "],
    religiousPlaces: [""],
    religiousPlacesDescription: [""],
    historicalPlaces: ["Sheshnag Lake","Aru Valley:"],
    historicalPlacesDescription: ["A high-altitude glacial lake, Sheshnag is a stunning destination located on the way to Amarnath Cave. The lake, surrounded by snow-capped peaks, adds to the enchantment of the trek to Mantalai.","A picturesque valley surrounded by coniferous trees and meadows, Aru is a starting point for the trek to Mantalai Lake. The journey through Aru Valley introduces trekkers to the mesmerizing beauty of the region."],
    foodsImages:[],
    imagesforreligious: [ ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default mantaliaLake;