// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/tattapani1.jpg";
import baderwah2 from "../../img/tattapani2.jpg";
import religiousImage1 from "../../img/sudh mahadev.jpg";
import religiousImage2 from "../../img/kailash-kund.jpeg";
import historicalImage1 from "../../img/pancheri.jpg";
import FoodsImage3 from "../../img/Rajma_Chawal.jpg";
import FoodsImage4 from "../../img/kheer.jpg";


const tattaPani = {
    name: "Tatta Pani",
    
    description: "Tatta Pani",
    linkMap:"https://www.google.com/maps?q=Tatapani, 185202",
    description1: "Tatapani, located 6 km away from Sangaldan in the Gool sub-division of Ramban District, is renowned for its sulphur springs, which are believed to possess miraculous healing powers. This  picturesque destination is situated approximately 40 km from the town of Ramban. The sulphur springs at Tatapani attract thousands of visitors each year, with a significant influx occurring  from June to the end of November.",
    p1: "The therapeutic properties of the spring water draw people from both within and outside the state, especially from neighboring Punjab. The primary purpose of their visit is to immerse themselves in the revered springs, seeking relief from ailments such as dermatitis and arthritis.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The distance from Jammu to Tata Pani is approximately 260 kilometers (about 162 miles) by road. The journey takes around 7 to 9 hours by car, depending on road conditions and traffic. The distance from Srinagar to Tata Pani is approximately 150 kilometers (about 93 miles) by road. The journey takes around 5 to 6 hours by car, depending on road conditions and traffic.",
    about1: "The belief in the healing powers of Tatapani&#39;s sulphur springs has been passed down through generations, making it a popular pilgrimage site for those seeking both physical and spiritual  well-being. The water is said to have rejuvenating effects, and the serene surroundings add to the overall experience, creating a tranquil environment for visitors. ",
    about2: "The pilgrimage season witnesses a vibrant atmosphere as devotees and tourists alike gather to partake in the ritualistic practice of bathing in the sulphur springs. The peak season, from June to November, showcases the cultural diversity of visitors, with people from various backgrounds and regions coming together to share in the therapeutic benefits of Tatapani. ",
    about3: "In addition to its hot springs, Tata Pani is surrounded by lush green valleys, cascading waterfalls, and snow-capped peaks, creating a picturesque setting that captivates the senses. Whether you're seeking relaxation, adventure, or cultural immersion, Tata Pani has something for everyone. ",
    about4: "Nearby Spots: Ghora gali, DagganTop, Sangaldan, Jabber Sar, Ramakunda, Hajam Marg etc. ",
    arrivalbycab1: "For travelers arriving by cab to Tata Pani, they can consider the following options: ",
    arrivalbycab2: "Pre-booked Cab: Travelers can arrange for a pre-booked cab service from either Jammu or Srinagar through local travel agencies, tour operators, or online platforms. This allows them to secure transportation in advance and ensure that a cab will be available to pick them up at their desired location and time. ",
    arrivalbycab3: "Taxi Service: Taxis are readily available in both Jammu and Srinagar, and travelers can hire one directly from taxi stands or through taxi services operating in the cities. It's advisable to negotiate the fare with the driver beforehand and confirm that they are aware of the destination in Tata Pani.",
    arrivalbycab4: "App-based Cab Services: Some app-based cab services might operate in Jammu and Srinagar. Travelers can use these services to book a cab for their journey to Tata Pani. It's essential to ensure that the service covers the route to Tata Pani and that the driver is willing to take them there. Local Transport: Alternatively, travelers can explore local transport options such as buses or shared taxis from nearby towns or cities to reach Tata Pani. However, these options may be less flexible and may require multiple transfers.",
    cimate0: "Summer (June to August): Summers in Tata Pani are generally mild and pleasant, with temperatures ranging from around 15°C to 25°C (59°F to 77°F). This is the ideal time for outdoor activities such as trekking and sightseeing. The weather is usually sunny, but occasional rainfall can occur. ",
    cimate1: "Monsoon (July to September): Tata Pani experiences monsoon rainfall during these months, particularly in July and August. The rainfall contributes to the lush greenery and vegetation in the region. However, heavy rainfall may sometimes lead to road closures or disruptions in outdoor activities.  ",
    cimate2: "Autumn (September to November): Autumn is a beautiful time to visit Tata Pani when the temperatures start to cool down, ranging from 10°C to 20°C (50°F to 68°F). The foliage may change colors, creating picturesque landscapes that are perfect for photography and hiking. ",
    cimate3: "Winter (December to February): Winters in Tata Pani are cold and snowy, with temperatures dropping below freezing point. The region receives heavy snowfall during these months, transforming it into a winter wonderland. Winter sports enthusiasts can enjoy activities like skiing and snowboarding. ",
    cimate4: "Spring (March to May): Spring is a transitional season in Tata Pani, with temperatures gradually warming up and the snow melting away. The valley comes alive with blooming flowers and lush vegetation, making it a picturesque time for nature walks and picnics. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Kuldeep Home Stay ","Ramban Regency Hotel &amp; Restaurant", ],
    hotelsPhone: ["0124 620 1520","NA", ],
    hotelsViewLinks: ["NA", "NA", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=OYO Kuldeep Home Stay, Chuchatter, Rajgarh, Ramban, Jammu, Jammu and Kashmir 182144","https://www.google.com/maps?q=Ramban Regency Hotel & Restaurant, 66RM+HXG, National Highway 1A, Sunari Mohalla, Kawbagh, Jammu and Kashmir 182144"],
    headingfood: "Must try in Tatta Pani",
    foodsName: ['Rajma Chawal', 'Kheer'],
    foodsDescription: [ "A popular dish made with red kidney beans cooked in a gravy of tomatoes, onions, and spices, served with steamed rice. "," A creamy rice pudding made with rice, milk, sugar, and flavored with cardamom and nuts."],
    religiousPlaces: ["Sudh Mahadev Temple","Kailash Kund"],
    religiousPlacesDescription: ["Located around 20 kilometers from Tata Pani, Sudh Mahadev Temple is a revered Hindu shrine dedicated to Lord Shiva. The temple complex includes a natural spring known as Pap Nashni Bowli.","It is a natural spring located close to Sudh Mahadev Temple, known for its religious significance and scenic beauty. The kund is believed to have been created by Lord Shiva's trident."],
    historicalPlaces: ["Pancheri"],
    historicalPlacesDescription: ["A small town situated around 15 kilometers from Tata Pani, Pancheri is known for its picturesque landscapes, lush greenery, and serene ambiance. It's an ideal destination for a day trip or a short getaway."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default tattaPani;