import React, { useState } from 'react';
import "./gallery.css";
import a1 from "../img/kailash-kund.jpeg";
import  b1 from "../img/laldraman.jpg";
import c1 from "../img/shanklpal.jpg";
import d1 from "../img/mac.jpg";
import a2 from "../img/kailash-2.jpg";
import b2 from "../img/laldraman2.jpg";
import c2 from "../img/shankpal2.webp";
import d2 from "../img/Macha2.jpg";

import Heder from "../Dashboard/custom-header";
import Footer from "../Dashboard/footer";

const Gallery = ({ destination, pictures }) => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const openBlogModal = (slide) => {
    const relatedBlogs = blogItems.find((blog) => blog.slide === slide);
    setSelectedBlog(relatedBlogs);
  };

  const closeBlogModal = () => {
    setSelectedBlog(null);
  };
  const blogItems = [
    { title: "KAILASH KUND",
    //  content: "It is famous place of pilgrimage and the Kund is a big lake of cold crystal Clearwater having circumference of 1.5 miles. Kailash Kund also Known as Vasuki kund, is abode of Nagraj Vasuki and is situated at a height of 14500ft. above sea level. Every year thousands of pilgrims pay Darshan, which commences on the 14th day after Shravan Purnima. The yatra starts from Gatha bhaderwah .",
     image: a1 ,slide: 1
    },
  
    { title: "LAL DRAMAN",
    //  content: "It is a beautiful spot 16 km North-West of Doda town. Amidst lofty and fir trees near village Sazan. It has a lot of tourism significance and offers adventure tourism.",
     image: b1 ,slide: 1
    },
    { title: "SANKPAL",
    //  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
     image: c1,slide: 1
    },
    { title: "MACHAIL",
    // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    image: d1, slide: 1
   },
   { title: "KAILASH KUND",
   content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
   image: a2, slide: 2
  },
  { title: "LAL DRAMAN",
  // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
  image: b2, slide: 2
 },
 { title: "SANKPA",
    // content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
    image: c2,slide: 2
   },
   { title: "MACHAIL",
  //  content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.",
   image: d2, slide: 2
  },
 
    
  ];

  return (

    <div>
       <Heder />
        
<div class="container">
    <div class="section-header">
      <h class="head">GALLERY</h>
      {/* <p class="para">Always upto date with our latest News and Articles</p> */}
    </div>
    <div class="blog-content">
      <div class="row">
         {/* Map over your blog items */}
         {blogItems.map((blog, index) => (
        <div class="col-md-3 col-sm-6" key={index}>
          <div className="single-blog-item" onClick={() => openBlogModal(blog.slide)}>
            <div class="single-blog-item-img"><img src={blog.image} alt=""/></div>
            <div class="single-blog-item-txt">
              <h2><a href="#!">{blog.title}</a></h2>
              {/* <h4>posted <span>by</span> <a href="#!">admin</a> march 2045</h4> */}
              {/* <p>{blog.content}</p> */}
            </div>
          </div>
        </div>
         ))}
       
      </div>
    </div>
    {/* Modal for displaying detailed blog content */}
    {selectedBlog && (
        <div className="blog-modal-overlay" onClick={closeBlogModal}>
          <div className="blog-modal-content">
            <h2>{selectedBlog.title}</h2>
            <img src={selectedBlog.image} alt="Blog"/>
            <p>{selectedBlog.content}</p>
            <button onClick={closeBlogModal}>Close</button>
          </div>
        </div>
      )}
 




      {/* <h2>{destination} Gallery</h2> */}
      {/* <div className="image-container"> */}
      
        {/* {pictures.map((picture, index) => ( */}
          {/* <img key={index} src={picture} alt={`Destination ${index + 1}`} /> */}
        {/* ))} */}
      </div>
      <Footer/>
    </div>
  );
};
export default Gallery;