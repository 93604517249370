import React, { useEffect, useState } from "react";
import Heder from "../Dashboard/custom-header";
import Footer from "../Dashboard/footer";
import "../Destination/destination.css";
import './explore.css'
import KailashKund from '../img/kailashkund.jpg'
import LalDraman from '../img/laldraman.jpg'
import Sankpal from '../img/shanklpal.jpg'
import Machail from '../img/mac.jpg';
import uri from '../img/urimain.jpg';
import Baderwah from '../img/baderwah1.jpg';
import GathaLake from '../img/gatha-lake.webp';
import jantronDhar from '../img/jantrondhar2.jpg';
import siyad1 from "../img/siyad-baba1.jpg";
import sanasar from "../img/sanasar-tourism.jpg";
import panjnara from "../img/PANJNARA.jpg";
import Apharwat  from "../img/apharwat-peak.jpg";
import nilnag from "../img/nilnag-lake.jpg";
import bangus from"../img/bangus5.webp";
import tattapani from "../img/tattapani.jpg";
import tarserlake from "../img/tarser-lake1.jpg";
import Mantalialake from"../img/mantai-lake.jpg";
import astanmarg from "../img/astanmarg2.jpg";
import baderwahfort from '../img/baderwahfort2.jpg';
import sheeshmahal from "../img/sheeshmahal1.jpg";
import warwan from "../img/warwan2.avif";
import padri from "../img/padri-pass2.jpg";
import patnitop from "../img/patnitop.webp";
import shalimarbag from "../img/shalimar-bagh-srinagar1.jpg";
import parimahal from "../img/Pari-Mahal-Front-View.jpg";
import ramnagar from "../img/ramnagar1.jpg";
import batote from "../img/batote1.jpg";
import chakkan from "../img/chakkan2.jpg";
import tangdar from "../img/tangdar1.jpg";
import pallanwala from "../img/pallanwala1.jpg";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";



function Explored() {
    const { location } = useParams();
   
    const [selectedOption, setSelectedOption] = useState(null);
   
    useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const locationParam = queryParams.get("location");

    if (locationParam === "ganderbal") {
        setSelectedOption("ganderbal");
    //   setDestinationData(ganderbalData);
    } else if (locationParam === "srinagar") {
        setSelectedOption("srinagar");
    //   setDestinationData(srinagarData);
    } 
    else if (locationParam === "guraz") {
        setSelectedOption("guraz");
    //   setDestinationData(gurazData);
    }
    else if (locationParam === "baderwah") {
        setSelectedOption("baderwah");
    //   setDestinationData(bedarwahData);
    }
    else if (locationParam === "laldraman") {
        setSelectedOption("laldraman");
    //   setDestinationData(bedarwahData);
    }
    else if (locationParam === "bangusvalley") {
        setSelectedOption("bangusvalley");
    //   setDestinationData(bangusValleyData);
    }
    else if (locationParam === "daksumvalley") {
        setSelectedOption("daksumvalley");
    //   setDestinationData(daksum);
    }
    else if (locationParam === "akhnoor") {
        setSelectedOption("akhnoor");
    //   setDestinationData(akhnoorData);
    }
    else if (locationParam === "lolabvalley") {
        setSelectedOption("lolabvalley");
    //   setDestinationData(lolabData);
    }
    else if (locationParam === "amarpalace") {
        setSelectedOption("amarpalace");
    //   setDestinationData(amarMahalPalace);
    }
    // else if (locationParam === "gathalake") {
        // setSelectedOption("gathalake");
    //   setDestinationData(amarMahalPalace);
    // }
    else if (locationParam === "uri") {
        setSelectedOption("uri");
    //   setDestinationData(amarMahalPalace);
    }
    else if (locationParam === "tangdhar") {
        setSelectedOption("tangdhar");
    //   setDestinationData(amarMahalPalace);
    }
    else if (locationParam === "pallanwalasector") {
        setSelectedOption("pallanwalasector");
    //   setDestinationData(amarMahalPalace);
    }
  }, [location]);

  return (
    <div>
      <Heder />

      <div>

      <div class="container-fluid py-5 backgroundImgForDestination">
    
    <div class="container pb-3">
        <div class="text-center mb-3 pb-3">
            {/* <h6 class="text-primary text-uppercase letter-spacing-5px" >Destination</h6> */}
            <h1>EXPLORE THE UNEXPLORED</h1>
        </div>
        <div class="row">
        {selectedOption === "guraz" && (
            <>
        <div class="col-lg-1 col-md-2 mb-2 "></div>
        <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border " src={KailashKund} alt="Kailashkund"/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=guraz'  rel="noopener noreferrer">
                        <h5 class="text-white">Kailash Kund</h5>
                        {/* <span>The Land of Dards</span> */}
                    {/* </a> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={LalDraman} alt="Baderwah"/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=laldraman'  rel="noopener noreferrer">
                        <h5 class="text-white">Lal Draman</h5>
                        {/* <span>Mini Kashmir</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>
       
          
        
            </>
         )}
            </div>
            <div class="row">
            {selectedOption === "guraz" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Sankpal} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=akhnoor'  rel="noopener noreferrer">
                        <h5 class="text-white">Sankpal</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Machail} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=ganderbal'  rel="noopener noreferrer">
                        <h5 class="text-white">Machail</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
           
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
           
           </div>
            </>
            )}     
        </div> 
            
        <div class="row">
        {selectedOption === "srinagar" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Baderwah} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=baderwah'  rel="noopener noreferrer">
                        <h5 class="text-white">Baderwah</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={GathaLake} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=gathalake'  rel="noopener noreferrer">
                        <h5 class="text-white">Gatha Lake</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
        )}
          </div> 
          <div class="row">
          {selectedOption === "srinagar" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={jantronDhar} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=jantrondhar'  rel="noopener noreferrer">
                        <h5 class="text-white">Jantron Dhar</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={siyad1} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=syedbaba'  rel="noopener noreferrer">
                        <h5 class="text-white">Siyed Baba Waterfall</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
         </>
          )}
         </div>
        <div class="row">
        {selectedOption === "bangusvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={sanasar} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=sanasar'  rel="noopener noreferrer">
                        <h5 class="text-white">Sanasar</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={panjnara} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=panjnara'  rel="noopener noreferrer">
                        <h5 class="text-white">Panjnara</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
             </>
        )}
            </div>
           <div class="row">
           {selectedOption === "bangusvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Apharwat} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=apharwat'  rel="noopener noreferrer">
                        <h5 class="text-white">Apharwat Peak</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={nilnag} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=nilnag'  rel="noopener noreferrer">
                        <h5 class="text-white">Nilnag Lake</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
           )}
             </div>
           <div class="row">
           {selectedOption === "daksumvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={chakkan} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=chakkan'  rel="noopener noreferrer">
                        <h5 class="text-white">Chakan Da Bagh</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={uri} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=uri'  rel="noopener noreferrer">
                        <h5 class="text-white">Uri</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
           )}
</div>
<div class="row">
{selectedOption === "daksumvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={tangdar} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=tangdhar'  rel="noopener noreferrer">
                        <h5 class="text-white">Tangdhar</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={pallanwala} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=pallanwalasector'  rel="noopener noreferrer">
                        <h5 class="text-white">Pallanwala Sector</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "lolabvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={tattapani} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=tattapani'  rel="noopener noreferrer">
                        <h5 class="text-white">Tatta Panni</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Mantalialake} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=mantalialake'  rel="noopener noreferrer">
                        <h5 class="text-white">Mantalia Lake</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "lolabvalley" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={tarserlake} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=tarsarlake'  rel="noopener noreferrer">
                        <h5 class="text-white">Tarser Lake</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={bangus} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=bangusvalley'  rel="noopener noreferrer">
                        <h5 class="text-white">Bangus Valley</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "akhnoor" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={astanmarg} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=astanmarg'  rel="noopener noreferrer">
                        <h5 class="text-white">Astanmarg</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={warwan} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=warwan'  rel="noopener noreferrer">
                        <h5 class="text-white">Warwan Valley</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "akhnoor" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={padri} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=padri'  rel="noopener noreferrer">
                        <h5 class="text-white">Padri</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={patnitop} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=patnitop'  rel="noopener noreferrer">
                        <h5 class="text-white">Patnitop</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "amarpalace" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={baderwahfort} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=baderwahfort'  rel="noopener noreferrer">
                        <h5 class="text-white">Baderwah Fort</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={sheeshmahal} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=seeshmahal'  rel="noopener noreferrer">
                        <h5 class="text-white">Sheesh Mahal</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "amarpalace" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={shalimarbag} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=shalimar'  rel="noopener noreferrer">
                        <h5 class="text-white">Shalimar Bagh</h5>
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={parimahal} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=parimahal'  rel="noopener noreferrer">
                        <h5 class="text-white">Pari Mahal</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
<div class="row">
{selectedOption === "ganderbal" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={ramnagar} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=ramnagar'  rel="noopener noreferrer">
                        <h5 class="text-white">Ramnagar</h5>
                        {/* <span>Jammu's Riverside Gem</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={batote} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=batote'  rel="noopener noreferrer">
                        <h5 class="text-white">Batote</h5>
                        {/* <span>Mesmerizing Natural Wonder</span> */}
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>     
</>
)}
</div>
{/* <div class="row">
{selectedOption === "ganderbal" && (
            <>
            <div class="col-lg-1 col-md-2 mb-2 "></div>
            <div class="col-lg-5 col-md-6 mb-4  ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Sankpal} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=akhnoor'  rel="noopener noreferrer">
                        <h5 class="text-white">Site27</h5>
                        
                    </Link>
                </div>
            </div> */}
            {/* <div class="col-lg-5 col-md-6 mb-4 ">
                <div class="destination-item position-relative overflow-hidden mb-2">
                <div style={{ width: '100%', height: '236px', overflow: 'hidden' }}>
                    <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluids border" src={Machail} alt=""/>
                    </div>
                    <Link className="destination-overlay text-white text-decoration-none "  to='/destination?location=amarpalace'  rel="noopener noreferrer">
                        <h5 class="text-white">Site28</h5>
                    </Link>
                </div>
            </div>
            <div class="col-lg-2 col-md-2 mb-2 "></div>      */}
{/* </> */}
{/* )} */}
{/* </div> */}

    </div>
</div>
        </div>

      <Footer />
    </div>

  );
};

export default Explored;
