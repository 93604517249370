// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/pallanwala1.jpg";
import baderwah2 from "../../img/pallanwala2.jpg";
import religiousImage1 from "../../img/palanwalafort.webp";
import historicalImage1  from "../../img/memorial.jpg";
import FoodsImage3 from "../../img/Sheer chaie.jfif";
import FoodsImage4 from "../../img/gushtaba.jpg";

const pallanwalasectorData= {
    name: "Pallanwala Sector",
    description: "Pallanwala Sector",
    linkMap: "https://www.google.com/maps?q=Pallanwala+Sector,+Jammu+and+Kashmir",
    description1: "Pallanwala Sector, located near the Line of Control in the Jammu region, plays a significant role in the defense and surveillance operations in the area. This sector is also known for its scenic beauty and historical significance, attracting visitors interested in the rich cultural heritage of Jammu and Kashmir.",
    p1: "Pallanwala Sector is notable for its strategic importance along the Line of Control. The area is a testament to the bravery and sacrifices of the armed forces and offers insights into the region's geopolitical dynamics.",
    distanceFromSrinagar: "The distance between Srinagar and the Pallanwala Sector can vary depending on the route taken. Typically, it is approximately 300 kilometers, with a journey time of around 7 to 8 hours by road.",
    about1: "The Pallanwala Sector is crucial for its strategic location and its role in the defense mechanism of the region. It is a symbol of resilience and vigilance, standing guard over the nation's borders.",
    about2: "Aside from its strategic importance, the Pallanwala Sector is surrounded by picturesque landscapes, making it a potential spot for tourism that offers a blend of natural beauty and historical exploration.",
    about3: "The security presence is significant in the Pallanwala Sector, with both military and paramilitary forces deployed to maintain peace and security. Visitors may witness the routines and drills of the armed forces, offering a unique glimpse into their lives.",
    about4: "The sector's proximity to the Line of Control makes it a place of great interest for those keen on understanding the complexities of the region's history and the ongoing efforts to maintain peace and stability.",
    arrivalbycab1: "Reaching Pallanwala Sector by road is the most feasible option for visitors. The area is accessible through various routes from Jammu, with local transportation options available for the final leg of the journey.",
    arrivalbycab2: "While the sector is accessible to the public, it's crucial to stay informed about the current security situation and obtain necessary permissions if required, due to its sensitive location.",
    cimate0: "The climate in Pallanwala Sector is similar to other parts of the Jammu region, with distinct seasons offering varied experiences throughout the year.",
    cimate1: "Summer in Pallanwala Sector is warm, with temperatures ranging from 20°C to 35°C, making it a good time for sightseeing and exploration.",
    cimate2: "The monsoon season brings rainfall, transforming the landscape into a lush green setting, ideal for nature enthusiasts.",
    cimate3: "Autumn sees mild weather, perfect for outdoor activities and visiting historical sites in and around the sector.",
    cimate4: "Winters can be cold, with temperatures dropping to near freezing at night. The season offers a different perspective of the sector's beauty, especially if snowfall occurs.",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Amar Palace "],
    hotelsPhone: ["+91-94198 29057"],
    hotelsViewLinks: ["https://hotel-amar-palace-top-5-hotelsluxury-hotels-near.business.site/?utm_source=gmb&utm_medium=referral"],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Amar Palace Pallanwala Sector"],
    headingfood: "Must try in URI",
    foodsName: ['Noon Chai', 'Gushtaba'],
    foodsDescription: ["This traditional Kashmiri tea, brewed with green tea, milk, salt, and spices, is a must-try for a warm and comforting beverage.", "Gushtaba is a traditional Kashmiri delicacy made from minced mutton balls cooked in a creamy yogurt sauce, flavored with spices."],
    religiousPlaces: ["Pallanwala Fort"],
    religiousPlacesDescription: ["Explore the historical Pallanwala Fort, which has played a role in the region's military history."],
    historicalPlaces: ["Pallanwala War Memorial"],
    historicalPlacesDescription: ["This memorial commemorates the sacrifices made by soldiers in the Pallanwala Sector during the Indo-Pak wars, offering a place for reflection and remembrance."],
    images: [baderwah1, baderwah2],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default pallanwalasectorData;