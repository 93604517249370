// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/tarser-lake1.jpg";
import baderwah2 from "../../img/tarser-lake2.jpg";
import religiousImage1 from "../../img/Mamleshwar-temple.jpg";
import historicalImage1 from "../../img/sheshnag_lake.jpg";
import historicalImage2 from "../../img/lidder-river.jpg";
import FoodsImage3 from "../../img/rice.jpg";
import FoodsImage4 from "../../img/tandoori.jpg";


const tarsarLake = {
    name: "Tarsar Lake",
    
    description: "Tarsar Lake",
    linkMap:"https://www.google.com/maps?q=Tarsar Lake, 191202",
    description1: "Situated like a jewel in the crown of the Aru Valley, Tarsar Lake unveils itself as a hidden marvel, surrounded by majestic snow-laden peaks and lush alpine meadows. This ethereal lake, cradled in the lap of nature, emanates an otherworldly beauty that beckons travelers seeking an offbeat adventure.The best time to visit Tarsar Lake is during the summer months, from June to September, when the weather is pleasant, and the trekking trails are accessible. The lake remains frozen during the winter months, making it inaccessible for trekking.",
    p1: "Tarsar is more than a lake; it's a reflection of Kashmir's unexplored wonders. The journey to Tarsar unfolds a narrative of untamed beauty, with the trek starting from the enchanting Aru Valley. Each step on the trail brings you closer to an alpine paradise, with expansive meadows and dense pine forests framing the path. As you traverse this route, the serenity of Tarsar Lake becomes a magnetic force, drawing you into a realm where the silence is interrupted only by the whispers of the wind and the occasional song of birds. This hidden gem offers a rare opportunity to reconnect with nature in its purest form.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The distance from Srinagar to Tarsar Lake in the Aru Valley region of Jammu and Kashmir is approximately 70 kilometers (about 43 miles) by road. However, it's important to note that reaching Tarsar Lake usually involves a trekking journey rather than a direct road route. he trek to Tarsar Lake typically begins from the picturesque Aru Valley, which is accessible from Srinagar by road. The exact trekking distance can vary depending on the starting point within the Aru Valley and the specific route taken. Trekkers usually take multiple days to complete the trek, with overnight camping at various campsites along the way.",
    about1: "Tarsar Lake, also known as Tar Sar, is a breathtaking alpine lake nestled in the Kashmir Valley of Jammu and Kashmir, India.  ",
    about2: "Location: Tarsar Lake is located in the Aru Valley region of the Anantnag district in Jammu and Kashmir. It lies at an altitude of approximately 3,962 meters (12,999 feet) above sea level. ",
    about3: "Scenic Beauty: Surrounded by majestic snow-capped peaks, lush green meadows, and alpine forests, Tarsar Lake is renowned for its stunning natural beauty. The crystal-clear waters of the lake reflect the surrounding landscape, creating a picturesque setting. ",
    about4: "Trekking Destination: Tarsar Lake is a popular trekking destination, attracting adventure enthusiasts and nature lovers from around the world. The trek to Tarsar Lake is typically a multi-day trek that begins from the picturesque Aru Valley and passes through scenic meadows, dense forests, and alpine terrain. ",
    arrivalbycab1: "Arriving at Tarsar Lake by cab involves a journey from Srinagar to the starting point of the trek, typically the Aru Valley.  ",
    arrivalbycab2: "Pre-booked Cab: You can arrange for a pre-booked cab service from Srinagar through local travel agencies, tour operators, or online platforms. This allows you to secure transportation in advance and ensures that a cab will be available to pick you up at your desired location and time. ",
    arrivalbycab3: "Shared Cabs: Alternatively, you can inquire about shared cab services or local transportation options from Srinagar to Pahalgam, which is the nearest town to the Aru Valley. From Pahalgam, you can arrange for further transportation to the starting point of the trek. ",
    arrivalbycab4: "Additionally, be prepared for unpredictable weather conditions, especially during monsoon season or winter months, as the trek to Tarsar Lake may involve crossing rugged terrain and varying altitudes. ",
    cimate0: "Summer (June to August): Summers in the Aru Valley are mild and pleasant, with daytime temperatures ranging from 10°C to 25°C (50°F to 77°F). This is considered the best time to visit Tarsar Lake, as the weather is comfortable for trekking and camping. The days are long, and the region experiences relatively low precipitation during this season. ",
    cimate1: "Monsoon (July to September): The monsoon season in the Aru Valley brings occasional rainfall, particularly in July and August. While the rainfall may cause temporary disruptions to outdoor activities, it also contributes to the lush greenery of the landscape. Trekkers should be prepared for muddy trails and slippery conditions during this time.  ",
    cimate2: "Autumn (September to November): Autumn is a beautiful time to visit Tarsar Lake, as the temperatures start to cool down, and the valley is adorned with vibrant hues of red, orange, and gold as the leaves change color. Daytime temperatures range from 5°C to 20°C (41°F to 68°F), making it ideal for trekking and photography. ",
    cimate3: "Winter (December to February): Winters in the Aru Valley are cold and snowy, with temperatures dropping below freezing point. The region receives heavy snowfall during these months, and Tarsar Lake may be frozen over. Winter trekking is not recommended for inexperienced trekkers, as the trails can be challenging and the conditions harsh. ",
    cimate4: "Spring (March to May): Spring is a transitional season in the Aru Valley, with temperatures gradually warming up and the snow starting to melt. The valley comes alive with the blooming of wildflowers, making it a beautiful time for nature walks and birdwatching. Daytime temperatures range from 5°C to 20°C (41°F to 68°F). ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Highland Resorts ","Badar Resort and Spa", "Al-Ayaan Guest House","Sabr Resorts"],
    hotelsPhone: ["+91-9419078144","094194 09409", "097970 75031","+91-6006459139"],
    hotelsViewLinks: ["https://www.lolabvalley.com/", "https://badarresorts.com/?sa=X&ved=0CPgCEMiuA2oXChMIkKXY0eqqhAMVAAAAAB0AAAAAECw","https://al-ayaan-guest-house.business.site/","https://sabrresorts.com/" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Highland Resorts, Bridge, across Right Side of Club, Pahalgam, Laripora, Jammu and Kashmir 192126","https://www.google.com/maps?q=Badar Resort and Spa, Gagangir, Sonamarg, Jammu and Kashmir 191202","https://www.google.com/maps?q=Al-Ayaan Guest House, Chandanwari Road Laripora Pahalgam near new bus stand Laripora, Pahalgam, Jammu and Kashmir 192126","https://www.google.com/maps?q=Sabr Resorts, Main, Chandanwari Rd, Pahalgam, Jammu and Kashmir 192126"],
    headingfood: "Must try in Tarser Lake",
    foodsName: ["Rice with Wazwan", 'Tandoori Chicken'],
    foodsDescription: [ "Rice is a staple food in Kashmiri cuisine and is often served with various meat and vegetable dishes. Fragrant long-grain rice, often flavored with saffron, is particularly popular. ","Tandoori Chicken is a classic dish made by marinating chicken pieces in a mixture of yogurt and spices, including cumin, coriander, turmeric, and chili powder. The marinated chicken is then skewered and cooked in the tandoor until tender and charred on the outside."],
    religiousPlaces: ["Mamleshwar Temple"],
    religiousPlacesDescription: ["Mamleshwar Temple is a significant Hindu shrine located in Pahalgam, Jammu and Kashmir, India. The temple is dedicated to Lord Shiva, one of the principal deities in Hinduism. It holds immense religious importance for devotees, especially those who undertake the Amarnath Yatra pilgrimage, as Pahalgam serves as one of the base camps for the journey."],
    historicalPlaces: ["Sheeshnag","Lidder River"],
    historicalPlacesDescription: ["An iconic glacial lake on the trekking route, Sheeshnag is known for its crystal- clear waters and serves as a captivating spot during the journey to Tarsar Lake.","Flowing through the Aru Valley, the Lidder River is a serene watercourse that adds to the charm of the region. Trekkers often cross the Lidder on their way to Tarsar Lake."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default tarsarLake;