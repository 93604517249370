import './App.css';
import Dashboard from './Dashboard/dashboard';
import Destination from './Destination/destination';
import Explored from './Explored/explored';
import Gallery from './Gallery/gallery';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/destination" element={<Destination />} />
      <Route path="/explored" element={<Explored />} />
      <Route path="/gallery" element={<Gallery />}/>
    </Routes>
  </Router>
  
  );
}

export default App;
