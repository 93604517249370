import image from "../../img/habbakhatoon.jpg";
import srinagarBackground from "../../img/srinagar-background.jpg";


const srinagarData = {
    name: "Srinagar",
    area: "Area 76440 km²",
    temp: "3²",
    population: "340000",
    capital: "Srinagar",
    language: "Urdu , English",
    description: "Srinagar",
    linkMap:"https://www.google.com/maps?q=Daksum, Forest Block 192202",
    p1: "Sapibulumnibh phasellus nulla egestibulum enim pretium elit tincidunt estiquam ultrisque donectetur. Sedcondimentumsan odio hendrerit proin vitae dignis nibh ac justo id congue. Amesintesque vel curabitae volutpat donec alique nasceleifendimentesque montesque rhoncus quis eros. Vestnunc nonummy",
    p2: "Sapibulumnibh phasellus nulla egestibulum enim pretium elit tincidunt estiquam ultrisque donectetur. Sedcondimentumsan odio hendrerit proin vitae dignis nibh ac justo id congue. Amesintesque vel curabitae volutpat donec alique nasceleifendimentesque montesque rhoncus quis eros. Vestnunc nonummy",
    p3: "Sapibulumnibh phasellus nulla egestibulum enim pretium elit tincidunt estiquam ultrisque donectetur. Sedcondimentumsan odio hendrerit proin vitae dignis nibh ac justo id congue. Amesintesque vel curabitae volutpat donec alique nasceleifendimentesque montesque rhoncus quis eros. Vestnunc nonummy",
    backgroundImage: `url(${srinagarBackground})`,
    images: [image, image, image],
    about1:"Srinagar is the largest city and the summer capital of the Indian state of Jammu and Kashmir.",
    about2:"It is situated in the Kashmir Valley and is known for its picturesque landscapes, including the famous Dal Lake.",
    about3:"Dal Lake is a major attraction, known for its pristine beauty and houseboats.",
    about4:"Srinagar is renowned for its Mughal gardens, such as Shalimar Bagh, Nishat Bagh, and Chashme Shahi. These gardens showcase exquisite Mughal architecture and well-maintained landscapes.",
    about5:"Hazratbal Shrine is a revered Muslim shrine located on the northern shores of Dal Lake. It is known for its pristine white marble architecture and houses a relic believed to be the hair of Prophet Muhammad.",
    arrivalbycab1:"Sheikh ul-Alam International Airport for air travel or Srinagar Railway Station for train arrivals.",
    arrivalbycab2:"Consider pre-booking a cab online for a seamless experience.",
    arrivalbycab3:"Head to the official taxi stand at the airport or railway station.",
    arrivalbycab4:"Discuss and agree on the fare beforehand, ensuring transparency.",
    arrivalbycab5:"Ask about the planned route and estimated travel time.",
    arrivalbycab6:"Exchange contact information with the driver for future needs.",
    arrivalbytrain:"Service Not Available yet",
    cimate0:"Temprature	2 C",
    cimate1:"Rainfall	93.2 cm",
    cimate2:"Summer temperatures typically range from [X°C to Y°C], while winter temperatures can drop to [Z°C].",
    cimate3:"Situated in the Kashmir Valley, Srinagar experiences diverse climate patterns.",
    cimate4:"Srinagar witnesses four distinct seasons: spring, summer, autumn, and winter.",
    cimate5:"Spring brings mild temperatures, ranging from comfortable to slightly warm.",
    cimate6:"Srinagar receives minimal rainfall during the monsoon season, primarily from June to October.",
    headinghotels:"List Of Hotels",
    hotels1:"The Lalit Grand Palace Srinagar",
    hotels2:"Vivanta Dal View (formerly known as Taj Dal View)",
    hotels3:"The Khyber Himalayan Resort & Spa",
    hotels4:"Jamal Resorts",
    hotels5:"Vintage Srinagar",
    headingfood:"Must Try in Srinagar",
    food1:"Rogan Josh",
    food2:"Yakhni",
    food3:"Wazwan",
    food4:"Dum Aloo",
    food5:"Kehwa",
    imagesforreligious: [image, image, image],
    imagesforhistorical: [image, image, image],
  };
  
  export default srinagarData;