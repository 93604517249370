// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/Lolab-Gateway.webp";
import baderwah2 from "../../img/lolab2.jfif";
import religiousImage1 from "../../img/goose.jpg";
import historicalImage1 from "../../img/lalpora.jpg";
import FoodsImage3 from "../../img/morelcurry.jfif";
import FoodsImage4 from "../../img/Sheer chaie.jfif";


const lolabValleyData = {
    name: "Gatha Lake",
    
    description: "Gatha Lake",
    linkMap:"https://www.google.com/maps?q=",
    description1: "",
    p1: "",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "",
    about1: " ",
    about2: " ",
    about3: " ",
    about4: " ",
    arrivalbycab1: " ",
    arrivalbycab2: " ",
    arrivalbycab3: " ",
    arrivalbycab4: " ",
    cimate0: " ",
    cimate1: "  ",
    cimate2: " ",
    cimate3: " ",
    cimate4: " ",
    headinghotels: "List Of Hotels",
    hotelsName: ["Hotel Lolab Valley ","Hut by JKTDC", ],
    hotelsPhone: ["+91-9697 894 589","+91-7006756428", ],
    hotelsViewLinks: ["https://www.lolabvalley.com/", "https://www.jktdc.co.in/", ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Lolab Valley , Lolab Valley, Jammu and Kashmir"],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['', ''],
    foodsDescription: [ " "," "],
    religiousPlaces: [""],
    religiousPlacesDescription: [""],
    historicalPlaces: [""],
    historicalPlacesDescription: [""],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1], // Add images array for historical
  };
  
  export default lolabValleyData;