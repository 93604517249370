// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/astanmarg-1.jpg";
import baderwah2 from "../../img/astanmarg2.jpg";
import religiousImage1 from "../../img/merak shah.jpg";
import historicalImage1 from "../../img/buddhist-monestry.jpg";
import historicalImage2 from "../../img/harwan-lake.jpg";
import FoodsImage3 from "../../img/kashmiri-pulao.webp";
import FoodsImage4 from "../../img/wazwan.webp";


const astanMarg = {
    name: "Astanmarg",
    
    description: "Astanmarg",
    linkMap:"https://www.google.com/maps?q=Astanmarg, 191202",
    description1: "Astanmarg is basically a viewpoint, a meadow on top of the Zabarwan range surrounded by a couple of small villages. You drive up the mountain on a steep and narrow road and reach a point where the road ends, leaving you with an amazing view of the entire Srinagar city and Dal Lake.",
    p1: "From Astanmarg, you really get a wide view of the entire city, Dal Lake, the Golf Course, and the mountains of the Zabarwan range surrounding it from all sides.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "Astanmarg is located at a distance of 10 kilometers from Shalimar Bagh, 13 kilometers from Nishat Bagh and Dal Lake, and about 25 kilometers from Lal Chowk in Srinagar.",
    about1: "You can cover a couple of other places either while going to Astanmarg or coming back. ",
    about2: "The Tourism department of Jammu and Kashmir introduced Paragliding at Astanmarg in 2013and slowly it is becoming quite popular with the tourists.You will have to book your tour in advance in Srinagar. ",
    about3: " Astanmarg is a meadow on top of the Zabarwan range near Srinagar city at a height of 7300 feet above sea level and overlooks Dal lake, Mahadev peak, Dachigam National Park, Mughal Gardens, Hariparbat fort and other scenic spots in Srinagar. ",
    about4: "Its rather interesting to reach the spot for paragliding though. Its close to a village called Dara about 15 kilometres from Srinagar on the Nishat-Shalimar garden road. The road leads towards Dachigam National Park and thereafter the climb starts, which is actually very very steep and you will need a Safari or a similar vehicle to take you up. The last 2 kilometres of drivable road is basically just rocks on a barely-there road.The last 1.5 kilometres is a lovely hike to the jumping ground. As you walk up, the lovely scenes below open up - on a clear day you can see most of Srinagar, Dal lake, Nishat and Shalimar gardens along with the beautiful villages below. The first part of the hike is a little steep, thereafter its just beautiful. ",
    arrivalbycab1: "You can hire a taxi or cab for the journey from Srinagar to Astanmarg Paragliding Point. Taxis are readily available in Srinagar, and you can either arrange for one through your hotel/accommodation or find one at a taxi stand in the city. ",
    arrivalbycab2: "The journey typically takes around half to 1 hour by road. However, it's essential to factor in additional time for any stops you might make along the way and potential delays due to traffic or road conditions. ",
    arrivalbycab3: " ",
    arrivalbycab4: " ",
    cimate0: "The best time to be at Astanmarg is around sunset. This is when you will get the best view.",
    cimate1: "Spring (March to May): Spring in Srinagar is marked by blossoming flowers, particularly the famous tulips in the Indira Gandhi Memorial Tulip Garden. Temperatures during this season range from mild to moderately warm, with average highs ranging from 15°C to 23°C (59°F to 73°F) and lows ranging from 3°C to 9°C (37°F to 48°F).  ",
    cimate2: "Summer (June to August): Summers in Srinagar are generally pleasant but can sometimes get hot, especially in June and July. The temperature during this season typically ranges from 20°C to 30°C (68°F to 86°F), with occasional peaks reaching higher. However, due to its location in the Kashmir Valley and its elevation, Srinagar generally remains cooler compared to other parts of India during the summer months. ",
    cimate3: "Autumn (September to November): Autumn is a beautiful season in Srinagar when the city is adorned with vibrant hues of red, orange, and yellow as the leaves change color. The weather starts to cool down gradually, with temperatures ranging from 8°C to 23°C (46°F to 73°F). This season also marks the harvesting of apples and other fruits in the region. ",
    cimate4: "Winter (December to February): Winter in Srinagar is cold and often snowy, especially in the higher elevations surrounding the city. Temperatures during this season can drop significantly, with average highs ranging from 2°C to 8°C (36°F to 46°F) and lows plunging below freezing, often reaching as low as -2°C to -7°C (28°F to 19°F). Srinagar experiences frequent snowfall during the winter months, making it a popular destination for winter sports enthusiasts. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["The Ivory ","Northland Residency Hotel","Hotel Paisley Palace" ,"Kashmir Holiday Inn"],
    hotelsPhone: ["088999 93148","095966 60038","060068 01984","8899900665" ],
    hotelsViewLinks: ["https://www.theivory.in/", "https://northlandresidency.com/","https://www.paisleypalace.in/","https://kashmirholidayinn.com/","" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=The Ivory, Harwan, Srinagar, Jammu and Kashmir 191121","https://www.google.com/maps?q=Northland Residency Hotel, Northland Residency, near Four Seasons Cafe Grill, Harwan, Srinagar, Jammu and Kashmir 190006","https://www.google.com/maps?q=Hotel Paisley Palace, SKAUST ROAD, Shalimar, Srinagar, Jammu and Kashmir 190025","https://www.google.com/maps?q=Kashmir Holiday Inn, Harwan Rd, Srinagar, Jammu and Kashmir 191121"],
    headingfood: "Must try in Astanmarg",
    foodsName: ['Kashmiri pulav with spices, saffron, and dry fruits', 'Wazwan'],
    foodsDescription: [ " Kashmiri Pulao is packed with toasted nuts and warming spices for an aromatic rice dish that is mild and faintly sweet in taste. Whether you serve this gluten-free and vegetarian dish as a side or light lunch, you’re sure to love it."," Wazwan is a multi-course meal in Kashmiri cuisine, originating from Kashmir. Almost all the dishes are meat-based using lamb or chicken with few vegetarian dishes. It is popular throughout the larger Kashmir region. Moreover, Wazwan is also served internationally at Kashmiri food festivals and reunions."],
    religiousPlaces: ["Sayyid Meerakh Shah Kashani"],
    religiousPlacesDescription: ["Sayyid Mirak Shah Kashani was a poet of substance who wrote devotional poems in Kashmiri language. The theme of his verses was Toheed (monotheism) and Ishq-e-Rasool (love of the Messenger of Allah)"],
    historicalPlaces: ["Harwan Lake","Harwan Buddhist Monastery"],
    historicalPlacesDescription: ["A clean and pure water lake, Harwan Lake is set among the mountains. It is 21 km from Srinagar. With its dark green waters reflecting the high peaks of Mahadeo moutain, Harwan Lake offers some of the best surrounding scenery in the Kashmir valley.","The Harwan monastery is a reminder of the pivotal role played by Kashmir in the spread of Buddhism. On the outskirts of Srinagar in Harwan, lie the ruins of an old Buddhist Monastery. Harwan as the seat of the fourth Buddhist Council held during the reign of Kanishka, is a relic of the Buddhist past of Kashmir & a testament to the cultural history of the region, through which Buddhism spread beyond the Land of the Buddha further to the rest of Asia."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default astanMarg;