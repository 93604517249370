import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import shankp1 from "../../img/shanklpal.jpg";
import shankp2 from "../../img/shankpal2.webp";
import Religious1 from "../../img/shiv_temple.jpg";
import Religious2 from "../../img/gauri_shaknar.jpg";
import historical1 from "../../img/patnitop.webp";
import historical2 from "../../img/sanasar.jpg";
import historical3 from "../../img/natha_top.jpg";
import FoodsImage1 from "../../img/kangra-dum.jpg";
import FoodsImage2 from "../../img/khatta-meat.jpg";


const akhnoorData = {
          name: "Sankpal",
        //   area: "Area 150 km²",
        //   temp: "20",
        //   population: "25,000",
        //   language: "Dogri, Hindi, Urdu, English",
          description: "Sankpal",
          linkMap:"https://www.google.com/maps?q=339P+3VR Sankpal devta Mandir, Upper Chunal, Panjar, Chulna, 182311",
          description1: "It is about 26 km from Patnitop by road and 7 km trek  from Sanasar. The Sankhpal temple, believed to have been build nearly 400 years ago, is located at an altitude of 2897m which is the highest point on Sankhpal ridge. Sankhpal- Sanasar Trek is renowned trek , which provides spectacular views of himalyan mountains.",
          p1: "This place offers complete pleasure, recreation, relaxation, Paragliding,  Adventure camping site, Trekking to Shankpal, horse Riding and religious tourism. ",
          p2: " Reaching the temple itself is a journey in itself. The 7 km trek from Sanasar winds through picturesque meadows, dense forests, and cascading streams, offering stunning views of the Pir Panjal range along the way. The fresh mountain air, vibrant flora, and the occasional sighting of wildlife create an unforgettable experience for trekkers.",
          p3: "While religious significance draws many to Sankhpal Temple, its beauty and serene atmosphere cater to diverse interests. The surrounding landscape beckons nature enthusiasts with opportunities for camping, hiking, and photography. The panoramic vistas from the temple's vantage point are a treasure for photographers and anyone seeking a moment of tranquility amidst the mountains.",    
          backgroundImage: `url(${gurazBandiporaBackground})`, 
          images: [shankp1, shankp2,], // Add images array
          distanceFromSrinagar: " The distance from Srinagar to Sankhpal Temple is 186 km along NH 44, and it should take about 5 hours 1 minute to drive. ",
          about1: " Nestled amidst the breathtaking panorama of the Himalayas, Sankhpal Temple stands as a beacon of spirituality and cultural significance. Located at an altitude of 2897 meters on the Sankhpal ridge, approximately 26 km from Patnitop and 7 km by trek from Sanasar, this 400-year-old temple attracts pilgrims and adventure seekers alike.",
          about2: " Believed to have been built around 400 years ago, Sankhpal Temple holds a special place in the hearts of devotees. It is dedicated to Nag Shankhpal, one of the nine prominent Nagas (serpents) revered in Hindu mythology. The temple's unique construction without mortar further adds to its mystique, attracting visitors curious about its architectural prowess",
          about3: " For many pilgrims, the trek to Sankhpal Temple is a spiritual pilgrimage. Every year, thousands participate in the annual yatra, which commences on the 14th day after Shravan Purnima. The rituals and prayers performed at the temple are believed to bring blessings and spiritual fulfillment.",
          // about4: "Spiritual Havens: Seek spiritual solace in Akhnoor's revered shrines. Visit temples like the Akhnoor Kali Mata Mandir and experience moments of peace and devotion. The spiritual ambiance adds a sacred dimension to Akhnoor's landscape.",
          arrivalbycab1: "The distance from Jammu to Sankhpal Temple is 129 km along NH 44, and it should take about 3 hours 8 minutes to drive. ",
          arrivalbycab2: " Use taxi aggregators: Apps like Ola or Uber operate in major cities like Jammu.",
          arrivalbycab3: " Hire a local taxi: Look for authorized taxi stands or reputable agencies at Jammu bus stand or railway station. Negotiate the fare beforehand for transparency.",
          arrivalbycab4: " Ensure the cab driver is authorized and has a valid permit for the route.",
          // cimate0: "Summer (April-June)",
          cimate1: " Summer (April-June): Temperatures range from 15°C to 25°C, making it a pleasant time for visiting. The weather is mostly sunny with occasional rainfall. This is a good time for trekking and other outdoor activities.",
          cimate2: "Monsoon (July-September): Moderate rainfall occurs, averaging 75 cm. The lush greenery of the region is at its best during this time. ",
          cimate3: "Autumn (October-November): Temperatures gradually decline, creating a vibrant display of fall foliage. The weather is mostly dry and sunny.This is a good time for photography and enjoying the scenic beauty of the region.",
          cimate4: "Winter (December to March): The region experiences snowfall, transforming it into a winter wonderland. Temperatures can dip below freezing. his is the time to visit if you want to experience a snowy landscape and winter activities.",
          headinghotels: "List Of Hotels",
          hotelsName:["Kohinoor Restaurant Akhnoor","Rama Palace","Krishna Palace","Afsara Hotel & Restaurant",""],
          hotelsPhone:["+91-7889955907","","+91-7051178767","","NA"],
          hotelsViewLinks:["https://www.instagram.com/kohinoorjammu/","https://m.facebook.com/profile.php?id=194221217759432","https://weddingz.in/jammu/krishna-palace-resort-bohri/","",""],
          hotelsMapsLinks:["https://www.google.com/maps/dir//KOHINOOR RESTAURANT -AKHNOOR (DINING | PARTY HALL | ROOMS)","https://www.google.com/maps/dir//Rama Palace, VPXJ+W4G, Sungal Morh, Akhnoor, Ambaran, 181201","https://www.google.com//maps/Krishna Palace, RR4V+4R5, Jourian-Akhnoor Road, Jammu, 181123","https://www.google.com/mapsM.Uttam work experience, Akhnoor city, Akhnoor, Gandharwan, Jammu and Kashmir 181201","https://www.google.com/maps/dir//Alpine+Resort+gurez/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x38e15db75243e557:0x4b043f2e827ef9e9?sa=X&ved=2ahUKEwiK3PTGgduDAxVcUGwGHemsAN8Q9Rd6BAhfEAA"],
          headingfood: "Must try in Gurez",
          foodsName:['Kangra Dham','Khatta Meat'],
          foodsDescription:["A vegetarian thali feast. featuring various local dishes like dal, vegetables, rice, papad, chutney, and pickles.          ","Mutton or chicken cooked in a yogurt-based gravy with a tangy twist"],
          religiousPlaces: ["Shiv Mandir.", "Gauri Shankar Mandir."],
          religiousPlacesDescription: ["An ancient temple dedicated to Lord Shiva, offering a peaceful atmosphere and stunning views of the surrounding mountains. ","Another ancient temple dedicated to Lord Shiva and Parvati, located in a scenic setting amidst forests.           "],
          historicalPlaces: ["Patnitop", "Sanasar","Nathatop"],
          historicalPlacesDescription: ["A popular hill station known for its meadows, forests, and stunning views of the Himalayas. You can enjoy activities like paragliding, skiing, and mountain biking here. ","A beautiful hill station known for its meadows, forests, and stunning views of the Himalayas. You can enjoy activities like paragliding, skiing, and mountain biking here.","A hill station known for its natural beauty, offering scenic views of the Pir Panjal range. You can enjoy activities like horseback riding, trekking, and camping here. "],
          foodsImages:[FoodsImage1,FoodsImage2],
          imagesforreligious: [Religious1, Religious2 ], // Add images array for religious
          imagesforhistorical: [historical1, historical2, historical3], // Add images array for historical
  };
  
  export default akhnoorData;
