import React from "react";
import './dashboard.css';
import MapComponent from '../Map/Map';
import DestinationSection from './destination-section';
import Heder from './header';
import Navbar from './navbar';
import Carousel from './carousel';
import Footer from "./footer";
import Slider from "../Slider/slider";


const Dashboard = () => {
    
    return (
        <div>
            <Heder />
            <Navbar />
            <Carousel />
            <DestinationSection />
           
            <MapComponent />
            <Slider />
            <Footer />
        </div>
    )
};
export default Dashboard;
