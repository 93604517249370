import React from "react";
import Sclipture from '../img/shahdara sharif.jpg'
import EcoTourism from '../img/noori chamb.jpeg'
import Mice from '../img/ragunathaji.jpg'
import Health from '../img/tattapani.jpg'
import Adventure from '../img/adventure.jpg'
// import Ganderbal from '../img/bahufort.jpg'
import Heritage from '../img/chingus fort.jpg'
import Rural from '../img/ruraltourism.webp'
import Bangus from '../img/Guraz 1.jpg'
import { Link, } from 'react-router-dom';
function App() {
  return (
    <div>
    {/* <!-- Destination Start --> */}
    <div class="container-fluid py-5 backgroundImgForDestination">
    
        <div class="container pt-5 pb-3">
            <div class="text-center mb-3 pb-3">
                {/* <h6 class="text-primary text-uppercase letter-spacing-5px" >Destination</h6> */}
                <h1>EXPLORE THE UNEXPLORED</h1>
            </div>
            <div class="row">
            <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Sclipture} alt="Bandipora"/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=guraz'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">Pilgrimage & Sculpture </h5>
                            <h5 class="text-white">Tourism</h5>
                        {/* </a> */}
                        </Link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={EcoTourism} alt="Baderwah"/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=srinagar'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">ECO-Tourism</h5>
                            {/* <span>Tourism</span> */}
                        </Link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }}  class="img-fluid border" src={Mice} alt=""/>
                    </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=bangusvalley'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">MICE Tourism</h5>
                            {/* <span>Tourism</span> */}
                        </Link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Bangus} alt=""/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=daksumvalley'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">Border Tourism </h5>
                            {/* <span>Tourism</span> */}
                        </Link>
                    </div>
                </div>
                
                </div>
                <div class="row">
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Health} alt=""/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=lolabvalley'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">Health & Wellness</h5>
                            <h5 class="text-white">Tourism</h5>
                            {/* <span>Tourism</span> */}
                    
                        </Link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4  ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Adventure} alt=""/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=akhnoor'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">Adventure Tourism</h5>
                            {/* <span>Tourism</span> */}
                        </Link>
                    </div>
                </div>
                
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Heritage} alt=""/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=amarpalace'  target="_blank" rel="noopener noreferrer">
                        <h5 class="text-white">Heritage Tourism</h5>
                            {/* <span> Tourism</span> */}
                        </Link>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 ">
                    <div class="destination-item position-relative overflow-hidden mb-2">
                    <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
                        <img  style={{ width: '100%', height: '100%', objectFit: 'cover' }} class="img-fluid border" src={Rural} alt=""/>
                        </div>
                        <Link className="destination-overlay text-white text-decoration-none "  to='/explored?location=ganderbal'  target="_blank" rel="noopener noreferrer">
                            <h5 class="text-white">Rural Tourism </h5>
                            {/* <span>Tourism</span> */}
                        </Link>
                    </div>
                </div>
               
                
            </div>
           
        </div>
    </div>
     {/* Destination Start */}
     </div>
     );
    }
    
    export default App;