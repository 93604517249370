// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import baderwah1 from "../../img/warwan1.avif";
import baderwah2 from "../../img/warwan.avif";
import religiousImage1 from "../../img/ziyarat shareef.jpg";
import religiousImage2 from "../../img/machail-cahdi-mata.jpg";
import historicalImage1 from "../../img/kishtwar town.jpg";
import historicalImage2 from "../../img/margantop1.jpg";
import FoodsImage3 from "../../img/nadru-yakhni.jpg";
import FoodsImage4 from "../../img/phirni-1.jpg";


const warWan = {
    name: "Warwan Valley",
    
    description: "Warwan Valley",
    linkMap:"https://www.google.com/maps?q=Warwan Valley, 192125",
    description1: "Nestled in the heart of the Kishtwar district in Jammu and Kashmir, Warwan Valley stands as a testament to the unspoiled beauty and tranquility that the region is renowned for. This remote valley, cradled by snow-capped peaks and adorned with lush meadows, offers a respite from the bustling pace of urban life. As a relatively lesser-explored gem in the Kashmir Valley, Warwan beckons adventure enthusiasts, nature lovers, and those seeking a unique cultural experience.",
    p1: "The Warwan Valley trek is the most exquisite in our country. There is no trek you would have done before this one that can match the experience of this trek. And there will be no trek you do after this that comes close. This might sound like a strong statement to make. However, our founders, who explored this trek in 2015, still stand by it.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [baderwah1, baderwah2], // Add images array
    distanceFromSrinagar: "The journey to Warwan Valley from Srinagar involves traversing approximately 200 kilometers, with the road leading through the picturesque town of Kishtwar. The route offers breathtaking views of the Himalayan landscapes, making the travel itself an integral part of the adventure.",
    about1: "Warwan Valley is exceptionally stunning – even for Kashmir. Even if you  have travelled to Kashmir before and seen Sonamarg, Gulmarg, Pahalgam, and  other places the valley offers – you are still going to be bowled over by the settings of Warwan Valley. It is unique to the region.",
    about2: "The valley’s remoteness and isolation compound this beauty. This is a side of Kashmir that even most Kashmiris haven’t seen. ",
    about3: "What makes getting into Warwan Valley even more remarkable is the crossover trail. It begins in Trans-Himalayan settings, among barren, purplish mountains around Panikhar. The trail goes through an oasis, river deltas, wildflower meadows, and glaciers – all among these stark mountains. It makes for hauntingly beautiful settings. As the trail slowly moves into the luxuriant greenery of Kashmir, after days  of being surrounded by desert mountains, the effect of it is striking. ",
    about4: "During the crossover, you experience what we believe is Kashmir’s greatest adventure. It is a great adventure because — outside the astounding beauty, there’s a lot of thrill on the trek. The pass day almost feels endless — it has a tricky climb up to the pass. And then it is followed by a 2 km long walk on the magnificent Bracken glacier, navigating its crevasses, right up to its snout when it pours over in moraines.The high adventure on this trail makes getting into the glorious greenery of Warwan Valley a gift, after long, hard days of trekking. ",
    arrivalbycab1: "The journey to Warwan Valley is best undertaken by car, providing the flexibility to navigate the challenging mountainous terrain. The road from Srinagar to Kishtwar offers a scenic drive, allowing travelers to soak in the mesmerizing beauty of the landscape. ",
    arrivalbycab2: " ",
    arrivalbycab3: " ",
    arrivalbycab4: " ",
    cimate0: " Warwan Valley experiences a typical Himalayan climate, with cold winters and relatively milder summers. During winter, heavy snowfall blankets the valley, rendering it inaccessible. The ideal time to explore Warwan is from late spring to early autumn when the weather is more favorable for outdoor activities.",
    cimate1: "  ",
    cimate2: " ",
    cimate3: " ",
    cimate4: " ",
    headinghotels: "List Of Hotels",
    hotelsName: ["WARWAN RESORT ","The Hermitage By Grand Resorts","Brown Palace","NA" ],
    hotelsPhone: ["08803126264","060067 50238","094692 84249","NA" ],
    hotelsViewLinks: ["https://www.facebook.com/p/Warwan-Resort-Paying-guest-house-100083284805380/", "https://hermitagehotels.in/","https://brownpalace.in/" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Inshan, https://www.google.com/maps?q=Forest Block, 192125","The Hermitage By Grand Resorts, Laripora Pahalgam, Pahalgam, Jammu and Kashmir 192126","https://www.google.com/maps?q=Brown Palace, Laripora, Pahalgam, Jammu and Kashmir 192126","https://www.google.com/maps?q="],
    headingfood: "Must try in Warwan Valley",
    foodsName: ['Nadru Yakhni', 'Phirni'],
    foodsDescription: [ " Lotus root cooked in a yogurt-based gravy with spices.","A creamy rice pudding flavored with cardamom and topped with chopped nuts. "],
    religiousPlaces: ["Ziarat of Shah Asrar-ud-Din Bagdad","Machail Mata Temple"],
    religiousPlacesDescription: ["The Ziarat of Shah Asrar-ud-Din Baghdadi, also known as Shah Asrar Mosque, is a revered religious site located in Kishtwar, Jammu and Kashmir, near the Warwan Valley. This shrine holds significant religious and cultural importance, particularly for the Muslim community in the region.Shah Asrar-ud-Din Baghdadi was a prominent Sufi saint believed to have traveled from Baghdad to the Indian subcontinent several centuries ago to spread the teachings of Islam and Sufism. The shrine is dedicated to him and serves as a place of reverence and pilgrimage for devotees.","The temple of Machail Mata is nestled in the lap of the Himalayas, surrounded by breathtaking natural beauty. It is situated at an altitude of around 9,500 feet above sea level. Devotees undertake a challenging pilgrimage to reach the temple during the annual Machail Yatra, which usually takes place during the Hindu month of Shravan (July-August). During this time, thousands of devotees from different parts of the country visit the temple to seek the blessings of Goddess Durga."],
    historicalPlaces: ["Kishtwar Town","Margan Top"],
    historicalPlacesDescription: ["Serving as a gateway to Warwan Valley, Kishtwar Town is known for its vibrant markets, historical temples, and the confluence of the Chenab and Athwajan rivers.","A popular trekking destination in the vicinity, Margan Top offers panoramic views of the surrounding mountains and valleys. The trek to Margan Top is an adventurous exploration of the region."],
    foodsImages:[FoodsImage3,FoodsImage4],
    imagesforreligious: [religiousImage1,religiousImage2 ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default warWan;