// import gurazBandiporaBackground from "../../img/srinagar-background.jpg";
import jantrondhar from "../../img/jantrondhar1.jpg";
import jantrondhar2 from "../../img/jantrondhar2.jpg";
import religiousImage1 from "../../img/chandi-mata.jpg";
import historicalImage1 from "../../img/sinu-waterfall.jpg";
import historicalImage2 from "../../img/balisa.jpg";
import FoodsImage1 from "../../img/dam-aloo.png";
import FoodsImage2 from "../../img/jungifood.jpg";


const jantronDharData = {
    name: "Jantron Dhar",
    
    description: "Jantron Dhar",
    linkMap:"https://www.google.com/maps?q=Jantroon Dhar Doda, 3QVF+H62, Bathri, Jammu and Kashmir 182203",
    description1: "It is in tehsil Chiralla about 55 km from District headquarter Doda. The spot is about 2 km away from the road. It is a hill Resort covered with rich conifer forest and high-altitude mountains.",
    p1: "Jantron Dhar, nestled in the Dodadistrict of Jammu and Kashmir, stands as a local hill station and meadow within the Forest Block area, approximately 15 kilometers away from Thathri. This scenic destination is enveloped by lush green meadows that transform into a winter wonderland with a blanket of snow during the colder months. Surrounded by dense vegetation, Jantron Dhar offers a serene escape for local tourists who visit annually, especially during the summer, to explore its picturesque landscapes. The region is notinhabited, providing a tranquil environment for those seeking a break from the hustle and bustle ofurban life.",
    // backgroundImage: `url(${gurazBandiporaBackground})`,
    images: [jantrondhar, jantrondhar2], // Add images array
    distanceFromSrinagar: "The distance from Jammu to Jantron Dhar in the Baderwah region is approximately 170 kilometers (about 105 miles) by road. The journey typically takes around 5 to 6 hours by car, depending on the road conditions and traffic. As for Srinagar to Jantron Dhar, the distance is approximately 240 kilometers (about 150 miles) by road. The journey from Srinagar to Jantron Dhar usually takes around 7 to 8 hours by car, again depending on factors such as road conditions and traffic.",
    about1: "Jantron Dhar is a beautiful meadow located in the Baderwah region of Jammu and Kashmir, India. It is renowned for its lush greenery, vibrant wildflowers, and stunning views of the surrounding Himalayan peaks. Jantron Dhar is situated at an altitude of approximately 3,000 meters (around 9,800 feet) above sea level, making it an ideal destination for nature lovers, trekkers, and adventure enthusiasts. ",
    about2: "The meadow is accessible via a trekking route, offering visitors an opportunity to immerse themselves in the pristine beauty of the Baderwah Valley. The journey to Jantron Dhar takes you through dense forests, rolling hills, and picturesque landscapes, providing breathtaking vistas at every turn. ", 
    about3: " Jantron Dhar is a popular camping spot, allowing visitors to spend a night under the starry sky amidst the tranquil surroundings. The meadow is also a haven for birdwatchers, with a variety of avian species found in the area.",
    about4: "Visitors to Jantron Dhar can indulge in activities like trekking, photography, birdwatching, and simply soaking in the natural beauty of the region. Overall, Jantron Dhar is a hidden gem in the Baderwah region, offering an unforgettable experience for those who venture into its pristine landscapes.    ",
    arrivalbycab1: "If you're planning to arrive at Jantron Dhar by cab, you can hire a taxi from either Jammu or Srinagar, depending on your starting point. Here's how you can arrange your journey. ",
    arrivalbycab2: " From Jammu: Hire a cab or taxi from Jammu to Jantron Dhar. You can find taxi services available in Jammu city or at the Jammu Tawi Railway Station. ",
    arrivalbycab3: "Discuss the route with the taxi driver. The most common route would likely be via Batote-Doda Road, which passes through the Baderwah region. Ensure that the cab is well-maintained and equipped for a long journey, especially if you're traveling during adverse weather conditions. ",
    arrivalbycab4: "From Srinagar: Hire a cab or taxi from Srinagar to Jantron Dhar. You can find taxi services available in Srinagar city or at the Srinagar International Airport.Discuss the route with the taxi driver. The journey from Srinagar would likely take you through Ramban, Banihal, and then towards the Baderwah region. Confirm the availability of the taxi for the entire duration of your trip, including return if needed.",
    cimate0: "The climate in the Baderwah region, where Jantron Dhar is located, can vary depending on the season. Here's a general overview of the climate throughout the year: ",
    cimate1: "Spring (March to May): Spring in Baderwah is mild and pleasant, with temperatures gradually rising. Days are generally warm, while nights can still be cool. This season brings blooming flowers and lush greenery to the region, making it a beautiful time to visit.  ",
    cimate2: " Summer (June to August): Summer months in Baderwah are warm during the day, with temperatures ranging from mild to moderately hot. However, due to its higher altitude, Baderwah generally remains cooler compared to the plains. Summer is an excellent time for trekking and outdoor activities, although occasional rainfall can be expected.",
    cimate3: "Autumn (September to November): Autumn is characterized by cooler temperatures and a gradual transition from summer to winter. The weather remains pleasant during the daytime, with cooler evenings. This season offers clear skies and stunning views of the surrounding landscapes, making it ideal for trekking and sightseeing. ",
    cimate4: "Winter (December to February): Winter in Baderwah is cold and chilly, with temperatures often dropping below freezing point, especially at higher elevations. The region receives snowfall during these months, transforming it into a winter wonderland. Heavy snowfall can sometimes lead to road closures, so it's essential to check weather conditions before traveling. Winter sports enthusiasts can enjoy activities like skiing and snowboarding in the nearby areas. ",
    headinghotels: "List Of Hotels",
    hotelsName: ["OYO Nag Hill View ","JMC HOTEL S INN PITHAMPUR","NA","NA" ],
    hotelsPhone: ["+91-0124 620 1520","+91-99267 85999","NA","NA" ],
    hotelsViewLinks: ["https://www.oyorooms.com/190936/", "reservations@jmchotels.com","NA","NA" ],
    hotelsMapsLinks: ["https://www.google.com/maps?q=Hotel Lolab Valley , Lolab Valley, Jammu and Kashmir"],
    headingfood: "Must try in Lolab Valley",
    foodsName: ['Dum Aloo', 'Junglikhand food'],
    foodsDescription: [ " Dum Aloo is a delicious and comforting dish that is enjoyed across India and beyond, known for its rich flavors, creamy texture, and aromatic spices. It's often served with rice or Indian breads like naan or roti, making it a satisfying meal for vegetarians and non-vegetarians alike. ","Junglikhand cuisine is often simple and rustic, reflecting the lifestyle of the people living in the region. The food is hearty and filling, using locally available ingredients. Simple and Rustic: Junglikhand cuisine is often simple and rustic, reflecting the lifestyle of the people living in the region. The food is hearty and filling, using locally available ingredients. Staple Ingredients: Staple ingredients in Junglikhand cuisine include grains like rice and wheat, lentils, vegetables, and dairy products such as yogurt, ghee (clarified butter), and paneer (Indian cottage cheese)."],
    religiousPlaces: ["Chandi Mata Temple"],
    religiousPlacesDescription: ["The temple holds immense religious significance for the local community and attracts devotees from nearby areas who come to seek blessings and offer prayers. It is situated amidst serene surroundings, amidst lush greenery and rolling hills, adding to its spiritual ambiance. Visitors to Jantron Dhar can explore the Chandi Mata Temple as part of their religious or cultural journey in the region. It's a place where one can experience both the natural beauty of the surroundings and the spiritual atmosphere of the temple. Additionally, the temple offers panoramic views of the surrounding landscape, making it a peaceful and serene spot for meditation and introspection."],
    historicalPlaces: ["Sinu waterfall","Bhalessa"],
    historicalPlacesDescription: ["Sinu Waterfall, nestled in the captivating Jantroon Valley in the Doda district of Jammu and Kashmir, is a breathtaking natural spectacle. Cascading down from the heights of Jantroon Valley, this pristine waterfall adds to the picturesque charm of the region. Surrounded by lush greenery andrugged landscapes, Sinu Waterfall offers a tranquil escape for nature enthusiasts and adventure seekers. The sight and sound of the cascading watercreate a serene ambiance, making it a must-visit destination for those exploring the scenic wonders of Jammu and Kashmir. Accessible through theenchanting trails of Jantroon Valley, Sinu Waterfall is a hidden gem that adds to the allure of this Himalayan paradise.","Bhalessa, situated in the Doda district of Jammu and Kashmir, is a region of captivating natural beauty and cultural significance. Nestled amidst the Himalayas, Bhalessa is characterized by its picturesque landscapes, including lush meadows, dense forests, and snow-capped peaks. The area is known for its rich cultural heritage, with a diverse community contributing to the vibrant traditions and festivals. Bhalessa offers a serene retreat for those seeking tranquility amid the Himalayan charm, attracting visitors with itsscenic vistas and the warm hospitality of its residents."],
    foodsImages:[FoodsImage1,FoodsImage2],
    imagesforreligious: [religiousImage1, ], // Add images array for religious
     imagesforhistorical: [historicalImage1,historicalImage2], // Add images array for historical
  };
  
  export default jantronDharData;